import { useMemo, useRef, useState } from 'react';
import { useEffect } from 'react';
import { BsTelephoneMinus } from 'react-icons/bs';
import { MdVerified } from 'react-icons/md';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { PostRequest, PatchRequest } from '../../../../components/Request';
import { MdOutlineKeyboardBackspace } from 'react-icons/md';
// this is important for email
// import ConfirmationBox from '../../buyers/ConfirmationBox';

import { BsFillCameraFill } from 'react-icons/bs';

const CssTextField = styled(TextField)({
  '& .MuiInputBase-input': {
    padding: '0.75rem 1.25rem',
  },
  '& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root': {
    lineHeight: '1rem',
    fontSize: '0.90rem',
    color: '#9FA6B3',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: '0.35rem',
      borderColor: '#E5E7EB',
    },
  },
});

const CssTextField1 = styled(TextField)({
  '& .MuiInputBase-input': {
    padding: '0.75rem 0.7rem',
  },
  '& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root': {
    lineHeight: '1rem',
    fontSize: '0.90rem',
    color: '#9FA6B3',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: '0.35rem',
      borderColor: '#E5E7EB',
    },
  },
});

export default function StoreContact(props: any) {
  let {
    setShowIndicator,
    setMessage,
    setType,
    shopInfo,
    setShopInfo,
    setSelectedNavigation,
    handleChange,
    validateEmail,
  } = props;
  let {
    shopName,
    shopType,
    shopCategory,
    phoneNumbers,
    panCard,
    tradeLicense,
    GST,
    email,
    shopImages,
    profileImage,
  } = shopInfo.errors;
  const [startEmailTimer, setStartEmailTimer] = useState(false);
  const [startPhoneOTPTimer, setStartPhoneOTPTimer] = useState(false);
  const [resendEmailTimer, setResendEmailTimer] = useState(30);
  const [resendPhoneOTPTimer, setResendPhoneOTPTimer] = useState(30);
  const [otp, setOtp] = useState<string[]>(new Array(6).fill(''));
  const [emailOTP, setEmailOTP] = useState<string[]>(new Array(6).fill(''));
  const [activeOtpIndex, setActiveOtpIndex] = useState<number>(0);
  const [activeEmailOTPIndex, setActiveEmailOTPIndex] = useState<number>(0);
  const [showConfirmationBox, setShowConfirmationBox] = useState(false);
  let currentOtpIndex: number = 0;
  const inputRef = useRef<HTMLInputElement>(null);
  const emailInputRef = useRef<HTMLInputElement>(null);
  const [phoneNumberObj, setPhoneNumberObj] = useState({
    phoneNumber: '',
    label: '',
    isVerified: false,
    countryCode: '+91',
    error: {
      phoneNumber: '',
      label: '',
      isVerified: '',
    },
  });

  const handleUpdateStoreContact = async () => {
    await PatchRequest('store', {
      phoneNumbers: shopInfo.phoneNumbers.map((numberObj: any) => {
        return {
          countryCode: numberObj.countryCode,
          label: numberObj.label,
          nationalNumber: numberObj.phoneNumber,
        };
      }),
      email: email.id,
    })
      .then((res: any) => {
        if (res.status === 200) {
          setShowIndicator(true);
          setMessage('Updated');
          setType('success');
          setTimeout(() => {
            setShowIndicator(false);
          }, 3000);
        }
      })
      .catch((err) => {
        setShowIndicator(true);
        setMessage(err.response.data.message);
        setType('error');
        setTimeout(() => {
          setShowIndicator(false);
        }, 3000);
      });
  };

  const phoneOtpHandleOnKeyDown = (
    { key }: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    const newOtp: string[] = [...otp];

    if (key !== 'Backspace' && key.match('[0-9]')) {
      newOtp[index] = key;
      setActiveOtpIndex(index + 1);
      setOtp(newOtp);
    } else if (key == 'Backspace') {
      newOtp[index] = '';
      setActiveOtpIndex(index - 1);
      setOtp(newOtp);
    }
  };

  const emailOTPHandleOnKeyDown = (
    { key }: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    const newEmailOTP: string[] = [...emailOTP];

    if (key !== 'Backspace' && key.match('[0-9]')) {
      newEmailOTP[index] = key;
      setActiveEmailOTPIndex(index + 1);
      setEmailOTP(newEmailOTP);
    } else if (key == 'Backspace') {
      newEmailOTP[index] = '';
      setActiveEmailOTPIndex(index - 1);
      setEmailOTP(newEmailOTP);
    }
  };

  useEffect(() => {
    inputRef.current?.focus();
    return;
  }, [activeOtpIndex]);

  useEffect(() => {
    emailInputRef.current?.focus();
    return;
  }, [activeEmailOTPIndex]);

  const calculateResendEmailTimer = useMemo(() => {
    if (resendEmailTimer <= 0) {
      setStartEmailTimer(false);
      return 0;
    }

    return resendEmailTimer;
  }, [resendEmailTimer]);

  useEffect(() => {
    let intervalId: any;
    if (startEmailTimer) {
      intervalId = setInterval(() => {
        setResendEmailTimer((resendEmailTimer) => resendEmailTimer - 1);
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [startEmailTimer]);

  const handleStart = () => {
    setStartEmailTimer(true);
  };

  const calculateResendPhoneOTPTimer = useMemo(() => {
    if (resendPhoneOTPTimer <= 0) {
      setStartPhoneOTPTimer(false);
      return 0;
    }

    return resendPhoneOTPTimer;
  }, [resendPhoneOTPTimer]);

  useEffect(() => {
    let intervalId: any;
    if (startPhoneOTPTimer) {
      intervalId = setInterval(() => {
        setResendPhoneOTPTimer(
          (resendPhoneOTPTimer) => resendPhoneOTPTimer - 1
        );
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [startPhoneOTPTimer]);

  const handleStartPhoneOTPTimer = () => {
    setStartPhoneOTPTimer(true);
  };

  return (
    <div className="cnt_of_stepone store-profile-form ">
      {/* {showConfirmationBox ? (
        <ConfirmationBox
          showConfirmationBox={showConfirmationBox}
          setShowConfirmationBox={setShowConfirmationBox}
          shopInfo={shopInfo}
          setShopInfo={setShopInfo}
          setEmailOTP={setEmailOTP}
          setResendEmailTimer={setResendEmailTimer}
        />
      ) : (
        ''
      )} */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
        }}
      >
        <button
          className="go-back-button"
          onClick={() => setSelectedNavigation('')}
        >
          <MdOutlineKeyboardBackspace style={{ display: 'inline' }} />
        </button>
      </div>
      <form action="#">
        <div className="flex my-10 email_section justify-between item-center sm:flex-col  sm:my-0">
          <div className="basis-[47.5%] flex justify-between items-center relative">
            <CssTextField
              sx={{
                width: '100%',
              }}
              onChange={handleChange}
              id="outlined-basic"
              name="email"
              value={shopInfo.email.id}
              label="Email"
              placeholder="eg: 12234ms@gmail.com"
              error={shopInfo.errors.email ? true : false}
              helperText={shopInfo.errors.email ? shopInfo.errors.email : ''}
              InputProps={{
                readOnly: shopInfo.email.isVerified,
              }}
              required
            />
            {shopInfo.email.isVerified && (
              <span
                style={{
                  marginLeft: '0.5rem',
                  display: 'block',
                  flexBasis: '5%',
                  position: 'absolute',
                  right: '10px',
                }}
              >
                <MdVerified style={{ color: 'green' }} />
              </span>
            )}
          </div>

          <div
            className={
              shopInfo.email.isVerified
                ? 'basis-[47.5%] flex-wrap sm:self-center otp_section self-start relative sm:justify-center  justify-between flex sm:mt-3 hidden'
                : 'basis-[47.5%] flex-wrap sm:self-center otp_section self-start relative sm:justify-center justify-between flex sm:mt-3'
            }
          >
            {/* email otp */}
            <div
              className={
                shopInfo.errors.email
                  ? 'hidden otpField sm:basis-[100%] flex flex-col justify-center'
                  : 'otpField hidden sm:basis-[100%] flex flex-col justify-center'
              }
            >
              <div className="flex  justify-center sm:my-[1rem]">
                <span className="text-[#14539a] otp_info sm:top-[0px] top-[-48%] absolute text-xs">
                  OTP has been sent to your email
                </span>
              </div>

              <div className="sm:self-center space-x-[0.67rem]">
                {emailOTP.map((_, index) => {
                  return (
                    <input
                      key={index}
                      value={emailOTP[index]}
                      onChange={(event) => {
                        return;
                      }}
                      type="text"
                      maxLength={1}
                      onFocus={(event) => {
                        event.target.classList.add('border-solid');
                        event.target.classList.add('border-[1.5px]');
                        event.target.classList.add('border-black');
                      }}
                      onBlur={(event) => {
                        event.target.classList.remove('border-solid');
                        event.target.classList.remove('border-[1.5px]');
                        event.target.classList.remove('border-black');
                      }}
                      ref={index === activeEmailOTPIndex ? emailInputRef : null}
                      onKeyDown={(event) =>
                        emailOTPHandleOnKeyDown(event, index)
                      }
                      className="shadow-md h-[27px] sm:h-[39px] sm:w-[39px] xs:w-[30px] xs:h-[30px]  bg-[#F6F6FB] inline-block   w-[27px] rounded-md text-center"
                    />
                  );
                })}
              </div>
              <div className="flex justify-center sm:my-[1rem]">
                <p
                  onClick={() => {
                    handleStart();
                    setResendEmailTimer(30);
                  }}
                  className={
                    resendEmailTimer == 0
                      ? 'absolute underline resend-btn text-[#14539A] xs:top-[50%]  cursor-pointer sm:top-[58%] text-[12px] top-[121%]'
                      : 'absolute resend-btn text-gray-600 xs:top-[50%] text-[12px] top-[121%] sm:top-[58%]'
                  }
                >
                  Resend OTP
                  {resendEmailTimer == 0
                    ? ''
                    : ' in ' + calculateResendEmailTimer}
                </p>
              </div>
            </div>

            {!shopInfo.email.isVerified && (
              <div>
                <div
                  className={
                    shopInfo.errors.email
                      ? 'hidden'
                      : 'hidden cnt_of_submit_n_resend_btn sm:basis-[100%]  flex space-x-4'
                  }
                >
                  <button
                    type="button"
                    onClick={(event: any) => {
                      let otp_info =
                        document.getElementsByClassName('otp_info')[0];
                      PostRequest('otp/verify', {
                        email: shopInfo.email.id,
                        otp: emailOTP.join(''),
                      })
                        .then((res) => {
                          if (res.status == 200) {
                            setShopInfo({
                              ...shopInfo,
                              email: {
                                id: shopInfo.email.id,
                                isVerified: true,
                              },
                            });
                            let otp_section =
                              document.querySelector('.otp_section');
                            otp_section?.classList.add('hidden');
                            let verified_email =
                              document.querySelector('.verified_email');
                            verified_email?.classList.remove('hidden');
                          }
                        })
                        .catch((err) => {
                          otp_info.innerHTML = err.response.data.message;
                        });
                    }}
                    className="button-primary submit_btn shadow-inner"
                  >
                    Submit
                  </button>
                </div>
                <div>
                  <button
                    type="button"
                    disabled={shopInfo.errors.email ? true : false}
                    onClick={async (event: any) => {
                      if (validateEmail(shopInfo.email.id)) {
                        let email_section =
                          document.getElementsByClassName('email_section')[0];
                        email_section.classList.remove('my-10');
                        email_section.classList.add('my-[50px]');
                        event.target.classList.add('hidden');
                        let cnt_of_submit_n_resend_btn =
                          document.getElementsByClassName(
                            'cnt_of_submit_n_resend_btn'
                          )[0];
                        cnt_of_submit_n_resend_btn.classList.remove('hidden');

                        let otpField =
                          document.getElementsByClassName('otpField')[0];
                        otpField.classList.remove('hidden');
                        otpField.classList.add('block');

                        await PostRequest('otp/send', {
                          email: shopInfo.email.id,
                        })
                          .then((res) => {
                            if (res.status == 200) {
                              handleStart();
                            }
                          })
                          .catch((err) =>
                            setShopInfo({
                              ...shopInfo,
                              errors: {
                                ...shopInfo.errors,
                                email: err.data.message,
                              },
                            })
                          );
                      } else {
                        setShopInfo((prevState: any) => {
                          let { errors } = prevState;
                          return {
                            ...prevState,
                            errors: {
                              ...errors,
                              email: 'email is required',
                            },
                          };
                        });
                      }
                    }}
                    className={
                      shopInfo.errors.email
                        ? 'button-primary-disabled shadow-inner'
                        : 'button-primary shadow-inner'
                    }
                  >
                    Verify
                  </button>
                </div>
              </div>
            )}
          </div>

          <div
            className={
              shopInfo.email.isVerified
                ? 'verified_email basis-[47.5%] sm:justify-center relative flex-start flex sm:mt-3 items-center'
                : 'hidden verified_email basis-[47.5%] sm:justify-center relative flex-start flex sm:mt-3 items-center'
            }
          >
            <button
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              type="button"
              className="button-primary"
              onClick={() => setShowConfirmationBox(true)}
            >
              Edit
            </button>
          </div>
        </div>

        <div className="flex my-10 justify-between  sm:flex-col ">
          <div className="basis-[47.5%] sm:flex-wrap sm:justify-start  flex justify-between">
            <div className="basis-[25%] sm:basis-[100%]">
              <FormControl
                fullWidth
                sx={{
                  '& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root': {
                    lineHeight: '1rem',
                    fontSize: '0.90rem',
                    color: '#9FA6B3',
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderRadius: '0.35rem',
                      borderColor: '#E5E7EB',
                    },
                  },
                }}
              >
                <InputLabel>Label</InputLabel>
                <Select
                  name="label"
                  style={{
                    width: '100%',
                  }}
                  sx={{
                    '& .MuiSelect-select': {
                      padding: '0.75rem 1.25rem',
                    },
                    '& .css-u0uils-MuiFormLabel-root-MuiInputLabel-root': {
                      borderColor: '#E5E7EB',
                    },
                  }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="label"
                  value={phoneNumberObj.label}
                  required
                  error={phoneNumberObj.error.label ? true : false}
                  onChange={(event: any) => {
                    let { value } = event.target;
                    return setPhoneNumberObj({
                      ...phoneNumberObj,
                      label: value,
                      error: {
                        ...phoneNumberObj.error,
                        label: '',
                      },
                    });
                  }}
                >
                  <MenuItem value="Work">Work</MenuItem>
                  <MenuItem value="Personal">Personal</MenuItem>
                  <MenuItem value="Others">Others</MenuItem>
                </Select>
                <span
                  style={{
                    color: '#D3382F',
                    fontSize: '12.5px',
                    marginLeft: '0.5rem',
                  }}
                >
                  {phoneNumberObj.error.label}
                </span>
              </FormControl>
            </div>
            <div className="sm:basis-[30%] basis-[16%] sm:mt-[1.5rem]">
              <CssTextField1
                id="outlined-basic"
                name="countryCode"
                type="text"
                label="Code"
                value={'🇮🇳 ' + phoneNumberObj.countryCode}
              />
            </div>
            <div className="sm:basis-[45%] sm:ml-[2rem] sm:mt-[1.5rem]">
              <CssTextField
                // sx={{ width: '100%' }}
                id="outlined-basic"
                name="phoneNumber"
                type="number"
                value={phoneNumberObj.phoneNumber}
                onChange={(event: any) => {
                  let { value } = event.target;
                  if (value.length == 10) {
                    setPhoneNumberObj({
                      ...phoneNumberObj,
                      phoneNumber: value,
                      error: {
                        ...phoneNumberObj.error,
                        phoneNumber: '',
                      },
                    });
                  } else {
                    setPhoneNumberObj({
                      ...phoneNumberObj,
                      phoneNumber: value,
                      error: {
                        ...phoneNumberObj.error,
                        phoneNumber: 'Please enter a valid number',
                      },
                    });
                  }
                }}
                label="Phone Number"
                required
                placeholder="eg: 1234567890"
                error={phoneNumberObj.error.phoneNumber ? true : false}
                helperText={phoneNumberObj.error.phoneNumber}
              />
            </div>
          </div>

          <div className="basis-[47.5%] sm:self-center sm:justify-center sm:flex-wrap justify-between flex sm:mt-[2rem]">
            <div className="phone_otpField sm:basis-[100%] hidden relative flex flex-col justify-center">
              <div className="flex justify-center">
                <span className="text-[#14539a] sm:mb-[0.5rem] sm:top-[-70%] otp_info top-[-48%] absolute text-xs">
                  OTP has been sent to your number
                </span>
              </div>

              <div className=" sm:self-center space-x-[0.67rem]">
                {otp.map((_, index) => {
                  return (
                    <input
                      key={index}
                      value={otp[index]}
                      onChange={(event) => {
                        return;
                      }}
                      type="text"
                      maxLength={1}
                      onFocus={(event) => {
                        event.target.classList.add('border-solid');
                        event.target.classList.add('border-[1.5px]');
                        event.target.classList.add('border-black');
                      }}
                      onBlur={(event) => {
                        event.target.classList.remove('border-solid');
                        event.target.classList.remove('border-[1.5px]');
                        event.target.classList.remove('border-black');
                      }}
                      ref={index === activeOtpIndex ? inputRef : null}
                      onKeyDown={(event) =>
                        phoneOtpHandleOnKeyDown(event, index)
                      }
                      className="shadow-md h-[27px] sm:h-[39px] sm:w-[39px] xs:w-[30px] xs:h-[30px]  bg-[#F6F6FB] inline-block   w-[27px] rounded-md text-center"
                    />
                  );
                })}
              </div>

              <div className="flex justify-center">
                <p
                  onClick={async () => {
                    await PostRequest('otp/send', {
                      nationalNumber: phoneNumberObj.phoneNumber,
                      countryCode: phoneNumberObj.countryCode,
                      label: phoneNumberObj.label,
                    })
                      .then((res) => {
                        if (res.status == 200) {
                          handleStartPhoneOTPTimer();
                          setResendPhoneOTPTimer(30);
                        }
                      })
                      .catch((err) =>
                        console.log(
                          'facing error while sending the otp to number'
                        )
                      );
                  }}
                  className={
                    resendPhoneOTPTimer == 0
                      ? 'absolute underline resend-btn text-[#14539A]  cursor-pointer text-[12px] top-[121%]'
                      : 'absolute resend-btn text-gray-600  text-[12px] top-[121%]'
                  }
                >
                  Resend OTP
                  {resendPhoneOTPTimer == 0
                    ? ''
                    : ' in ' + calculateResendPhoneOTPTimer}
                </p>
              </div>
            </div>

            <div>
              <div className="hidden sm:mt-[2.5rem] sm:basis-[100%] cnt_of_submit_n_resend_phone_btn flex space-x-4 ">
                <button
                  type="button"
                  className="button-primary  submit_btn shadow-inner"
                  onClick={async () => {
                    let phoneOTP = otp.join('');
                    await PostRequest('otp/verify', {
                      countryCode: phoneNumberObj.countryCode,
                      nationalNumber: phoneNumberObj.phoneNumber,
                      otp: phoneOTP,
                    })
                      .then((res: any) => {
                        if (res.status == 200) {
                          setPhoneNumberObj({
                            ...phoneNumberObj,
                            isVerified: true,
                          });

                          let phoneNumbersArray = [
                            ...shopInfo.phoneNumbers,
                            phoneNumberObj,
                          ];

                          setShopInfo((prevShopInfo: any) => {
                            return {
                              ...prevShopInfo,
                              phoneNumbers: phoneNumbersArray,
                            };
                          });

                          setPhoneNumberObj({
                            phoneNumber: '',
                            label: '',
                            isVerified: false,
                            countryCode: '+91',
                            error: {
                              phoneNumber: '',
                              label: '',
                              isVerified: '',
                            },
                          });

                          setOtp(new Array(6).fill(''));

                          let cnt_of_submit_n_resend_phone_btn =
                            document.getElementsByClassName(
                              'cnt_of_submit_n_resend_phone_btn'
                            )[0];
                          cnt_of_submit_n_resend_phone_btn.classList.add(
                            'hidden'
                          );
                          let phone_otpField =
                            document.getElementsByClassName(
                              'phone_otpField'
                            )[0];
                          phone_otpField.classList.add('hidden');
                          let verify_number_btn =
                            document.getElementsByClassName(
                              'verify_number_btn'
                            )[0];
                          verify_number_btn.classList.remove('hidden');
                        }
                      })
                      .catch((err) => {
                        console.log(
                          err,
                          'this is err after submittting incorrect otp'
                        );
                      });
                  }}
                >
                  Submit
                </button>
              </div>

              <button
                type="button"
                onClick={async (event: any) => {
                  if (
                    phoneNumberObj.phoneNumber.length == 10 &&
                    phoneNumberObj.label != ''
                  ) {
                    event.target.classList.add('hidden');
                    let cnt_of_submit_n_resend_phone_btn =
                      document.getElementsByClassName(
                        'cnt_of_submit_n_resend_phone_btn'
                      )[0];
                    cnt_of_submit_n_resend_phone_btn.classList.remove('hidden');

                    let phone_otpField =
                      document.getElementsByClassName('phone_otpField')[0];
                    phone_otpField.classList.remove('hidden');
                    phone_otpField.classList.add('block');
                    handleStartPhoneOTPTimer();
                    await PostRequest('otp/send', {
                      countryCode: phoneNumberObj.countryCode,
                      nationalNumber: phoneNumberObj.phoneNumber,
                    });
                  } else if (
                    phoneNumberObj.phoneNumber.length != 10 &&
                    phoneNumberObj.label == ''
                  ) {
                    setPhoneNumberObj({
                      ...phoneNumberObj,
                      error: {
                        ...phoneNumberObj.error,
                        phoneNumber: 'Please enter 10 digit number',
                        label: 'Choose one',
                      },
                    });
                  } else if (phoneNumberObj.label == '') {
                    setPhoneNumberObj({
                      ...phoneNumberObj,
                      error: {
                        ...phoneNumberObj.error,
                        label: 'Choose here',
                      },
                    });
                  } else if (phoneNumberObj.phoneNumber.length != 10) {
                    setPhoneNumberObj({
                      ...phoneNumberObj,
                      error: {
                        ...phoneNumberObj.error,
                        phoneNumber: 'Please enter a 10 digit number',
                      },
                    });
                  }
                }}
                disabled={
                  phoneNumberObj.error.phoneNumber ||
                  phoneNumberObj.error.label ||
                  phoneNumberObj.error.isVerified
                    ? true
                    : false
                }
                className={
                  phoneNumberObj.error.phoneNumber ||
                  phoneNumberObj.error.label ||
                  phoneNumberObj.error.isVerified
                    ? 'button-primary-disabled  verify_number_btn shadow-inner'
                    : 'button-primary verify_number_btn  shadow-inner'
                }
              >
                Verify
              </button>
            </div>
          </div>
        </div>

        {/* only verified numbers */}
        {shopInfo.phoneNumbers.map((phoneNumberObj: any) => {
          return (
            <div
              key={phoneNumberObj.phoneNumber}
              className="flex my-10 sm:flex-col justify-between"
            >
              <div className="basis-[47.5%] sm:flex-wrap sm:justify-start  flex justify-between">
                <div className="basis-[25%] sm:basis-[100%]">
                  <FormControl
                    fullWidth
                    sx={{
                      '& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root': {
                        lineHeight: '1rem',
                        fontSize: '0.90rem',
                        color: '#9FA6B3',
                      },
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderRadius: '0.35rem',
                          borderColor: '#E5E7EB',
                        },
                      },
                    }}
                  >
                    <InputLabel>Label</InputLabel>
                    <Select
                      name="label"
                      style={{
                        width: '100%',
                      }}
                      sx={{
                        '& .MuiSelect-select': {
                          padding: '0.75rem 1.25rem',
                        },
                        '& .css-u0uils-MuiFormLabel-root-MuiInputLabel-root': {
                          borderColor: '#E5E7EB',
                        },
                      }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="label"
                      value={phoneNumberObj.label}
                      onChange={(event: any) => {
                        let { value } = event.target;
                        return setPhoneNumberObj({
                          ...phoneNumberObj,
                          label: value,
                        });
                      }}
                    >
                      <MenuItem value="Work">Work</MenuItem>
                      <MenuItem value="Personal">Personal</MenuItem>
                      <MenuItem value="Others">Others</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="sm:basis-[30%]  basis-[16%] sm:mt-[1.5rem]">
                  <CssTextField1
                    id="outlined-basic"
                    name="countryCode"
                    type="text"
                    label="Code"
                    value={'🇮🇳 ' + '+91'}
                  />
                </div>
                <div className="sm:basis-[45%] sm:ml-[2rem] sm:mt-[1.5rem]">
                  <CssTextField
                    // sx={{ width: '100%' }}
                    id="outlined-basic"
                    name="phoneNumber"
                    type="number"
                    value={
                      phoneNumberObj.phoneNumber.includes('+91')
                        ? phoneNumberObj.phoneNumber.slice(3)
                        : phoneNumberObj.phoneNumber
                    }
                    label="Phone Number"
                    placeholder="eg: 1234567890"
                  />
                </div>
              </div>

              <div className="basis-[47.5%] sm:mt-[1.5rem] sm:basis-[100%] sm:self-center sm:justify-center ">
                <button
                  type="button"
                  onClick={() => {
                    let previousPhoneNumbers = [...shopInfo.phoneNumbers];
                    let updatedPhoneNumbers = previousPhoneNumbers.filter(
                      (previousPhoneNumber: any) => {
                        if (
                          previousPhoneNumber.phoneNumber !=
                          phoneNumberObj.phoneNumber
                        ) {
                          return previousPhoneNumber;
                        }
                      }
                    );
                    setShopInfo((prevShopInfo: any) => {
                      return {
                        ...prevShopInfo,
                        phoneNumbers: updatedPhoneNumbers,
                      };
                    });
                  }}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  className="button-primary"
                >
                  {'Delete   '}
                  <span className="ml-[1rem]"></span> <BsTelephoneMinus />
                </button>
              </div>
            </div>
          );
        })}

        <hr />
        <div className="text-right my-10 mx-2 store-profile-update-cnt">
          <button
            type="button"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onClick={handleUpdateStoreContact}
            disabled={
              !shopInfo.email ||
              !shopInfo.email.isVerified ||
              shopInfo.phoneNumbers.length == 0
                ? true
                : false
            }
            className={
              !shopInfo.email ||
              !shopInfo.email.isVerified ||
              shopInfo.phoneNumbers.length == 0
                ? 'button-primary-disabled md:w-[5rem] md:text-[12px]'
                : 'button-primary md:w-[5rem] md:text-[12px]'
            }
          >
            Update
          </button>
        </div>
      </form>
    </div>
  );
}
