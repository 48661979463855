import {
  GoogleMap,
  useJsApiLoader,
  MarkerF,
  Autocomplete as GoogleMapAutoComplete,
} from '@react-google-maps/api';
import { useEffect } from 'react';
import { useMemo, useRef, useState } from 'react';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { MdOutlineKeyboardBackspace } from 'react-icons/md';
import { IoMdArrowBack } from 'react-icons/io';
import { PatchRequest } from '../../../../components/Request';

const libraries1 = ['places'];

const CssTextField = styled(TextField)({
  '& .MuiInputBase-input': {
    padding: '0.75rem 1.25rem',
  },
  '& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root': {
    lineHeight: '1rem',
    fontSize: '0.90rem',
    color: '#9FA6B3',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: '0.35rem',
      borderColor: '#E5E7EB',
    },
  },
});

export default function StoreLocation(props: any) {
  let {
    setShopLocation,
    shopLocation,
    shopName,
    latitude,
    setLatitude,
    longitude,
    setLongitude,
    setSelectedNavigation,
  } = props;
  let { shopAddress, landmark, district, state, country, zipCode, tehsil } =
    shopLocation.errors;

  console.log(shopLocation, 'this is the shop location');
  const [searchResult, setSearchResult] = useState<any>(null);

  const containerStyle = {
    height: '75vh',
    marginBottom: '1.3rem',
    marginTop: '1.8rem',
    borderRadius: '0.4rem',
  };

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API as string,
    libraries: ['places'],
  });

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setLatitude(position.coords.latitude);
      setLongitude(position.coords.longitude);
    });
  }, []);

  function onLoad(autocomplete: any) {
    setSearchResult(autocomplete);
  }

  function onPlaceChanged() {
    if (searchResult != null) {
      let locationObj = searchResult.getPlace();
      setLatitude(locationObj.geometry.location.lat());
      setLongitude(locationObj.geometry.location.lng());
    }
  }

  const handleChange = (event: any) => {
    let { value, name } = event.target;
    let { errors } = shopLocation;
    switch (name) {
      case 'shopAddress':
        errors.shopAddress = !value ? 'Shop Address is required' : '';
        break;
      case 'tehsil':
        errors.tehsil = !value ? 'tehsil is required' : '';
        break;
      case 'district':
        errors.district = !value ? 'district is required' : '';
        break;
      case 'state':
        errors.state = !value ? 'state is required' : '';
        break;
      case 'country':
        errors.country = !value ? 'country is required' : '';
        break;
      case 'zipCode':
        errors.zipCode = !value ? 'zip code is required' : '';
        break;
      case 'landmark':
        errors.landmark = !value ? 'landmark is required' : '';
        break;
    }

    setShopLocation({
      ...shopLocation,
      [name]: value,
    });
  };

  const updateShopInfo = async () => {
    await PatchRequest('store', {
      district: shopLocation.district,
      postalCode: shopLocation.postalCode,
      tehsil: shopLocation.tehsil,
      state: shopLocation.state,
      address: shopLocation.address,
      landmark: shopLocation.landmark,
    })
      .then((res: any) => {
        if (res.status === 200) {
          // setShowIndicator(true);
          // setMessage('Updated');
          // setType('success');
          // setTimeout(() => {
          //   setShowIndicator(false);
          // }, 3000);
        }
      })
      .catch((err) => {
        // setShowIndicator(true);
        // setMessage(err.response.data.message);
        // setType('error');
        // setTimeout(() => {
        //   setShowIndicator(false);
        // }, 3000);
      });
  };

  const center = { lat: latitude, lng: longitude };

  return (
    <div className="store-profile-form">
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
        }}
      >
        <button
          className="go-back-button"
          onClick={() => setSelectedNavigation('')}
        >
          <MdOutlineKeyboardBackspace style={{ display: 'inline' }} />
        </button>
      </div>
      <div id="stepTwo" className="w-full">
        <form
          action="#"
          onSubmit={(e) => {
            e.preventDefault();
          }}
          className="space-y-7 mb-7"
        >
          <div className="mx-9 md:mx-2 relative">
            {isLoaded ? (
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={18}
                onClick={(event: any) => {
                  let lat = event.latLng.lat();
                  let lng = event.latLng.lng();
                  setLatitude(lat);
                  setLongitude(lng);
                }}
                options={{
                  fullscreenControl: false,
                }}
              >
                <MarkerF
                  onDragEnd={(event: any) => {
                    let lat = event.latLng.lat();
                    let lng = event.latLng.lng();
                    setLatitude(lat);
                    setLongitude(lng);
                  }}
                  draggable={true}
                  position={center}
                />

                <GoogleMapAutoComplete
                  onPlaceChanged={onPlaceChanged}
                  onLoad={onLoad}
                >
                  <div className="flex justify-center">
                    <input
                      type="text"
                      placeholder="search your shop"
                      className="md:w-[90%] w-[50%]"
                      style={{
                        border: '1px solid #E0E0E0',
                        marginTop: '4rem',
                        boxSizing: `border-box`,
                        height: `42px`,
                        padding: `0 12px`,
                        borderRadius: `5px`,
                        boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                        fontSize: `14px`,
                        outline: `none`,
                        textOverflow: `ellipses`,
                        position: 'absolute',
                      }}
                      name="searchText"
                    />
                  </div>
                </GoogleMapAutoComplete>
                <button className="w-[22px] -ml-7 inline">
                  <svg
                    className="z-50"
                    viewBox="0 0 24 24"
                    preserveAspectRatio="xMidYMid meet"
                    focusable="false"
                  >
                    <g>
                      <path d="M20.87,20.17l-5.59-5.59C16.35,13.35,17,11.75,17,10c0-3.87-3.13-7-7-7s-7,3.13-7,7s3.13,7,7,7c1.75,0,3.35-0.65,4.58-1.71 l5.59,5.59L20.87,20.17z M10,16c-3.31,0-6-2.69-6-6s2.69-6,6-6s6,2.69,6,6S13.31,16,10,16z"></path>
                    </g>
                  </svg>
                </button>
              </GoogleMap>
            ) : (
              <h1>{isLoaded}</h1>
            )}
          </div>

          <div className="flex my-9 justify-between  md:flex-col md:space-y-7 mx-9 md:mx-2">
            <div className="basis-[45%] md:w-full">
              <CssTextField
                sx={{
                  width: '100%',
                }}
                id="outlined-basic"
                name="shopAddress"
                value={shopLocation.shopAddress}
                onChange={handleChange}
                label="Shop address"
                placeholder="Enter your shop address"
                error={shopAddress ? true : false}
                helperText={shopAddress ? 'Shop Address is required' : ''}
              />
            </div>
            <div className="basis-[45%] md:w-full">
              <CssTextField
                sx={{
                  width: '100%',
                }}
                id="outlined-basic"
                name="landmark"
                value={shopLocation.landmark}
                onChange={handleChange}
                label="Landmark"
                placeholder="near shiv mandir"
                error={landmark ? true : false}
                helperText={landmark ? 'landmark is required' : ''}
              />
            </div>
          </div>
          <div className="flex my-9 justify-between  md:flex-col md:space-y-7 mx-9 md:mx-2">
            <div className="basis-[45%] md:w-full">
              <CssTextField
                sx={{
                  width: '100%',
                }}
                id="outlined-basic"
                name="tehsil"
                value={shopLocation.tehsil}
                error={tehsil ? true : false}
                helperText={tehsil ? 'tehsil is required' : ''}
                onChange={handleChange}
                label="Tehsil"
                placeholder="enter tehsil like rohini"
              />
            </div>
            <div className="basis-[45%] md:w-full">
              <CssTextField
                sx={{
                  width: '100%',
                }}
                id="outlined-basic"
                name="district"
                value={shopLocation.district}
                error={district ? true : false}
                helperText={district ? 'district is required' : ''}
                onChange={handleChange}
                label="District"
                placeholder="eg: north west Delhi"
              />
            </div>
          </div>
          <div className="flex my-9 justify-between  md:flex-col md:space-y-7 mx-9 md:mx-2">
            <div className="basis-[45%] md:w-full">
              <CssTextField
                sx={{
                  width: '100%',
                }}
                id="outlined-basic"
                name="state"
                label="State"
                error={state ? true : false}
                helperText={state ? 'state is required' : ''}
                value={shopLocation.state}
                onChange={handleChange}
                placeholder="eg: Delhi"
              />
            </div>
            <div className="basis-[45%] md:w-full">
              <CssTextField
                sx={{
                  width: '100%',
                }}
                id="outlined-basic"
                value={shopLocation.country}
                onChange={handleChange}
                error={country ? true : false}
                helperText={country ? 'country is required' : ''}
                name="country"
                label="Country"
                placeholder="eg: India"
              />
            </div>
          </div>
          <div className="flex my-9 justify-between  md:flex-col md:space-y-7 mx-9 md:mx-2">
            <div className="basis-[45%] md:w-full">
              <CssTextField
                sx={{
                  width: '100%',
                }}
                value={shopLocation.zipCode}
                id="outlined-basic"
                onChange={handleChange}
                name="zipCode"
                label="Zip Code"
                error={zipCode ? true : false}
                helperText={zipCode ? 'zipCode is required' : ''}
                placeholder="eg: 110088"
              />
            </div>
          </div>
        </form>
        <hr />
        <div className="flex mt-7 justify-end mx-2">
          <button
            type="button"
            disabled={
              !shopLocation.shopAddress ||
              !shopLocation.landmark ||
              !shopLocation.district ||
              !shopLocation.state ||
              !shopLocation.country ||
              !shopLocation.zipCode ||
              !shopLocation.tehsil
                ? true
                : false
            }
            className={
              !shopLocation.shopAddress ||
              !shopLocation.landmark ||
              !shopLocation.district ||
              !shopLocation.state ||
              !shopLocation.country ||
              !shopLocation.zipCode ||
              !shopLocation.tehsil
                ? 'button-primary-disabled  md:w-[5rem] md:text-[12px]'
                : 'button-primary md:w-[5rem] md:text-[12px]'
            }
          >
            Update
          </button>
        </div>
      </div>
    </div>
  );
}
