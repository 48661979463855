import { AiOutlinePlus } from 'react-icons/ai';
import { FaRoute } from 'react-icons/fa';
import { FiCalendar, FiEdit, FiInfo, FiUser } from 'react-icons/fi';
import './styles/sellerRoute.scss';
import { RxCross1 } from 'react-icons/rx';

export default function SellerRoute({
  setActiveSellerRoute,
  route,
  setShowSellerRoute,
}: any) {
  function convertTimeFormat(inputTime: string): string {
    try {
      const [timePart, _] = inputTime.split('+');
      const [hours, minutes] = timePart.split(':').map(Number);

      let period = 'AM';
      let convertedHours = hours;

      if (convertedHours >= 12) {
        period = 'PM';
        if (convertedHours > 12) {
          convertedHours -= 12;
        }
      }

      const convertedTime = `${convertedHours}:${minutes
        .toString()
        .padStart(2, '0')} ${period}`;
      return convertedTime;
    } catch (error) {
      return 'Invalid input time format';
    }
  }

  function convertToDatetimeLocalFormat(inputFormat: any) {
    try {
      const inputDate = new Date(inputFormat);

      if (isNaN(inputDate.getTime())) {
        throw new Error('Invalid input format');
      }

      const year = inputDate.getFullYear();
      const month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
      const day = inputDate.getDate().toString().padStart(2, '0');
      const hours = inputDate.getHours().toString().padStart(2, '0');
      const minutes = inputDate.getMinutes().toString().padStart(2, '0');

      const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;
      return formattedDate;
    } catch (error) {
      return 'Invalid input format';
    }
  }

  return (
    <div
      className="route-boxes-cnt"
      // style={{ backgroundImage: "url('/bg-route-info.jpeg')" }}
    >
      <header className="heading-of-route-page">
        <h1>Your Seller Routes.</h1>
        <span
          className="cross-icon"
          onClick={() => {
            setShowSellerRoute(false);
            setActiveSellerRoute([]);
          }}
        >
          <RxCross1 />
        </span>
      </header>
      <div className="middle-cnt">
        {route.length > 0 ? (
          route.map((data: any) => {
            return (
              <div key={data._id} className="route-box">
                <img
                  src="/images/google-maps.jpg"
                  alt="Map Icon"
                  className="map-icon"
                />
                <div className="route-details">
                  <h2 className="route-name">{data.name}</h2>
                  <p className="route-description">{data.description}</p>

                  <p className="route-schedule">
                    <span className="label">Schedule: </span>

                    {data.scheduleCommonDayList.join(', ')}
                  </p>
                  <p className="route-coverage">
                    <span className="label"> Areas: </span>

                    {data.coverageArea.placeNameList.length > 0 &&
                      data.coverageArea.placeNameList.join(', ')}
                  </p>
                  <p className="route-start-time">
                    <span className="label"> Start Time: </span>
                    {convertTimeFormat(data.commonStartTime)}
                  </p>
                  <p className="route-end-time">
                    <span className="label">End Time: </span>

                    {convertTimeFormat(data.commonEndTime.time)}
                  </p>

                  {data?.upcomingRouteSchedule?.startDateAndTime ? (
                    <p className="route-start-date">
                      <span className="label">
                        Upcoming Start Date And Time:
                      </span>

                      {convertToDatetimeLocalFormat(
                        data.upcomingRouteSchedule.startDateAndTime
                      )}
                    </p>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            );
          })
        ) : (
          <h2 className="no-route-info">
            No routes found, comeback later, he/she might be updating the routes
            !
          </h2>
        )}
      </div>
    </div>
  );
}
