// import { useState, useEffect, useContext } from 'react';
// import ActiveStoreContext from '../../../context/ActiveStore/ActiveStoreContext';
// import { GetRequest, PostRequest } from '../../../components/Request';
// import { FaStore } from 'react-icons/fa';
// import './dashboard.scss';
// import { fetchUserInfoAsyncThunk } from '../../../features/auth/Slice';
// import { useDispatch } from 'react-redux';
// import { AppDispatch } from '../../../store';
// import { Link } from 'react-router-dom';

// export default function Dashboard() {
//   const [stores, setStores] = useState([]);
//   const dispatch = useDispatch<AppDispatch>()
//   dispatch(fetchUserInfoAsyncThunk({}))
//   let { activeStoreState, setActiveStoreState }: any =
//     useContext(ActiveStoreContext);
//   const [showListOfStores, setShowListOfStores] = useState(false);

//   useEffect(() => {
//     let allShops = JSON.parse(localStorage.shops);
//     if (allShops.length > 1 == true) {
//       setStores(allShops);
//       setShowListOfStores(true);
//     }
//   }, []);

//   const getCurrentActiveStore = async () => {
//     await GetRequest('store/self')
//       .then((res: any) => {
//         if (res.data.statusCode == 200) {
//           localStorage.setItem(
//             'activeStore',
//             JSON.stringify({
//               ...res.data.data,
//               storeId: res.data.data._id,
//               name: res.data.data.name,
//             })
//           );
//           setActiveStoreState({
//             storeId: res.data.data._id,
//             name: res.data.data.name,
//           });
//         }
//       })
//       .catch((err) => {
//         if (err) {
//           setActiveStoreState({
//             storeId: '',
//             name: '',
//           });
//         }
//       });
//   };

//   const makeStoreActive = async (store: any) => {
//     await PostRequest('auth/change-profile', {
//       self: false,
//       storeId: store.id,
//     })
//       .then(async (res: any) => {
//         console.log(res, 'this is the res');
//         if (res.data.statusCode == 200) {
//           setShowListOfStores(false);
//           // need to change this thing.
//           let newState = {
//             storeId: store.id,
//             name: store.name,
//           };
//           setActiveStoreState(newState);
//           await getCurrentActiveStore();
//           // localStorage.setItem(
//           //   'activeStore',
//           //   JSON.stringify({ storeId: store.id, name: store.name })
//           // );
//         }
//       })
//       .catch((err) => {
//         setShowListOfStores(false);
//       });
//   };

//   return (
//     <div
//       style={{
//         paddingTop: '100px',
//       }}
//     >
//       {showListOfStores ? (
//         <ListOfStores stores={stores} makeStoreActive={makeStoreActive} />
//       ) : (
//         ''
//       )}
//       Dashboard
//     </div>
//   );
// }

// function ListOfStores(props: any) {
//   let { stores, makeStoreActive } = props;
//   return (
//     <section className="confirmation-cnt lists-of-stores">
//       <div className="confirmation-box">
//         <p>Choose one store to make active</p>
//         <ul
//           style={{
//             padding: '0 0.8rem',
//           }}
//         >
//           {stores.length > 0
//             ? stores.map((store: any) => {
//                 return (
//                   <li
//                     key={store.id}
//                     className="store"
//                     onClick={() => makeStoreActive(store)}
//                   >
//                     <Link to="#">
//                       <FaStore style={{ display: 'inline-block' }} />
//                       <span
//                         style={{
//                           marginLeft: '0.5rem',
//                         }}
//                       >
//                         {store.name}
//                       </span>
//                     </Link>
//                   </li>
//                 );
//               })
//             : ''}
//         </ul>
//       </div>
//     </section>
//   );
// }

import { useState, useEffect, useRef } from 'react';
import { FaStore } from 'react-icons/fa';
import './dashboard.scss';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../store';
import {
  fetchUserInfoAsyncThunk,
  profileTypeSelector,
  switchProfileAsyncThunk,
} from '../../../features/auth/Slice';
import {
  currentUserSelector,
  storeListSelector,
} from '../../../features/auth/Slice';
import { GetRequest, PostRequest } from '../../../components/Request';
import { IoIosArrowRoundForward } from 'react-icons/io';
import { FiSearch } from 'react-icons/fi';
import { profile } from 'console';

export default function Dashboard() {
  const [showListsOfStores, setShowListsOfStores] = useState<boolean>(false);
  const [storeList, setStoreList] = useState<any>([]);

  const { slug } = useParams();
  const navigate = useNavigate();
  const [sellerSlug, setSellerSlug] = useState<any>(slug);
  const [selectedNavigation, setSelectedNavigation] = useState('home');
  const [storeInfo, setStoreInfo] = useState<any>({});
  const [searchedValue, setSearchedValue] = useState<any>('');
  const storeListRef = useRef<any>(null);
  const storeListAndSearchBarRef = useRef<any>(null);
  const [allStore, setAllStore] = useState<any>([]);
  const [hasMore, setHasMore] = useState(true);
  const storeListRefForInfiniteScrolling = useRef(null);
  const [sellerList, setSellerList] = useState<any>([]);
  const [showSellerForm, setShowSellerForm] = useState<Boolean>(false);
  const sellerListRefForInfiniteScrolling = useRef<any>(null);
  const [hasMoreSellerListOrNot, setHasMoreSellerListOrNot] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageNumberForMainSellerList, setPageNumberForMainSellerList] =
    useState(1);

  const onIntersection = async (entries: any) => {
    const firstEntry = entries[0];

    if (firstEntry.isIntersecting && hasMore) {
      await setPageNumber((prevPageNumber) => prevPageNumber + 1);

      await fetchMoreBuyerAndSeller();
    }
  };

  useEffect(() => {
    if (storeListRefForInfiniteScrolling.current) {
      const observer = new IntersectionObserver(onIntersection);
      observer.observe(storeListRefForInfiniteScrolling.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [storeListRefForInfiniteScrolling.current]);

  const fetchMoreBuyerAndSeller = async () => {
    await GetRequest(`store?name=${searchedValue}&limit=10&page=${pageNumber}`)
      .then(async (res) => {
        if (res.data.statusCode === 200) {
          if (res.data.data.length === 0 || res.data.data.length < 10) {
            setHasMore(false);
          } else {
            setAllStore((prevStore: any) => [...prevStore, ...res.data.data]);
          }
        }
      })
      .catch((err) => {
        console.log(err, 'error in fetching all store for the first');
      });
  };

  const handleClickOutside = (event: any) => {
    if (
      storeListAndSearchBarRef.current &&
      !storeListAndSearchBarRef.current.contains(event.target)
    ) {
      setAllStore([]);
      setPageNumber(1);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (searchedValue) {
      getBuyerAndSeller();
    }
  }, [searchedValue]);

  const handleChangeOnSearchedValue = (event: any) => {
    setSearchedValue(event.target.value);
  };

  const getBuyerAndSeller = async () => {
    if (searchedValue) {
      await GetRequest(
        `store?name=${searchedValue}&limit=10&page=${pageNumber}`
      )
        .then(async (res) => {
          if (res.data.statusCode == 200) {
            setAllStore(res.data.data);
          }
        })
        .catch((err) => {
          console.log(err, 'this is err in fetching all store for the first');
        });
    }
  };

  const handleClickOnStore = (data: any) => {
    setSellerSlug(data.slug);
    navigate(`/store/${data.slug}`);
    setAllStore([]);
    setSearchedValue('');
  };

  // infinite  scrolling starts here

  const onIntersectionForSellerList = async (entries: any) => {
    const firstEntry = entries[0];

    if (firstEntry.isIntersecting && hasMoreSellerListOrNot) {
      setPageNumberForMainSellerList((prevPageNumber) => prevPageNumber + 1);
    }
  };

  useEffect(() => {
    if (sellerListRefForInfiniteScrolling.current) {
      const observer = new IntersectionObserver(onIntersectionForSellerList);
      observer.observe(sellerListRefForInfiniteScrolling.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [sellerList.length > 0]);

  useEffect(() => {
    getRegisteredSellerOnScroll(pageNumberForMainSellerList);
  }, [pageNumberForMainSellerList]);

  useEffect(() => {
    getSeller();
  }, []);

  const getSeller = async () => {
    await GetRequest(
      `store?buyer=true&page=${pageNumberForMainSellerList}&limit=10`
    )
      .then(async (res) => {
        if (res.data.statusCode === 200) {
          await setSellerList((prevState: any) => [
            ...prevState,
            ...res.data.data,
          ]);

          if (res.data.data.length < 10) {
            await setHasMoreSellerListOrNot(false);
          } else {
            await setHasMoreSellerListOrNot(true);
          }
        }
      })
      .catch((err) => {
        console.log(err, 'this is err in fetching all sellers');
      });
  };

  const getRegisteredSellerOnScroll = async (currentPageNumber: any) => {
    if (hasMoreSellerListOrNot) {
      await GetRequest(`store?buyer=true&page=${currentPageNumber}&limit=10`)
        .then(async (res) => {
          if (res.data.statusCode === 200) {
            if (res.data.data.length < 10) {
              setHasMoreSellerListOrNot(false);
            } else {
              setHasMoreSellerListOrNot(true);
            }
            setSellerList((prevState: any) => [...prevState, ...res.data.data]);
          }
        })
        .catch((err) => {
          console.log(err, 'this is err in fetching all sellers');
        });
    }
  };

  const getAllStore = async () => {
    await GetRequest('store/my-store?buyer=true')
      .then((res) => {
        if (res.data.statusCode === 200) {
          if (res.data.data.length > 1) {
            setStoreList(res.data.data);
            setShowListsOfStores(true);
          }
        }
      })
      .catch((err) => {
        console.log(err, 'err in getting all stores in dashboard');
      });
  };

  const getActiveStore = async () => {
    await GetRequest('store/self?profile-detail=true')
      .then(async (res) => {
        if (res.data.statusCode === 200) {
          return res.data.data;
        }
      })
      .catch(async (err) => {
        if (err.response.status === 403) {
          await getAllStore();
        }
      });
  };

  useEffect(() => {
    getActiveStore();
  }, []);

  return (
    <div
      style={{
        paddingTop: '100px',
      }}
      className="all-store-cnt"
    >
      {showListsOfStores ? (
        <ListOfStore
          storeList={storeList}
          // dispatch={dispatch}
          setShowListsOfStores={setShowListsOfStores}
        />
      ) : (
        ''
      )}
      <div
        className="all-store-cnt"
        style={{
          paddingTop: '50px',
        }}
      >
        <div className="search-bar-component">
          <div className="search-bar" ref={storeListAndSearchBarRef}>
            <input
              type="text"
              placeholder="Search Store..."
              value={searchedValue}
              onChange={handleChangeOnSearchedValue}
              onFocus={getBuyerAndSeller}
            />
            <button className="search-icon">
              <FiSearch />
            </button>
            {allStore.length > 0 ? (
              <ul
                className="store-list"
                style={{ overflowY: 'scroll' }}
                ref={storeListRef}
              >
                {allStore.map((data: any, index: number) => (
                  <li
                    key={data._id}
                    onClick={() => handleClickOnStore(data)}
                    className="each-store"
                  >
                    <img
                      src={
                        data.profileImage
                          ? data.profileImage
                          : '/distributor-icon.svg'
                      }
                      alt={data._id}
                      className="store-img"
                    />
                    <div>
                      <p className="name"> {data.name}</p>
                      <p className="location">
                        {data.address.district},{data.address.state},
                        {data.address.country} ,{data.address.postalCode}
                      </p>
                    </div>
                  </li>
                ))}
                {hasMore && allStore.length > 0 && (
                  <div ref={storeListRefForInfiniteScrolling}></div>
                )}
              </ul>
            ) : (
              ''
            )}
          </div>
        </div>
        <section className="seller-list">
          <div className="seller-list-container">
            {sellerList.length > 0 ? (
              sellerList.map((data: any) => {
                return (
                  <article className="flex-49">
                    <figure
                      className="figure-1 font-0"
                      style={{
                        background: data.profileImage ? 'none' : 'peachpuff',
                        borderRadius: '11px',
                      }}
                    >
                      <img
                        className="width-full"
                        src={
                          data.profileImage
                            ? data.profileImage
                            : 'images/seller.png'
                        }
                        style={{
                          objectFit: data.profileImage
                            ? 'contain'
                            : 'scale-down',
                        }}
                        alt="values-1"
                      />
                    </figure>
                    <div className="small-article">
                      <h4 className="text-center">{data.name}</h4>
                      <p>
                        We Provide Variety Of Products Such As{' '}
                        {data.typeList?.join(' , ')}.
                      </p>
                      <Link to={`/store/${data.slug}`}>
                        See Store
                        <span>
                          <IoIosArrowRoundForward className="arrow-right" />
                        </span>
                      </Link>
                    </div>
                  </article>
                );
              })
            ) : (
              <p>Loading...</p>
            )}

            {sellerList.length > 0 && (
              <div
                ref={sellerListRefForInfiniteScrolling}
                style={{ padding: '20px' }}
              ></div>
            )}
          </div>
        </section>
      </div>
    </div>
  );
}

function ListOfStore(props: any) {
  let { storeList, setShowListsOfStores } = props;
  const storeListRef = useRef<any>(null);
  // const dispatch = useDispatch<AppDispatch>();

  const handleClickOutside = (event: any) => {
    if (storeListRef.current && !storeListRef.current.contains(event.target)) {
      setShowListsOfStores(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const makeStoreActive = async (storeId: any) => {
    await PostRequest('auth/change-profile', {
      self: false,
      storeId,
    })
      .then((res) => {
        if (res.data.statusCode === 200) {
          setShowListsOfStores(false);
          window.location.reload();
        }
      })
      .catch((err) => console.log(err, 'err in make one store active'));
  };

  return (
    <section className="confirmation-cnt lists-of-stores">
      <div className="confirmation-box" ref={storeListRef}>
        <p>Choose one store to make active</p>
        <ul
          style={{
            padding: '0 0.8rem',
          }}
        >
          {storeList.length > 0
            ? storeList.map((store: any) => {
                return (
                  <li
                    key={store._id}
                    className="store"
                    onClick={() => makeStoreActive(store._id)}
                  >
                    <Link to="#">
                      <FaStore style={{ display: 'inline-block' }} />
                      <span
                        style={{
                          marginLeft: '0.5rem',
                        }}
                      >
                        {store.name}
                      </span>
                    </Link>
                  </li>
                );
              })
            : ''}
        </ul>
      </div>
    </section>
  );
}
