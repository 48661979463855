import { BsFillCameraFill } from 'react-icons/bs';
import '../storePublicProfile.scss';

export default function storePublicProfile(props: any) {
  return (
    <div className="public-store-profile-form">
      <div className="cover-picture">
        <img
          src="cover.jpg"
          alt="Cover Picture"
          style={{ border: '1px solid #ddd' }}
        />
      </div>
      {/* <div className="flex py-5 mt-[-5rem]  justify-center relative">
        <div
          style={{
            borderRadius: '4rem',
            border: '1px solid #14539A',
          }}
          className="w-[255px] h-[137px]  flex justify-center items-center"
        >
          <img
            style={{
              width: '100px',
              color: '#14539A',
              height: '100px',
            }}
            src="/distributor-icon.svg"
            alt="distributor-icon"
            className="user_icon"
          />
        </div>

        <div
          style={{
            position: 'absolute',
            bottom: '8%',
            background: '#14539a',
            width: '45px',
            height: '45px',
            lineHeight: '40px',
            textAlign: 'center',
            borderRadius: '50%',
            overflow: 'hidden',
            border: '4px solid white',
            cursor: 'pointer',
          }}
          className="flex cursor-pointer right-[40%] md:right-[36%] justify-center items-center"
        >
          <input
            type="file"
            style={{
              position: 'absolute',
              transform: 'scale(2)',
              opacity: '0',
              cursor: 'pointer',
            }}
            name="profileImage"
            // onChange={handleChange}
          />
          <BsFillCameraFill
            style={{
              cursor: 'pointer',
            }}
            className="w-[28px] h-[28px] text-white"
          />
        </div>
      </div> */}

      <div className="about-field">
        <textarea placeholder="About"></textarea>
      </div>
      <button type="submit" className="btn-primary">
        Submit
      </button>
    </div>
  );
}
