// for store profile
import { RxCross2 } from 'react-icons/rx';
import Autocomplete from '@mui/material/Autocomplete';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { PatchRequest } from '../../../../components/Request';
import { useState, useEffect } from 'react';
import { BsFillCameraFill } from 'react-icons/bs';
import { MdOutlineKeyboardBackspace } from 'react-icons/md';

const CssTextField = styled(TextField)({
  '& .MuiInputBase-input': {
    padding: '0.75rem 1.25rem',
  },
  '& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root': {
    lineHeight: '1rem',
    fontSize: '0.90rem',
    color: '#9FA6B3',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: '0.35rem',
      borderColor: '#E5E7EB',
    },
  },
});

export default function StoreProfile(props: any) {
  let {
    setShowIndicator,
    setMessage,
    setType,
    shopInfo,
    setShopInfo,
    setSelectedNavigation,
    handleChange,
    validateEmail,
  } = props;
  const shopTypes = [
    'Accessories',
    'Antique',
    'ArtSupply',
    'Bakery',
    'Bookstore',
    'Butcher',
    'Candy',
    'Cloth',
    'CleaningSupplies',
    'Coffee',
    'Convenience',
    'Cosmetics',
    'Department',
    'Discount',
    'Drug',
    'ECommerce',
    'Electronic',
    'Florist',
    'Furniture',
    'GamingConsole',
    'Garden',
    'Gift',
    'Greengrocer',
    'Grocery',
    'Handbags',
    'HomeAppliances',
    'HomeDecor',
    'Hypermarket',
    'Kios',
    'Liquor',
    'Organic',
    'Pet',
    'ScentedCandlesSoapPerf',
    'Shoe',
    'Souven',
    'Stall',
    'Stationery',
    'Supermarke',
    'Thrift',
    'Tobacco',
    'ToolA',
    'ToolAndEquip',
    'Toy',
    'Warehouse',
  ];
  let {
    shopName,
    shopType,
    shopCategory,
    phoneNumbers,
    panCard,
    tradeLicense,
    GST,
    email,
    shopImages,
    profileImage,
  } = shopInfo.errors;
  const [showConfirmationBox, setShowConfirmationBox] = useState(false);
  let { currentStep, setCurrentStep } = props;

  const [panCardInfo, setPanCard] = useState<any>({
    pancard: '',
    isPanCardValid: null,
  });

  useEffect(() => {
    return;
  }, [panCardInfo]);

  function handleDragStart(event: any) {
    event.dataTransfer.setData('text/plain', event.currentTarget.dataset.index);
  }

  function handleDragOver(event: any) {
    event.preventDefault();
  }

  function handleDrop(event: React.DragEvent<HTMLElement>) {
    event.preventDefault();

    const oldIndex = Number(event.dataTransfer.getData('text/plain'));
    const newIndex = Number(event.currentTarget.dataset.index);
    const maxIndex = shopInfo.shopImages.length - 1;

    if (
      oldIndex < 0 ||
      oldIndex > maxIndex ||
      newIndex < 0 ||
      newIndex > maxIndex
    ) {
      return; // Invalid index, do not perform the replacement
    }

    if (oldIndex === newIndex) {
      return; // No need to perform the replacement if oldIndex and newIndex are the same
    }

    setShopInfo((prevShopInfo: any) => {
      const newShopImages = [...prevShopInfo.shopImages];
      const draggedImage = newShopImages[oldIndex];
      newShopImages.splice(oldIndex, 1); // Remove the dragged element
      newShopImages.splice(newIndex, 0, draggedImage); // Insert the dragged element at the drop position

      return {
        ...prevShopInfo,
        shopImages: newShopImages,
      };
    });
  }

  const updateShopInfo = async () => {
    await PatchRequest('store', {
      profileImage: shopInfo.profileImage,
      name: shopInfo.shopName,
      category: 'Distributor',
      type: shopInfo.shopType,
      images: shopInfo.images,
    })
      .then((res: any) => {
        if (res.status === 200) {
          setShowIndicator(true);
          setMessage('Updated');
          setType('success');
          setTimeout(() => {
            setShowIndicator(false);
          }, 3000);
        }
      })
      .catch((err) => {
        setShowIndicator(true);
        setMessage(err.response.data.message);
        setType('error');
        setTimeout(() => {
          setShowIndicator(false);
        }, 3000);
      });
  };

  const handleUpdateShopProfile = () => {
    updateShopInfo();
  };

  return (
    <div className="cnt_of_stepone store-profile-form">
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
        }}
      >
        <button
          className="go-back-button"
          onClick={() => setSelectedNavigation('')}
        >
          <MdOutlineKeyboardBackspace style={{ display: 'inline' }} />
        </button>
      </div>
      <form action="#">
        <div className="flex py-5 justify-center relative">
          {shopInfo.profileImage ? (
            <img
              src={shopInfo.profileImage}
              className="w-[255px] h-[137px]  object-cover"
              style={{
                borderRadius: '4rem',
              }}
              alt={shopInfo.shopName}
            />
          ) : (
            <div
              style={{
                borderRadius: '4rem',
                border: '1px solid #14539A',
              }}
              className="w-[255px] h-[137px]  flex justify-center items-center"
            >
              <img
                style={{
                  width: '100px',
                  color: '#14539A',
                  height: '100px',
                }}
                src="/distributor-icon.svg"
                alt="distributor-icon"
                className="user_icon"
              />
            </div>
          )}
          <div
            style={{
              position: 'absolute',
              bottom: '8%',
              background: '#14539a',
              width: '45px',
              height: '45px',
              lineHeight: '40px',
              textAlign: 'center',
              borderRadius: '50%',
              overflow: 'hidden',
              border: '4px solid white',
              cursor: 'pointer',
            }}
            className="flex cursor-pointer right-[40%] md:right-[36%] justify-center items-center"
          >
            <input
              type="file"
              style={{
                position: 'absolute',
                transform: 'scale(2)',
                opacity: '0',
                cursor: 'pointer',
              }}
              name="profileImage"
              onChange={handleChange}
            />
            <BsFillCameraFill
              style={{
                cursor: 'pointer',
              }}
              className="w-[28px] h-[28px] text-white"
            />
          </div>
        </div>
        <div style={{ textAlign: 'center' }}>
          <span className="error"> {shopInfo.errors.profileImage}</span>
        </div>
        <div className="flex my-10 justify-between sm:flex-col ">
          <div className="basis-[45%] sm:mb-10">
            <CssTextField
              sx={{
                width: '100%',
              }}
              onChange={handleChange}
              id="outlined-basic"
              name="shopName"
              value={shopInfo.shopName}
              label="Store Name"
              placeholder="Enter your store name"
              error={shopInfo.errors.shopName ? true : false}
              helperText={
                shopInfo.errors.shopName ? shopInfo.errors.shopName : ''
              }
              required
            />
          </div>

          <div className="basis-[45%] relative">
            <FormControl
              fullWidth
              sx={{
                '& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root': {
                  lineHeight: '1rem',
                  fontSize: '0.90rem',
                  color: '#9FA6B3',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderRadius: '0.35rem',
                    borderColor: '#E5E7EB',
                  },
                },
              }}
            >
              <InputLabel>Store Category</InputLabel>
              <Select
                name="shopCategory"
                value={shopInfo.shopCategory}
                style={{
                  width: '100%',
                }}
                sx={{
                  '& .MuiSelect-select': {
                    padding: '0.75rem 1.25rem',
                  },
                  '& .css-u0uils-MuiFormLabel-root-MuiInputLabel-root': {
                    borderColor: '#E5E7EB',
                  },
                }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                placeholder="choose One"
                label="Store Category"
                onChange={handleChange}
              >
                <MenuItem value="Distributor">Distributor</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>

        <div className="flex justify-between sm:flex-col ">
          <div className="basis-[45%]">
            <Stack spacing={3}>
              <Autocomplete
                multiple
                id="tags-outlined"
                value={shopInfo.shopType}
                options={
                  shopInfo.shopType?.length > 0 ? shopInfo.shopType : shopTypes
                }
                onChange={(event, newValue) => {
                  if (newValue.length !== 0) {
                    setShopInfo((prevState: any) => {
                      let { errors } = prevState;
                      return {
                        ...prevState,
                        shopType: newValue,
                        errors: {
                          ...prevState.errors,
                          shopType: '',
                        },
                      };
                    });
                  } else {
                    setShopInfo((prevState: any) => {
                      let { errors } = prevState;
                      return {
                        ...prevState,
                        shopType: newValue,
                        errors: {
                          ...prevState.errors,
                          shopType: 'Add at least one shop type',
                        },
                      };
                    });
                  }
                }}
                getOptionLabel={(shopType: any) => shopType}
                filterSelectedOptions
                renderInput={(params) => (
                  <CssTextField
                    {...params}
                    sx={{
                      '& .css-1qqsdnr-MuiAutocomplete-root': {
                        padding: '4px',
                      },
                      '& .MuiOutlinedInput-root': {
                        padding: '4px',
                      },
                    }}
                    name="shopType"
                    label="Store Type"
                    placeholder="for eg: grocery , gifts and etc."
                    error={shopInfo.errors.shopType ? true : false}
                    helperText={
                      shopInfo.errors.shopType ? shopInfo.errors.shopType : ''
                    }
                    required
                  />
                )}
              />
            </Stack>
          </div>
          <div className="basis-[45%]"></div>
        </div>

        <div className="mb-4 mt-[1.5rem]">
          <div className="mb-[-0.7rem] ml-[0.5rem]">
            <label
              htmlFor="shopImage"
              className="text-sm bg-white px-[0.2rem] lg:text-xs font-semibold"
            >
              Shop's Image
            </label>
          </div>

          {/* card for showing uploaded images by the user */}
          <div className="basis-[90%] sm:p-[0.5rem] flex flex-wrap rounded border-solid border-[1px] p-4 border-gray-220">
            {shopInfo.shopImages?.length > 0 &&
              shopInfo.shopImages.map((img: string, index: number) => (
                <figure
                  key={index}
                  draggable
                  onDragStart={handleDragStart}
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                  data-index={index}
                  className="relative m-[0.4rem] sm:m-[0.2rem] inline-block rounded border-[1px] border-solid border-[black]"
                  style={{
                    width: '110px',
                    height: '110px',
                  }}
                >
                  <span
                    onClick={() => {
                      let newShopImages = shopInfo.shopImages.filter(
                        (img: String, i: number) => {
                          return i !== index;
                        }
                      );
                      setShopInfo({
                        ...shopInfo,
                        shopImages: newShopImages,
                      });
                    }}
                    style={{
                      position: 'absolute',
                      right: '4px',
                      borderRadius: '50%',
                      display: 'flex',
                      background: 'white',
                      top: '5px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '20px',
                      height: '20px',
                    }}
                  >
                    <RxCross2 />
                  </span>
                  <img
                    src={img}
                    className="rounded-md w-full h-[110px] object-cover"
                    alt={'shop-image' + index}
                  />
                </figure>
              ))}
            <button
              className="w-[110px] m-[0.4rem] sm:m-[0.2rem] p-[24px] h-[110px] border-box bg-[#EAECEC] h-auto flex-col flex items-center justify-center border-solid border-[1px] border-gray-220 rounded-md"
              style={{
                width: '110px',
                height: '110px',
              }}
            >
              <i
                style={{
                  backgroundImage: 'url("/add_photos_icon.png")',
                  backgroundPosition: '0px -58px',
                  backgroundSize: 'auto',
                  color: 'white',
                  width: '20px',
                  height: '20px',
                  backgroundRepeat: 'no-repeat',
                  display: 'inline-block',
                }}
              ></i>
              <span className="text-xs mt-2">Add Photos</span>
              <input
                type="file"
                name="shopImages"
                style={{
                  position: 'absolute',
                  opacity: '0',
                  cursor: 'pointer',
                  width: '110px',
                  top: '100',
                  height: '110px',
                }}
                onChange={handleChange}
              />
            </button>
          </div>
          <div style={{ marginTop: '0.5rem' }}>
            <span className="error"> {shopInfo.errors.shopImages}</span>
          </div>
        </div>

        <hr />
        <div className="text-right my-10 mx-2 store-profile-update-cnt">
          <button
            type="button"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onClick={handleUpdateShopProfile}
            disabled={
              !shopInfo.shopName ||
              !shopInfo.shopCategory ||
              shopInfo.shopType.length == 0
                ? true
                : false
            }
            className={
              !shopInfo.shopName ||
              !shopInfo.shopCategory ||
              shopInfo.shopType.length == 0
                ? 'button-primary-disabled md:w-[5rem] md:text-[12px]'
                : 'button-primary md:w-[5rem] md:text-[12px]'
            }
          >
            Update
          </button>
        </div>
      </form>
    </div>
  );
}
