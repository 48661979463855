import { useNavigate } from 'react-router-dom';
import { PatchRequest } from '../../components/Request';
import './confirmation.scss';

export default function ConfirmationBox({
  setOrderIdConvertIntoPending,
  orderIdConvertIntoPending,
  setShowConfirmation,
  setSelectedOption,
  setShowPlaceOrder,
}: any) {
  let navigate = useNavigate();

  const convertIntoPendingOrder = async () => {
    await PatchRequest(`order/${orderIdConvertIntoPending}`, {
      status: 'Pending',
    })
      .then((res: any) => {
        if (res.data.statusCode == 200) {
          // for order page it is
          setSelectedOption('Pending');
          navigate('/order/Pending');
          setShowConfirmation(false);
        }
      })
      .catch((err) => console.log(err, 'in updating the order into pending'));
  };

  return (
    <section className="confirmation-cnt">
      <div className="confirmation-box">
        <p>Are you ready to expand or update your existing orders?</p>
        <div
          style={{
            textAlign: 'center',
            marginTop: '1rem',
          }}
        >
          <button
            onClick={() => {
              setShowPlaceOrder({});
              setOrderIdConvertIntoPending('');
              setShowConfirmation(false);
            }}
            type="button"
            className="cancel-btn"
          >
            No
          </button>
          <button
            type="button"
            id="button-primary"
            style={{
              padding: '4px',
              width: '6rem',
              height: 'auto',
              marginLeft: '2rem',
            }}
            className="button-primary"
            onClick={convertIntoPendingOrder}
          >
            Yes
          </button>
        </div>
      </div>
    </section>
  );
}
