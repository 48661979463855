import { RxCross1 } from 'react-icons/rx';
import React, { useRef, useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import '../../order/order.scss';
import { useMediaQuery } from 'react-responsive';
import { AiTwotonePhone } from 'react-icons/ai';
import { CiCalendarDate } from 'react-icons/ci';
import { MdEmail } from 'react-icons/md';
import { PatchRequest, PostRequest } from '../../../components/Request';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ConfirmationBox from './Confirmation';

export default function OrderFromSpecificStore(props: any) {
  let { showPlaceOrder, setShowPlaceOrder, getProducts } = props;
  const billRef = useRef<any>(null);
  const isMobileScreen = useMediaQuery({ maxWidth: 480 });
  const [currentUrl, setCurrentUrl] = useState(window.location.href);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [orderIdConvertIntoPending, setOrderIdConvertIntoPending] = useState();
  let navigate = useNavigate();

  console.log(showPlaceOrder, 'this is show place order');

  const handleClickOutside = (event: any) => {
    if (billRef.current && !billRef.current.contains(event.target)) {
      setShowPlaceOrder({});
    }
  };

  function formatDateToDDMMMYY(inputDate: any) {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    const date = new Date(inputDate);

    const day = String(date.getUTCDate()).padStart(2, '0');
    const monthAbbreviation = months[date.getUTCMonth()];
    const year = String(date.getUTCFullYear()).slice(-4);

    const formattedDate = `${day}-${monthAbbreviation}-${year}`;

    return formattedDate;
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const convertOrderedIntoPendingStatusPopUp = (orderId: any) => {
    setOrderIdConvertIntoPending(orderId);
    setShowConfirmation(true);
  };

  const handleClickOnConfirmOrder = (order: any, url: string) => {
    PatchRequest(`order/${order._id}`, { status: 'Ordered' })
      .then((res: any) => {
        if (res.data.statusCode == 200) {
          if (url.includes('product')) {
            navigate('/order/Ordered');
          } else {
            setShowPlaceOrder({});
            navigate('/order/Ordered');
          }
        }
      })
      .catch((err) => console.log(err, 'in getting '));
  };

  return (
    <div className="bill-pop-up">
      {showConfirmation ? (
        <ConfirmationBox
          getProducts={getProducts}
          orderIdConvertIntoPending={orderIdConvertIntoPending}
          setOrderIdConvertIntoPending={setOrderIdConvertIntoPending}
          setShowConfirmation={setShowConfirmation}
          setShowPlaceOrder={setShowPlaceOrder}
        />
      ) : (
        <div ref={billRef} className="bill-cnt">
          {isMobileScreen ? (
            <OrderItemUIForMobile
              currentUrl={currentUrl}
              setCurrentUrl={setCurrentUrl}
              handleClickOnConfirmOrder={handleClickOnConfirmOrder}
              formatDateToDDMMMYY={formatDateToDDMMMYY}
              showPlaceOrder={showPlaceOrder}
              setShowPlaceOrder={setShowPlaceOrder}
            />
          ) : (
            <OrderItemUIForDesktop
              convertOrderedIntoPendingStatusPopUp={
                convertOrderedIntoPendingStatusPopUp
              }
              currentUrl={currentUrl}
              setCurrentUrl={setCurrentUrl}
              handleClickOnConfirmOrder={handleClickOnConfirmOrder}
              formatDateToDDMMMYY={formatDateToDDMMMYY}
              showPlaceOrder={showPlaceOrder}
              setShowPlaceOrder={setShowPlaceOrder}
            />
          )}
        </div>
      )}
    </div>
  );
}
function OrderItemUIForMobile({
  showPlaceOrder,
  formatDateToDDMMMYY,
  setShowPlaceOrder,
  handleClickOnConfirmOrder,
}: any) {
  return (
    <div className="order-items-mb">
      <div className="cross-icon-cnt">
        <button className="cross-icon">
          <RxCross1 onClick={() => setShowPlaceOrder({})} />
        </button>
      </div>
      <div className="seller-details">
        <div
          style={{
            display: 'flex',
            alignItems: 'start',
            justifyContent: 'space-between',
            padding: '6px',
          }}
        >
          <div>
            <LazyLoadImage
              alt={showPlaceOrder?.sellerStore?.name}
              className="product_img"
              style={{
                width: '70px',
                height: '70px',
                borderRadius: '0.5rem',
              }}
              src={
                showPlaceOrder?.sellerStore?.profileImage == undefined
                  ? '/images/no-image-available-icon.jpeg'
                  : showPlaceOrder?.sellerStore?.profileImage
              }
            />
            <p
              style={{
                margin: '9px 0',
                fontSize: '14px',
                fontWeight: '460',
                color: '#364daa',
              }}
            >
              {showPlaceOrder?.sellerStore?.name}
            </p>
          </div>

          <div>
            <p>
              <span className="seller-info-icon">
                <CiCalendarDate />
              </span>
              {formatDateToDDMMMYY(showPlaceOrder.createdAt)}
            </p>
            <p>
              <span className="seller-info-icon">
                <MdEmail />
              </span>
              {showPlaceOrder?.sellerStore?.email}
            </p>

            <p>
              <span className="seller-info-icon">
                <AiTwotonePhone />
              </span>

              {`( ${
                showPlaceOrder?.sellerStore?.phoneNumberList.length > 0 &&
                showPlaceOrder?.sellerStore?.phoneNumberList[0].phoneNumber.substring(
                  0,
                  3
                )
              } ) ${
                showPlaceOrder?.sellerStore?.phoneNumberList.length > 0 &&
                showPlaceOrder?.sellerStore?.phoneNumberList[0].phoneNumber.substring(
                  3
                )
              }`}
            </p>
          </div>
        </div>
      </div>
      {showPlaceOrder.itemList.map((data: any, indexOfItem: number) => {
        return (
          <div
            className="order-item-detail"
            style={{
              background: 'rgb(232 233 240 / 51%)',
            }}
          >
            <div className="img-n-name">
              <LazyLoadImage
                alt={data.product.name}
                className="order-item-img"
                src={
                  data.product.imageList == undefined
                    ? '/images/no-image-available-icon.jpeg'
                    : data.product.imageList[0]
                }
              />
              <h1
                className="order-item-name"
                style={{
                  fontSize: '13px',
                  fontWeight: '450',
                }}
              >
                {data.product.name}
              </h1>
            </div>
            <div className="flex padding-6 justify-between">
              <p>MRP</p> <p className="heading-mb">{'₹ ' + data.product.mrp}</p>
            </div>
            <div className="flex justify-between padding-6">
              <p>Price (Exclu. tax)</p>
              <p className="heading-mb">
                {'₹ ' + data.pricePerUnit.netAmount.toFixed(2)}
              </p>
            </div>
            <div className="flex justify-between padding-6">
              <p>Price (Inclu. tax)</p>
              <p className="heading-mb">
                {'₹ ' +
                  data.product.lastPriceOnWhichTotalPriceGettingCalculated}
              </p>
            </div>
            <div className="flex justify-between padding-6">
              <p>Quantity</p>
              <p className="heading-mb">
                {data.quantity.pieceWise}
                {data.product.selectedOption !== 'piece'
                  ? ` (  ${data.product.quantity}
                      ${data.product.selectedOption} )`
                  : ''}
              </p>
            </div>

            {data.totalTaxPercentagePrice.map((tax: any) => (
              <div className="flex justify-between padding-6">
                <p>{tax.type}</p>
                <p className="heading-mb">
                  {'₹ ' + `${tax.price.toFixed(2)} (` + tax.percentage + '%)'}
                </p>
              </div>
            ))}

            <div className="flex justify-between padding-6">
              <p>Total Price</p>
              <p className="heading-mb"> {'₹ ' + data.totalPrice}</p>
            </div>
          </div>
        );
      })}

      <div>
        {showPlaceOrder?.totalTaxAmountList?.map((tax: any) => (
          <div
            className="flex  justify-between"
            style={{
              padding: '6px',
            }}
          >
            <p className="order_tax ">{'Total ' + tax.type}</p>
            <p className="heading-mb">{'₹ ' + `${tax.amount.toFixed(2)}`}</p>
          </div>
        ))}
        <div
          className="flex  justify-between"
          style={{
            padding: '6px',
          }}
        >
          <p>Total Amount</p>
          <p className="heading-mb">
            {'₹ ' + showPlaceOrder?.totalAmount?.toFixed(2)}
          </p>
        </div>
      </div>
      <div className="flex justify-center">
        <button
          type="submit"
          onClick={() => handleClickOnConfirmOrder(showPlaceOrder)}
          className="confirm-order-btn"
        >
          Confirm Order
        </button>
      </div>
    </div>
  );
}

function OrderItemUIForDesktop({
  showPlaceOrder,
  formatDateToDDMMMYY,
  setShowPlaceOrder,
  convertOrderedIntoPendingStatusPopUp,
}: any) {
  return (
    <ul className="lists_of_distributors">
      <div className="cross-icon-cnt">
        <button className="cross-icon">
          <RxCross1 onClick={() => setShowPlaceOrder({})} />
        </button>
      </div>
      <div className="flex justify-between">
        <div className="cnt-of-seller-img-n-seller-info">
          <LazyLoadImage
            alt={showPlaceOrder?.sellerStore?.name}
            className="seller-img"
            src={
              showPlaceOrder?.sellerStore?.profileImage == undefined
                ? '/images/no-image-available-icon.jpeg'
                : showPlaceOrder?.sellerStore?.profileImage
            }
          />
          <div className="seller-name-n-email">
            <h2> Seller Name :- {showPlaceOrder?.sellerStore?.name}</h2>
            <p>
              <span className="seller-info-icon">
                <MdEmail />
              </span>
              {showPlaceOrder?.sellerStore?.email}
            </p>
          </div>
        </div>
        <div>
          <p>
            <span className="seller-info-icon">
              <CiCalendarDate />
            </span>
            {formatDateToDDMMMYY(showPlaceOrder.createdAt)}
          </p>
          <p>
            <span className="seller-info-icon">
              <AiTwotonePhone />
            </span>

            {`( ${
              showPlaceOrder?.sellerStore?.phoneNumberList?.length > 0 &&
              showPlaceOrder?.sellerStore?.phoneNumberList[0].phoneNumber.substring(
                0,
                3
              )
            } ) ${
              showPlaceOrder?.sellerStore?.phoneNumberList?.length > 0 &&
              showPlaceOrder?.sellerStore?.phoneNumberList[0].phoneNumber.substring(
                3
              )
            }`}
          </p>
        </div>
      </div>

      <table className="bill-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>MRP</th>
            <th>Price (Excl. tax)</th>
            <th>Price (Incl. tax)</th>
            <th>Quantity </th>
            {showPlaceOrder?.totalTaxAmountList?.length > 0 &&
              showPlaceOrder?.totalTaxAmountList.map((tax: any) => (
                <th className="order_tax">{tax.type}</th>
              ))}
            <th>Total Price</th>
          </tr>
        </thead>
        <tbody>
          {showPlaceOrder?.itemList?.length > 0 &&
            showPlaceOrder?.itemList.map((data: any, indexOfItem: number) => {
              return (
                <tr key={indexOfItem}>
                  <td className="flex">
                    <div>
                      <LazyLoadImage
                        alt={data.product.name}
                        className="product_img"
                        src={
                          data.product.imageList == undefined
                            ? '/images/no-image-available-icon.jpeg'
                            : data.product.imageList[0]
                        }
                      />
                    </div>
                    <span className="order-item-name">{data.product.name}</span>
                  </td>
                  <td> {'₹ ' + data.product.mrp}</td>
                  <td>{'₹ ' + data.pricePerUnit.netAmount.toFixed(2)}</td>
                  <td>
                    {'₹ ' +
                      data.product.lastPriceOnWhichTotalPriceGettingCalculated}
                  </td>
                  <td>
                    {data.quantity.pieceWise}
                    {data.product.selectedOption !== 'piece'
                      ? ` (  ${data.product.quantity}
        ${data.product.selectedOption} )`
                      : ''}
                  </td>

                  {data.totalTaxPercentagePrice.map((tax: any) => (
                    <td>
                      {'₹ ' +
                        `${tax.price.toFixed(2)} (` +
                        tax.percentage +
                        '%)'}
                    </td>
                  ))}
                  <td>{'₹ ' + Number(data.totalPrice).toFixed(2)}</td>
                </tr>
              );
            })}

          <tr className="total-price-details">
            <td
              colSpan={5}
              style={{
                textAlign: 'left',
                color: '#6D7785',
                fontSize: '19px',
                fontWeight: '550',
              }}
            >
              Grand Total
            </td>

            {showPlaceOrder?.totalTaxAmountList?.length > 0 &&
              showPlaceOrder?.totalTaxAmountList.map((tax: any) => (
                <td>{'₹ ' + `${tax.amount.toFixed(2)}`}</td>
              ))}
            <td>{'₹ ' + Number(showPlaceOrder.totalAmount).toFixed(2)}</td>
          </tr>
        </tbody>
      </table>
      <div className="flex justify-center">
        {
          <Link
            to="#"
            onClick={() => {
              convertOrderedIntoPendingStatusPopUp(showPlaceOrder._id);
            }}
          >
            Edit Above Orders
          </Link>
        }
      </div>
    </ul>
  );
}
