import { useEffect, useState } from 'react';
import './styles/subcart.scss';
import { BsFillHandbagFill } from 'react-icons/bs';
import { RxCross1 } from 'react-icons/rx';
import {
  RiDeleteBack2Fill,
  RiChatDeleteFill,
  RiDeleteBin2Fill,
} from 'react-icons/ri';
import './styles/products.scss';
import { HiOutlineArrowSmUp } from 'react-icons/hi';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { GetRequest, PatchRequest } from '../../../components/Request';
import { isBundle } from 'typescript';
import { useNavigate } from 'react-router-dom';

export default function SubCart(_props: any) {
  let {
    bag,
    setBag,
    removeProductFromBag,
    handleIncrementInBag,
    handleDecrementInBag,
    showMinimumQuantityAccToSelectedOption,
    handleChangeOnQuantity,
    products,
    setProducts,
    updateProductForOption,
    updateProductForQuantity,
    setShowPlaceOrder,
  } = _props;
  const [showSubCart, setShowSubCart] = useState(false);
  let navigate = useNavigate();
  const applyClasses = () => {
    if (showSubCart) {
      return 'subcart_opened_cnt';
    } else if (!showSubCart) {
      return 'subcart_closed_cnt';
    }
  };

  const changeValue = (p: any) => {
    if (p.quantity.boxWise) {
      return p.quantity.boxWise;
    } else if (p.quantity.bundleWise) {
      return p.quantity.bundleWise;
    } else {
      return p.quantity.pieceWise;
    }
  };

  const handleClickOnBag = (event: any) => {
    setShowSubCart(true);
  };

  const showActiveOption = (p: any) => {
    if (p.quantity.boxWise) {
      return 'box';
    } else if (p.quantity.bundleWise) {
      return 'bundle';
    } else {
      return 'piece';
    }
  };

  const giveQuantityInPiece = (p: any, changeIntoThisSelectedOption: any) => {
    let info = p.product?.info;

    if (changeIntoThisSelectedOption == 'piece') {
      return p.sellerProductDetail.minimumQuantity.piece.quantity;
    } else if (changeIntoThisSelectedOption == 'box') {
      return (
        p.sellerProductDetail.minimumQuantity.box.quantity *
        info?.pieceInEachBox
      );
    } else {
      return (
        p.sellerProductDetail.minimumQuantity.bundle.quantity *
        info?.pieceInEachBundle
      );
    }
  };

  const giveQuantityInPieceForBag = (
    p: any,
    quantity: any,
    changeIntoThisSelectedOption: any
  ) => {
    let info = p.product?.info;
    if (changeIntoThisSelectedOption == 'piece') {
      return quantity;
    } else if (changeIntoThisSelectedOption == 'box') {
      return quantity * info?.pieceInEachBox;
    } else {
      return quantity * info?.pieceInEachBundle;
    }
  };

  const lastPrice = (product: any, selectedOption: any) => {
    if (selectedOption === 'piece') {
      if (product.sellerProductDetail.pricePerUnit.piece.unique) {
        return product.sellerProductDetail.pricePerUnit.piece.price;
      } else {
        let lastPrice;
        const quantity = product.quantity.pieceWise;

        const quantityWisePriceList =
          product.sellerProductDetail.pricePerUnit.piece.quantityWisePriceList;

        for (const priceObj of quantityWisePriceList) {
          const { price, moreThanOrEqual, lessThanOrEqual } = priceObj;

          if (quantity >= moreThanOrEqual && quantity <= lessThanOrEqual) {
            lastPrice = price;
          }
        }

        return lastPrice;
      }
    } else if (selectedOption === 'bundle') {
      if (product.sellerProductDetail.pricePerUnit.bundle.unique) {
        return product.sellerProductDetail.pricePerUnit.bundle.price;
      } else {
        let lastPrice;
        const quantity = product.quantity.bundleWise;

        const quantityWisePriceList =
          product.sellerProductDetail.pricePerUnit.bundle.quantityWisePriceList;

        for (const priceObj of quantityWisePriceList) {
          const { price, moreThanOrEqual, lessThanOrEqual } = priceObj;

          if (quantity >= moreThanOrEqual && quantity <= lessThanOrEqual) {
            lastPrice = price;
          }
        }

        return lastPrice;
      }
    } else if (selectedOption === 'box') {
      if (product.sellerProductDetail.pricePerUnit.box.unique) {
        return product.sellerProductDetail.pricePerUnit.box.price;
      } else {
        let lastPrice;
        const quantity = product.quantity.boxWise;

        const quantityWisePriceList =
          product.sellerProductDetail.pricePerUnit.box.quantityWisePriceList;

        for (const priceObj of quantityWisePriceList) {
          const { price, moreThanOrEqual, lessThanOrEqual } = priceObj;

          if (quantity >= moreThanOrEqual && quantity <= lessThanOrEqual) {
            lastPrice = price;
          }
        }

        return lastPrice;
      }
    }
  };

  const handleClickOnPlaceOrder = async (bag: any) => {
    await GetRequest(
      `order/${bag._id}?seller-product-detail=true&seller-store-detail=true`
    )
      .then((res) => {
        if (res.data.statusCode == 200) {
          let obj = res.data.data;

          obj.itemList = obj.itemList.map((p: any) => {
            let selectedOption;
            let quantity;
            if (p.quantity.boxWise) {
              selectedOption = 'box';
              quantity = p.quantity.boxWise;
            } else if (p.quantity.bundleWise) {
              selectedOption = 'bundle';
              quantity = p.quantity.bundleWise;
            } else {
              selectedOption = 'piece';
              quantity = p.quantity.pieceWise;
            }
            return {
              ...p,
              totalPrice: p.totalPrice.toFixed(2),
              product: {
                ...p.product,
                selectedOption,
                quantity,
                lastPriceOnWhichTotalPriceGettingCalculated: lastPrice(
                  p,
                  selectedOption
                ),
              },
            };
          });
          setShowPlaceOrder(obj);
          setShowSubCart(false);
        }
      })
      .catch((err) => {
        console.log(err, 'errrr  from fetch pending orders');
      });
  };
  // console.log(, 'this is the totla item list');
  return (
    <div>
      <button
        onClick={handleClickOnBag}
        className="w-[50px] fixed top-[50%] right-[0%] h-[50px]  border-solid border-[1px] rounded-md bg-[#14539A] text-gray-200 px-4 border-gray-400"
        style={{
          boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          transition: '1s',
          borderRadius: '50% 50% 0% 50%',
        }}
      >
        <p
          style={{
            border: '1.5px solid #14539A',
            color: 'white',
            position: 'absolute',
            height: '15px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '11px',
            textAlign: 'center',
            borderRadius: '50%',
            bottom: '4px',
            right: '5px',
            width: '15px',
          }}
        >
          {bag.totalItemList}
        </p>
        <BsFillHandbagFill className="md:text-[16px] w-[100%]" />
      </button>
      <div
        className={applyClasses()}
        style={{
          overflow: 'scroll',
          top: '60px',
        }}
      >
        <div className="header_cnt_subcart">
          <h2
            style={{
              color: '#14539a',
            }}
          >
            Your Bag ({bag.totalItemList})
          </h2>
          <button
            type="button"
            className="close_btn"
            onClick={(event: any) => {
              setShowSubCart(false);
            }}
          >
            <RxCross1 />
          </button>
        </div>
        {bag.totalItemList == 0 ? (
          <AddYourProducts />
        ) : (
          <ul
            style={{
              paddingBottom: '6rem',
            }}
          >
            {bag.itemList.map((p: any, index: number) => {
              return (
                <li key={p.product.name} className="subcart_product_cnt">
                  <div className="product_name_cnt">
                    <h2 className="font-[400] text-sm product_name">
                      {p.product.name}
                    </h2>

                    <button
                      className="block"
                      style={{
                        color: '#d40c0c',
                      }}
                      onClick={() => removeProductFromBag(index)}
                    >
                      <RiChatDeleteFill style={{ fontSize: '18px' }} />
                    </button>
                  </div>

                  <div className="small_cnt_of_subcart_product">
                    <figure className="product_img">
                      <LazyLoadImage
                        alt={p.product.name}
                        className="product_img"
                        src={
                          p.product.imageList == undefined ||
                          p.product.imageList.length == 0
                            ? '/images/no-image-available-icon.jpeg'
                            : p.product.imageList[0]
                        }
                      />
                    </figure>
                    <div className="subcart_product_info_cnt">
                      <div>
                        <div className="cnt_of_price_n_mrp">
                          <p className="price">
                            ₹
                            {p.product
                              .lastPriceOnWhichTotalPriceGettingCalculated +
                              ' '}
                          </p>
                          <p className="mrp">{'  MRP ₹' + p.product.mrp}</p>
                        </div>
                        <div className="quantity">
                          <div>
                            <button
                              style={{
                                cursor:
                                  showMinimumQuantityAccToSelectedOption(p) ==
                                  p.quantity
                                    ? 'not-allowed'
                                    : 'pointer',
                              }}
                              onMouseOver={(event: any) => {
                                if (
                                  showMinimumQuantityAccToSelectedOption(p) ==
                                  p.quantity
                                ) {
                                  return event.target.classList.add('minus-1');
                                }
                              }}
                              onMouseLeave={(event: any) => {
                                return event.target.classList.remove('minus-1');
                              }}
                              onClick={async (event: any) => {
                                let productId = p.product._id;
                                let indexOfProduct = products.findIndex(
                                  (product: any) => product._id == productId
                                );
                                let product = products[indexOfProduct];
                                if (
                                  showMinimumQuantityAccToSelectedOption(
                                    product
                                  ) == product.quantity
                                ) {
                                  return event.target.classList.add('minus-1');
                                } else {
                                  let updatedProducts =
                                    await handleDecrementInBag(
                                      product,
                                      indexOfProduct
                                    );
                                  let quantityInPiece =
                                    await giveQuantityInPieceForBag(
                                      p,
                                      updatedProducts[indexOfProduct].quantity,
                                      updatedProducts[indexOfProduct]
                                        .selectedOption
                                    );
                                  let orderId =
                                    p.product.pendingOrderDetail != null
                                      ? p.product.pendingOrderDetail.order._id
                                      : p.orderId;
                                  await updateProductForQuantity(
                                    orderId,
                                    productId,
                                    updatedProducts[indexOfProduct],
                                    quantityInPiece,
                                    indexOfProduct
                                  );
                                }
                              }}
                              className="minus"
                            >
                              -
                            </button>
                            <div className="minimum_quantity_info">
                              <span>
                                minimum Quantity to buy in
                                {' ' + p.selectedOption} is
                                {' ' +
                                  showMinimumQuantityAccToSelectedOption(p)}
                              </span>
                            </div>

                            <input
                              onFocus={(event: any) => null}
                              onBlur={(event: any) => {
                                return handleChangeOnQuantity(event, p);
                              }}
                              onChange={(event: any) => {
                                let newProducts = [...products];
                                let newBag = [...bag];
                                newProducts = newProducts.map(
                                  (newProduct: any) => {
                                    if (newProduct.id == p.id) {
                                      return {
                                        ...newProduct,
                                        quantity: event.target.value,
                                      };
                                    } else {
                                      return newProduct;
                                    }
                                  }
                                );
                                bag[index].quantity = event.target.value;
                                setBag(newBag);
                                setProducts(newProducts);
                              }}
                              type="number"
                              name="quantity"
                              value={changeValue(p)}
                            />
                            <button
                              onClick={async () => {
                                let productId = p.product._id;
                                let indexOfProduct = products.findIndex(
                                  (product: any) => product._id === productId
                                );
                                let product = products[indexOfProduct];
                                let updatedProducts =
                                  await handleIncrementInBag(
                                    product,
                                    indexOfProduct
                                  );
                                let quantityInPiece =
                                  await giveQuantityInPieceForBag(
                                    p,
                                    updatedProducts[indexOfProduct].quantity,
                                    updatedProducts[indexOfProduct]
                                      .selectedOption
                                  );
                                let orderId =
                                  p.product.pendingOrderDetail != null
                                    ? p.product.pendingOrderDetail.order._id
                                    : p.orderId;

                                await updateProductForQuantity(
                                  orderId,
                                  productId,
                                  updatedProducts[indexOfProduct],
                                  quantityInPiece,
                                  indexOfProduct
                                );
                              }}
                              className="plus"
                            >
                              +
                            </button>
                          </div>
                        </div>
                        <div className="options_like_box_bundle_piece">
                          <div>
                            {p.sellerProductDetail.minimumQuantity.piece &&
                            p.sellerProductDetail.pricePerUnit.piece ? (
                              <button
                                onClick={async (_event: any) => {
                                  let productId = p.product._id;
                                  let orderId =
                                    p.product.pendingOrderDetail != null
                                      ? p.product.pendingOrderDetail.order._id
                                      : p.orderId;
                                  let indexOfProduct = products.findIndex(
                                    (product: any) => product._id == productId
                                  );
                                  let quantityInPiece =
                                    await giveQuantityInPiece(p, 'piece');

                                  await updateProductForOption(
                                    orderId,
                                    productId,
                                    products[indexOfProduct],
                                    quantityInPiece,
                                    indexOfProduct
                                  );
                                }}
                                style={{
                                  backgroundColor:
                                    showActiveOption(p) == 'piece'
                                      ? '#eef2f6'
                                      : '',
                                }}
                              >
                                Piece
                              </button>
                            ) : (
                              ''
                            )}

                            {p.sellerProductDetail.minimumQuantity.bundle &&
                            p.sellerProductDetail.pricePerUnit.bundle &&
                            p.product?.info?.pieceInEachBundle ? (
                              <button
                                style={{
                                  backgroundColor:
                                    showActiveOption(p) == 'bundle'
                                      ? '#eef2f6'
                                      : '',
                                }}
                                onClick={async (_event: any) => {
                                  let productId = p.product._id;
                                  let orderId =
                                    p.product.pendingOrderDetail != null
                                      ? p.product.pendingOrderDetail.order._id
                                      : p.orderId;
                                  let indexOfProduct = products.findIndex(
                                    (product: any) => product._id == productId
                                  );
                                  let quantityInPiece =
                                    await giveQuantityInPiece(p, 'bundle');

                                  await updateProductForOption(
                                    orderId,
                                    productId,
                                    products[indexOfProduct],
                                    quantityInPiece,
                                    indexOfProduct
                                  );
                                }}
                              >
                                Bundle *
                              </button>
                            ) : (
                              ''
                            )}

                            {p.sellerProductDetail.minimumQuantity.box &&
                            p.sellerProductDetail.pricePerUnit.box &&
                            p.product?.info?.pieceInEachBox ? (
                              <button
                                onClick={async (_event: any) => {
                                  let productId = p.product._id;
                                  let orderId =
                                    p.product.pendingOrderDetail != null
                                      ? p.product.pendingOrderDetail.order._id
                                      : p.orderId;
                                  let indexOfProduct = products.findIndex(
                                    (product: any) => product._id == productId
                                  );
                                  let quantityInPiece =
                                    await giveQuantityInPiece(p, 'box');

                                  await updateProductForOption(
                                    orderId,
                                    productId,
                                    products[indexOfProduct],
                                    quantityInPiece,
                                    indexOfProduct
                                  );
                                }}
                                style={{
                                  backgroundColor:
                                    showActiveOption(p) == 'box'
                                      ? '#eef2f6'
                                      : '',
                                  position: 'relative',
                                }}
                              >
                                Box
                                <span
                                  style={{
                                    fontSize: '9px',
                                    position: 'absolute',
                                    top: '2px',
                                    right: '18%',
                                  }}
                                >
                                  {'  #'}
                                </span>
                              </button>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="profit_cnt">
                    <span className="total_price">
                      <span
                        style={{
                          fontStyle: 'italic',
                        }}
                      >
                        Total
                      </span>{' '}
                      {'₹' + p.totalPrice}
                    </span>
                  </div>

                  <div className="star_info">
                    {p.product?.info?.pieceInEachBundle ? (
                      <p>
                        * Bundle =
                        {' ' + p.product?.info?.pieceInEachBundle + ' '}
                        pieces.
                      </p>
                    ) : (
                      ''
                    )}
                    {p.product?.info?.pieceInEachBundle &&
                    p.product?.info?.pieceInEachBox ? (
                      <span
                        style={{
                          display: 'block',
                          height: '15px',
                          borderLeft: '0.2px solid #666666',
                          margin: '5px 0px',
                        }}
                      ></span>
                    ) : (
                      ''
                    )}

                    {p.product?.info?.pieceInEachBox ? (
                      <p
                        style={{
                          position: 'relative',
                          marginLeft:
                            p.product?.info?.pieceInEachBox &&
                            p.product?.info?.pieceInEachBundle
                              ? '0px'
                              : '0.6rem',
                        }}
                      >
                        <span
                          style={{
                            fontSize: '9px',
                            position: 'absolute',
                            top: '-1px',
                            left: '24px',
                          }}
                        >
                          {' # '}
                        </span>
                        Box = {' ' + p.product?.info?.pieceInEachBox + ' '}
                        pieces.
                      </p>
                    ) : (
                      ''
                    )}
                  </div>
                  <hr
                    style={{
                      margin: '1rem 0',
                    }}
                  />
                </li>
              );
            })}
          </ul>
        )}

        <div className="checkout_cnt">
          {bag.totalItemList != 0 ? (
            <p
              style={{
                color: '#14539A',
              }}
            >
              Total: ₹ {bag.totalAmount}
            </p>
          ) : (
            ''
          )}

          <button
            onClick={() => handleClickOnPlaceOrder(bag)}
            style={{
              background: '#14539A',
              width: '326px',
              padding: '0.5rem 0',
              color: 'white',
              margin: '1rem 0',
              borderRadius: '21px 7px 21px 7px',
            }}
          >
            Place Order
          </button>
        </div>
      </div>
    </div>
  );
}

function AddYourProducts() {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '75vh',
      }}
    >
      <p
        style={{
          color: 'black',
          fontWeight: '400',
          fontSize: '15px',
          verticalAlign: 'center',
        }}
      >
        Add Your Products in the bag <br />
        <span style={{ textAlign: 'center', display: 'block' }}>: )</span>
      </p>
    </div>
  );
}
