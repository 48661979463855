import {
  PayloadAction,
  SliceCaseReducers,
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';
import {
  PatchRequest,
  GetRequest,
  PostRequest,
} from '../../components/Request';
import {
  APIResponse,
  Address,
  Coordinates,
  Data,
  Location,
  PhoneNumberList,
  StoreInfo,
  StoreList,
} from '../../interface/axios.types';
import { IStore } from '../buyerStore/Slice';
import { AsyncThunkConfig } from '../../interface';
import { RootState } from '../../store';

export interface IUserInfo {
  _id: string;
  username: string;
  createdAt?: Date;
  phoneNumber: PhoneNumberList;
  roleList: string[];
  updatedAt?: Date;
  email?: string;
  name: string;
  profileImage: string;
  status: string;
  dateOfBirth: Date | string;
  gender: string;
  phoneNumberList: PhoneNumberList[];
  profileType: 'User' | 'Store';
}

export interface IUserStore {
  _id: string;
  address: Address;
  buyer: boolean;
  categoryList: string[];
  coordinates: Coordinates;
  createdAt: Date;
  createdBy: string;
  email: string;
  gst: Document;
  imageList: any[];
  name: string;
  pan: Document;
  phoneNumberList: PhoneNumberList[];
  profileDetail: null;
  profileType: 'Store' | 'User';
  profileImage: string;
  seller: boolean;
  slug: string;
  status: string;
  tradeLicense: Document;
  typeList: string[];
  updatedAt: Date;
  updatedBy: string;
  verified: boolean;
  location: any;
}

export type Document = {
  document: string;
  number: string;
  verified: boolean;
};

export interface CustomError {
  message: string;
  type: 'error' | 'success' | 'info';
  errorId: string;
}
export interface IAuthState {
  userInfo: IUserInfo | any;
  userStoreListFetched: boolean;
  UserStoreList: IUserStore[];
  currentUser: IUserStore | IUserInfo;
  loading: 'Pending' | 'Fulfilled';
  storeSelfProfile: IUserStore | null;
  isLoggedIn: boolean;
  error: CustomError | null;
}

const initialState: IAuthState = {
  userInfo: {
    _id: '',
    username: '',
    phoneNumber: '',
    email: '',
    name: '',
    profileImage: '',
    roleList: [],
    dateOfBirth: '',
    gender: '',
    status: '',
    phoneNumberList: [],
  },
  userStoreListFetched: false,
  UserStoreList: [],
  currentUser: {} as IUserStore,
  loading: 'Fulfilled',
  isLoggedIn: false,
  storeSelfProfile: null,
  error: null,
};

export const fetchStoreSelfAsyncThunk = createAsyncThunk<IUserStore, undefined>(
  'store/selfAPI',
  async (value: undefined, { rejectWithValue }) => {
    try {
      const response = await GetRequest('store/self?profile-detail=true');
      return response.data.data as IUserStore;
    } catch (error: any) {
      return rejectWithValue(error.data);
    }
  }
);
export const fetchUserStoreListAsyncThunk = createAsyncThunk<
  IUserStore[],
  undefined,
  AsyncThunkConfig
>('user/fetchStoreList', async (value: undefined, { rejectWithValue }) => {
  try {
    const response = await GetRequest('store/my-store?buyer=true');
    const data = response.data.data.map((item: any) => ({
      _id: item._id,
      name: item?.name,
      profileImage: item?.profileImage ?? '',
      address: item?.address ?? null,
      profileType: 'Store',
      seller: item?.seller ?? false,
      buyer: item?.buyer ?? true,
    }));

    return data as IUserStore[];
  } catch (error: any) {
    console.log(error);
    return rejectWithValue(error.data);
  }
});

export const updateProfileAsyncThunk = createAsyncThunk<
  IUserInfo,
  Record<string, any>,
  AsyncThunkConfig
>('user/update', async (value: Record<string, any>, { rejectWithValue }) => {
  try {
    type MyKeys = keyof IUserInfo;
    (Object.keys(value) as MyKeys[]).forEach(
      (k: MyKeys) =>
        value[k] === '' || (value[k] === undefined && delete value[k])
    );
    const response = await PatchRequest('user', value);

    return response.data.data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});
export const logOutAsyncThunk = createAsyncThunk<any, any, AsyncThunkConfig>(
  'user/logout',
  async (a: any, { rejectWithValue }) => {
    try {
      const response = await PostRequest('auth/log-out', {});
      // isLoggedIn: true,
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const switchProfileAsyncThunk = createAsyncThunk<
  any,
  Record<string, any>,
  AsyncThunkConfig
>(
  'user/switchProfile',
  async (value: Record<string, any>, { rejectWithValue }) => {
    try {
      const response = await PostRequest('auth/change-profile', value);
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);
export const loginAsyncThunk = createAsyncThunk<
  IUserInfo,
  any,
  AsyncThunkConfig
>('user/login', async (value, { rejectWithValue }) => {
  try {
    console.log(value);
    const response = await PostRequest('auth/log-in', value);
    return response.data.data as IUserInfo;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

function removeBlankAttributes(obj: Record<string, any>) {
  const filteredObj: Record<string, any> = {};
  Object.keys(obj).forEach((key) => {
    if (Array.isArray(obj[key]) && obj[key].length === 0) {
      return;
    }
    filteredObj[key] = obj[key];
  });
  return filteredObj;
}
export const UpdateSellerStoreAsyncThunk = createAsyncThunk<
  IUserStore,
  Partial<IUserStore>,
  AsyncThunkConfig
>('store/update', async (value: Partial<IUserStore>, { rejectWithValue }) => {
  try {
    //?for removing null fields
    type MyKeys = keyof IUserStore;
    (Object.keys(value) as MyKeys[]).forEach(
      (k: MyKeys) =>
        value[k] === '' || (value[k] === undefined && delete value[k])
    );
    const data = removeBlankAttributes(value);
    const response = await PatchRequest('store', data);
    return response.data.data;
  } catch (error: any) {
    console.log(error);
    return rejectWithValue(error.response.data);
  }
});

export const fetchUserInfoAsyncThunk = createAsyncThunk<
  IUserInfo,
  Record<string, any>,
  AsyncThunkConfig
>('user/fetchUser', async (value: Record<string, any>, { rejectWithValue }) => {
  try {
    let { showIndicatorForErrorRequest } = value;
    const response = await GetRequest(
      'user/self',
      showIndicatorForErrorRequest
    );
    return response.data.data as IUserInfo;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

export const AuthSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    addIsLogin: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = 'Pending';
      state.isLoggedIn = Boolean(payload);
      state.loading = 'Fulfilled';
    },
    addUser: (state, action) => {
      state.loading = 'Pending';
      state = action.payload;
      state.loading = 'Fulfilled';
    },
    removeUser: (state) => {
      state.loading = 'Pending';
      console.log('removeuser called');
      state = { ...initialState };
      state.isLoggedIn = false;
      state.loading = 'Fulfilled';
    },
    addCurrentUser: (state, { payload }: PayloadAction<IUserStore>) => {
      state.loading = 'Pending';
      state.currentUser = { ...payload };
      state.loading = 'Fulfilled';
    },
    addUserInfoFromLoacalStore: (
      state,
      { payload }: PayloadAction<IUserInfo>
    ) => {
      state.loading = 'Pending';
      state.userInfo = { ...payload };
      state.loading = 'Fulfilled';
    },
    showError: (state, { payload }: PayloadAction<CustomError>) => {
      state.error = { ...payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserInfoAsyncThunk.pending, (state) => {
        state.loading = 'Pending';
      })
      .addCase(
        fetchUserInfoAsyncThunk.fulfilled,
        (state, { payload }: PayloadAction<IUserInfo>) => {
          state.userInfo = { ...payload };
          state.currentUser = { ...payload };
          // AsyncStorage.setItem('userInfo', JSON.stringify(payload));
          // AsyncStorage.setItem('isLogin', 'true');
          state.isLoggedIn = true;
          state.loading = 'Fulfilled';
        }
      )
      .addCase(fetchUserInfoAsyncThunk.rejected, (state, { payload }) => {
        // state = { ...initialState };
        state.userInfo = {
          _id: '',
          username: '',
          phoneNumber: {
            countryCode: '',
            nationalNumber: '',
          },
          email: '',
          name: '',
          profileImage: '',
          roleList: [],
          dateOfBirth: '',
          gender: '',
        };
        state.loading = 'Fulfilled';
        // showMessage({
        //   message: JSON.stringify(payload),
        //   type: 'danger',
        // });
      })
      .addCase(loginAsyncThunk.pending, (state) => {
        state.loading = 'Pending';
      })
      .addCase(
        loginAsyncThunk.fulfilled,
        (state, { payload }: PayloadAction<IUserInfo>) => {
          state.userInfo = { ...payload };
          state.isLoggedIn = true;
          state.currentUser = { ...payload };
          state.loading = 'Fulfilled';
        }
      )
      .addCase(loginAsyncThunk.rejected, (state, { payload }: any) => {
        state = { ...initialState };
        if (payload.error.statusCode === 400) {
          state.isLoggedIn = true;
        }
      })
      .addCase(logOutAsyncThunk.pending, (state) => {
        state.loading = 'Pending';
      })
      .addCase(logOutAsyncThunk.fulfilled, (state) => {
        state.userInfo = {
          _id: '',
          username: '',
          phoneNumber: '',
          email: '',
          name: '',
          profileImage: '',
          roleList: [],
          dateOfBirth: '',
          gender: '',
        };
        state.loading = 'Fulfilled';
        state.isLoggedIn = false;
        console.log(state, 'this is the state,');
      })
      .addCase(updateProfileAsyncThunk.pending, (state) => {
        state.loading = 'Pending';
      })
      .addCase(
        updateProfileAsyncThunk.fulfilled,
        (state, { payload }: PayloadAction<IUserInfo>) => {
          state.userInfo = { ...payload };
          console.log(payload);
          state.loading = 'Fulfilled';
        }
      )
      .addCase(updateProfileAsyncThunk.rejected, (state, { payload }) => {
        //todo some change in message needed
        state.loading = 'Fulfilled';
        // showMessage({
        //   message: 'Something went wrong',
        //   type: 'danger',
        // });
      })
      .addCase(fetchUserStoreListAsyncThunk.pending, (state) => {
        state.loading = 'Pending';
      })
      .addCase(
        fetchUserStoreListAsyncThunk.fulfilled,
        (state, { payload }: PayloadAction<IUserStore[]>) => {
          state.UserStoreList = [...payload];
          state.userStoreListFetched = true;
          state.userInfo.loading = 'Fullfilled';
        }
      )
      .addCase(fetchStoreSelfAsyncThunk.pending, (state) => {
        state.loading = 'Pending';
      })
      .addCase(
        fetchStoreSelfAsyncThunk.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          state.storeSelfProfile = { ...payload };
          if (payload?.seller) {
            state.storeSelfProfile = { ...payload };
            state.currentUser = { ...payload };
            if (state.currentUser) state.currentUser.profileType = 'Store';
          }
          // showMessage({
          //   message: 'Profile Changed',
          //   type: 'success',
          // });
          state.loading = 'Fulfilled';
        }
      )
      .addCase(fetchStoreSelfAsyncThunk.rejected, (state, { payload }) => {
        state.loading = 'Fulfilled';
      })
      .addCase(switchProfileAsyncThunk.pending, (state) => {
        state.loading = 'Pending';
      })
      .addCase(switchProfileAsyncThunk.fulfilled, (state, { payload }) => {
        if (payload?.seller || payload?.buyer) {
          state.storeSelfProfile = { ...payload };
          state.currentUser = { ...payload };
          if (state.currentUser) state.currentUser.profileType = 'Store';
          window && window.localStorage.setItem('ProfileType', 'Store');
        } else {
          state.userInfo = { ...payload };
          state.currentUser = { ...payload };
          if (state.currentUser) state.currentUser.profileType = 'User';
          window && window.localStorage.setItem('ProfileType', 'User');
        }
        state.loading = 'Fulfilled';
      })
      .addCase(switchProfileAsyncThunk.rejected, (state) => {
        state.loading = 'Fulfilled';
      })
      .addCase(UpdateSellerStoreAsyncThunk.pending, (state) => {
        state.loading = 'Pending';
      })
      .addCase(
        UpdateSellerStoreAsyncThunk.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          console.log(JSON.stringify(payload));
          state.storeSelfProfile = { ...payload };
          state.loading = 'Fulfilled';
          return state;
        }
      )
      .addCase(UpdateSellerStoreAsyncThunk.rejected, (state, { payload }) => {
        state.loading = 'Fulfilled';
      });
  },
});

export const { addUser, removeUser, addCurrentUser, addIsLogin, showError } =
  AuthSlice.actions;

export default AuthSlice.reducer;

export const errorSelector = (state: RootState) => state.user.error;
export const isLoginSelector = (state: RootState) => state.user.isLoggedIn;
export const userSelector = (state: RootState) => state.user.userInfo;
export const storeListSelector = (state: RootState) => state.user.UserStoreList;
export const storeListFetchedSelector = (state: RootState) =>
  state.user.userStoreListFetched;
export const currentUserSelector = (state: RootState) => state.user.currentUser;
export const loadingSelector = (state: RootState) => state.user.loading;
export const storeProfileSelector = (state: RootState) =>
  state.user.storeSelfProfile;
export const emailSelecter = (state: RootState) =>
  state.user.storeSelfProfile?.email;
export const phoneNumberListSelector = (state: RootState) =>
  state.user.storeSelfProfile?.phoneNumberList;
export const profileTypeSelector = (state: RootState) =>
  state.user.currentUser?.profileType;
export const storeProfileAddressSelector = (state: RootState) =>
  state.user.storeSelfProfile?.address;
export const storeProfileLocationSelector = (state: RootState) =>
  state.user.storeSelfProfile?.location;
