import { useState } from 'react';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { PostRequest } from '../../../../components/Request';
import { MdOutlineKeyboardBackspace } from 'react-icons/md';
import { RxCross2 } from 'react-icons/rx';

const CssTextField = styled(TextField)({
  '& .MuiInputBase-input': {
    padding: '0.75rem 1.25rem',
  },
  '& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root': {
    lineHeight: '1rem',
    fontSize: '0.90rem',
    color: '#9FA6B3',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: '0.35rem',
      borderColor: '#E5E7EB',
    },
  },
});

export default function StoreLegal(props: any) {
  let { shopInfo, setShopInfo, setSelectedNavigation } = props;
  const [panCardInfo, setPanCard] = useState<any>({
    pancard: '',
    isPanCardValid: null,
  });

  const pancardValidation = (panNumber: any) => {
    let regex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
    if (regex.test(panNumber)) {
      return true;
    } else {
      return false;
    }
  };

  function validateGST(GST: any) {
    var expr = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    if (!expr.test(GST)) {
      return false;
    }
    return true;
  }

  const handleClickToUploadLegalDocuments = async (event: any, type: any) => {
    let img = event.target.files[0];
    const formData = new FormData();
    formData.append('file', img);
    await PostRequest(`file?type=store-legal-document`, formData)
      .then((fileResponse: any) => {
        if (fileResponse.data.statusCode == 201) {
          if (type == 'panCard') {
            let updatedShopInfo = { ...shopInfo };
            updatedShopInfo.panCard.document = fileResponse.data.data.url;
            updatedShopInfo.errors.panCard.document = '';
            setShopInfo(updatedShopInfo);
          } else if (type == 'GST') {
            let updatedShopInfo = { ...shopInfo };
            updatedShopInfo.GST.document = fileResponse.data.data.url;
            updatedShopInfo.errors.GST.document = '';
            setShopInfo(updatedShopInfo);
          } else {
            let updatedShopInfo = { ...shopInfo };
            updatedShopInfo.tradeLicense.document = fileResponse.data.data.url;
            updatedShopInfo.errors.tradeLicense.document = '';
            setShopInfo(updatedShopInfo);
          }
        }
      })
      .catch((error) => {
        if (type === 'panCard') {
          if (
            error.response.data.message === 'File larger than 1 MB not accepted'
          ) {
            let updatedShopInfo = { ...shopInfo };
            updatedShopInfo.panCard.document = '';
            updatedShopInfo.errors.panCard.document =
              error.response.data.message;
            setShopInfo(updatedShopInfo);
          } else {
            let updatedShopInfo = { ...shopInfo };
            updatedShopInfo.panCard.document = '';
            updatedShopInfo.errors.panCard.document = 'Something went wrong';
            setShopInfo(updatedShopInfo);
          }
        } else if (type === 'tradeLicense') {
          if (
            error.response.data.message === 'File larger than 1 MB not accepted'
          ) {
            let updatedShopInfo = { ...shopInfo };
            updatedShopInfo.tradeLicense.document = '';
            updatedShopInfo.errors.tradeLicense.document =
              error.response.data.message;
            setShopInfo(updatedShopInfo);
          } else {
            let updatedShopInfo = { ...shopInfo };
            updatedShopInfo.tradeLicense.document = '';
            updatedShopInfo.errors.tradeLicense.document =
              'Something went wrong';
            setShopInfo(updatedShopInfo);
          }
        } else {
          if (
            error.response.data.message === 'File larger than 1 MB not accepted'
          ) {
            let updatedShopInfo = { ...shopInfo };
            updatedShopInfo.GST.document = '';
            updatedShopInfo.errors.GST.document = error.response.data.message;
            setShopInfo(updatedShopInfo);
          } else {
            let updatedShopInfo = { ...shopInfo };
            updatedShopInfo.GST.document = '';
            updatedShopInfo.errors.GST.document = 'Something went wrong';
            setShopInfo(updatedShopInfo);
          }
        }
      });
  };

  const handleChange = async (event: any) => {
    let { value, name } = event.target;
    let { errors } = shopInfo;
    switch (name) {
      case 'shopName':
        setShopInfo((prevState: any) => {
          return {
            ...prevState,
            [name]: value,
            errors,
          };
        });
        break;
      case 'shopImages':
        let img1 = event.target.files[0];
        const formData1 = new FormData();
        formData1.append('file', img1);
        await PostRequest('file?type=store-image', formData1)
          .then((fileResponse: any) => {
            setShopInfo({
              ...shopInfo,
              shopImages: shopInfo.shopImages.concat(fileResponse.data.url),
            });
          })
          .catch((err) => {
            setShopInfo({
              ...shopInfo,
              errors: {
                ...shopInfo.errors,
                shopImages: err,
              },
            });
          });
        break;
      case 'profileImage':
        let img = event.target.files[0];
        const formData = new FormData();
        formData.append('file', img);
        await PostRequest('file?type=store-image', formData)
          .then((fileResponse: any) => {
            setShopInfo({
              ...shopInfo,
              profileImage: fileResponse.data.data.url,
            });
          })
          .catch((err) => {
            setShopInfo({
              ...shopInfo,
              errors: {
                ...shopInfo.errors,
                profileImage: err,
              },
            });
          });
        break;
      case 'shopName':
        errors.shopName = !value ? 'shopname is required' : '';
        break;
      case 'shopCategory':
        errors.shopCategory = !value ? 'shop category is required' : '';
        break;

      case 'panCard':
        value = value.toUpperCase();
        if (value == '') {
          errors.panCard = 'PAN Card is required';
          setShopInfo((prevState: any) => {
            return {
              ...prevState,
              [name]: value,
              errors,
            };
          });
        } else if (!pancardValidation(value)) {
          errors.panCard = 'PAN Card is invalid';
          setShopInfo((prevState: any) => {
            return {
              ...prevState,
              [name]: value,
              errors,
            };
          });
        } else {
          errors.panCard = '';
          setShopInfo((prevState: any) => {
            return {
              ...prevState,
              [name]: value,
              errors,
            };
          });
        }
        break;
      case 'GST':
        if (!validateGST(value)) {
          errors.GST = 'GST is invalid';
          setShopInfo((prevState: any) => {
            return {
              ...prevState,
              [name]: value,
              errors,
            };
          });
        } else {
          errors.GST = '';
          setShopInfo((prevState: any) => {
            return {
              ...prevState,
              [name]: value,
              errors,
            };
          });
        }
        break;
      case 'tradeLicense':
        setShopInfo((prevState: any) => {
          return {
            ...prevState,
            [name]: value,
            errors,
          };
        });
    }
    if (name == 'email') {
      setShopInfo((prevState: any) => {
        return {
          ...prevState,
          [name]: { id: value, isVerified: false },
          errors,
        };
      });
    }
  };

  return (
    <div className="cnt_of_stepone store-profile-form store-legal-form">
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
        }}
      >
        <button
          className="go-back-button"
          onClick={() => setSelectedNavigation('')}
        >
          <MdOutlineKeyboardBackspace style={{ display: 'inline' }} />
        </button>
      </div>
      <form action="#">
        <div className="flex my-10 justify-between sm:flex-col  items-center sm:items-stretch">
          <div className="basis-[47.5%]">
            <CssTextField
              sx={{
                width: '100%',
                borderRadius: '1.5rem',
                color: 'red',
              }}
              required
              onChange={handleChange}
              id="outlined-basic"
              name="panCard"
              label="PAN Card"
              value={shopInfo.panCard.number}
              variant="outlined"
              placeholder="eg: 1234567890"
              error={shopInfo.errors.panCard.number ? true : false}
              helperText={
                shopInfo.errors.panCard.number
                  ? shopInfo.errors.panCard.number
                  : ''
              }
            />
          </div>
          <div className="basis-[47.5%] sm:justify-center sm:self-center  justify-between flex sm:mt-2 sm:flex-wrap">
            <div className="uploaded-cnt">
              {shopInfo.panCard.document ? (
                <figure
                  className="relative m-[0.4rem] sm:m-[0.2rem] inline-block rounded border-[1px] border-solid border-[black]"
                  style={{
                    width: '110px',
                    height: '110px',
                  }}
                >
                  <span
                    onClick={() => {
                      let newShopInfo = { ...shopInfo };
                      newShopInfo.panCard.document = '';
                      setShopInfo(newShopInfo);
                    }}
                    style={{
                      position: 'absolute',
                      right: '4px',
                      borderRadius: '50%',
                      display: 'flex',
                      background: 'white',
                      top: '5px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '20px',
                      height: '20px',
                    }}
                  >
                    <RxCross2 />
                  </span>
                  <iframe
                    allow-transparency="true"
                    src={shopInfo.panCard.document}
                    className="rounded-md w-full h-[110px] object-cover"
                  />
                </figure>
              ) : (
                <div>
                  <button
                    type="button"
                    className={
                      shopInfo.errors.panCard.number
                        ? 'button-primary-disabled shadow-inner'
                        : 'button-primary shadow-inner'
                    }
                  >
                    <input
                      onChange={(event: any) => {
                        if (pancardValidation(shopInfo.panCard.number)) {
                          return handleClickToUploadLegalDocuments(
                            event,
                            'panCard'
                          );
                        } else {
                          let updatedShopInfo = { ...shopInfo };
                          updatedShopInfo.errors.panCard.number =
                            'Provide a valid PAN Card';
                          console.log(updatedShopInfo, 'dfgh');
                          setShopInfo(updatedShopInfo);
                        }
                      }}
                      type="file"
                      name="panCard"
                      style={{
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        opacity: 0,
                      }}
                    />
                    Upload PAN
                  </button>
                </div>
              )}
            </div>

            {shopInfo.errors.panCard.document && (
              <div
                className="error sm:basis-[100%]"
                style={{
                  margin: '0',
                  textAlign: 'center',
                  marginTop: '0.5rem',
                }}
              >
                {shopInfo.errors.panCard.document}
              </div>
            )}
          </div>
        </div>

        <div className="flex my-10 justify-between sm:flex-col items-center sm:items-stretch">
          <div className="basis-[47.5%]">
            <CssTextField
              sx={{
                width: '100%',
                borderRadius: '1.5rem',
              }}
              onChange={handleChange}
              id="outlined-basic"
              name="GST"
              label="GST"
              value={shopInfo.GST.number}
              variant="outlined"
              placeholder="eg: 1234567890"
              error={shopInfo.errors.GST.number ? true : false}
              helperText={
                shopInfo.errors.GST.number ? shopInfo.errors.GST.number : ''
              }
              required
            />
          </div>
          <div className="basis-[47.5%] sm:justify-center sm:self-center  justify-between flex sm:mt-2 sm:flex-wrap">
            <div className="uploaded-cnt">
              {shopInfo.GST.document ? (
                <figure
                  className="relative m-[0.4rem] sm:m-[0.2rem] inline-block rounded border-[1px] border-solid border-[black]"
                  style={{
                    width: '110px',
                    height: '110px',
                  }}
                >
                  <span
                    onClick={() => {
                      let newShopInfo = { ...shopInfo };
                      newShopInfo.GST.document = '';
                      setShopInfo(newShopInfo);
                    }}
                    style={{
                      position: 'absolute',
                      right: '4px',
                      borderRadius: '50%',
                      display: 'flex',
                      background: 'white',
                      top: '5px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '20px',
                      height: '20px',
                    }}
                  >
                    <RxCross2 />
                  </span>
                  <iframe
                    allow-transparency="true"
                    src={shopInfo.GST.document}
                    className="rounded-md w-full h-[110px] object-cover"
                  />
                </figure>
              ) : (
                <div>
                  <button
                    type="button"
                    className={
                      shopInfo.errors.GST.number
                        ? 'button-primary-disabled shadow-inner'
                        : 'button-primary shadow-inner'
                    }
                  >
                    <input
                      onChange={(event: any) => {
                        if (validateGST(shopInfo.GST.number)) {
                          return handleClickToUploadLegalDocuments(
                            event,
                            'GST'
                          );
                        } else {
                          let updatedShopInfo = { ...shopInfo };
                          updatedShopInfo.errors.GST.number =
                            'Provide a valid GST';

                          setShopInfo(updatedShopInfo);
                        }
                      }}
                      type="file"
                      name="GST"
                      style={{
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        opacity: 0,
                      }}
                    />
                    Upload GST
                  </button>
                </div>
              )}
            </div>

            {shopInfo.errors.GST.document && (
              <div
                className="error sm:basis-[100%]"
                style={{
                  margin: '0',
                  textAlign: 'center',
                  marginTop: '0.5rem',
                }}
              >
                {shopInfo.errors.GST.document}
              </div>
            )}
          </div>
        </div>

        <div
          className={
            shopInfo.tradeLicense.document
              ? 'flex my-10 justify-between sm:flex-col  items-center sm:items-stretch'
              : 'flex my-10 justify-between sm:flex-col  items-start sm:items-stretch'
          }
        >
          <div className="basis-[47.5%]">
            <CssTextField
              sx={{
                width: '100%',
                borderRadius: '1.5rem',
              }}
              onChange={handleChange}
              id="outlined-basic"
              value={shopInfo.tradeLicense.number}
              name="tradeLicense"
              label="Trade License"
              variant="outlined"
              placeholder="eg: 1234567890"
              error={shopInfo.errors.tradeLicense.number ? true : false}
              helperText={shopInfo.errors.tradeLicense.number}
            />
          </div>
          <div className="basis-[47.5%] sm:justify-center sm:self-center  justify-between flex sm:mt-2 sm:flex-wrap">
            <div className="uploaded-cnt">
              {shopInfo.tradeLicense.document ? (
                <figure
                  className="relative m-[0.4rem] sm:m-[0.2rem] inline-block rounded border-[1px] border-solid border-[black]"
                  style={{
                    width: '110px',
                    height: '110px',
                  }}
                >
                  <span
                    onClick={() => {
                      let newShopInfo = { ...shopInfo };
                      newShopInfo.tradeLicense.document = '';
                      setShopInfo(newShopInfo);
                    }}
                    style={{
                      position: 'absolute',
                      right: '4px',
                      borderRadius: '50%',
                      display: 'flex',
                      background: 'white',
                      top: '5px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '20px',
                      height: '20px',
                    }}
                  >
                    <RxCross2 />
                  </span>
                  <iframe
                    src={shopInfo.tradeLicense.document}
                    className="rounded-md w-full h-[110px] object-cover"
                  />
                </figure>
              ) : (
                <div>
                  <button
                    type="button"
                    className={
                      shopInfo.errors.tradeLicense.number
                        ? 'button-primary-disabled shadow-inner'
                        : 'button-primary shadow-inner'
                    }
                  >
                    <input
                      onChange={(event: any) => {
                        if (shopInfo.tradeLicense.number) {
                          return handleClickToUploadLegalDocuments(
                            event,
                            'tradeLicense'
                          );
                        } else {
                          let updatedShopInfo = { ...shopInfo };
                          updatedShopInfo.errors.tradeLicense.number =
                            'Provide a valid tradeLicense';

                          setShopInfo(updatedShopInfo);
                        }
                      }}
                      type="file"
                      name="tradeLicense"
                      style={{
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        opacity: 0,
                      }}
                    />
                    Upload License
                  </button>
                </div>
              )}
            </div>

            {shopInfo.errors.tradeLicense.document && (
              <div
                className="error sm:basis-[100%]"
                style={{
                  margin: '0',
                  textAlign: 'center',
                  marginTop: '0.5rem',
                }}
              >
                {shopInfo.errors.tradeLicense.document}
              </div>
            )}
          </div>
        </div>

        <hr />
        <div className="text-right my-10 mx-2 store-profile-update-cnt">
          <button
            type="button"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            disabled={
              !shopInfo.shopName ||
              !shopInfo.shopCategory ||
              shopInfo.shopType.length == 0
                ? true
                : false
            }
            className={
              !shopInfo.shopName ||
              !shopInfo.shopCategory ||
              shopInfo.shopType.length == 0
                ? 'button-primary-disabled md:w-[5rem] md:text-[12px]'
                : 'button-primary md:w-[5rem] md:text-[12px]'
            }
          >
            Update
          </button>
        </div>
      </form>
    </div>
  );
}
