import './profile.scss';
import {
  FaStore,
  FaUserCircle,
  FaMapMarkerAlt,
  FaEnvelope,
  FaRegBuilding,
} from 'react-icons/fa';
import { useState } from 'react';
import { useEffect } from 'react';
import UserProfile from './component/UserProfile';
import StoreProfile from './component/StoreProfile';
import StoreContact from './component/StoreContact';
import StoreLocation from './component/StoreLocation';
import StoreLegal from './component/StoreLegal';
import { GetRequest, PostRequest } from '../../../components/Request';
import Indicator from '../../../common/Indicators/Indicator';
import StorePublicProfile from './component/StorePublicProfile';

export default function Profile(props: any) {
  const [shopLocation, setShopLocation] = useState({
    shopAddress: '',
    landmark: '',
    district: '',
    state: '',
    country: '',
    zipCode: '',
    tehsil: '',
    errors: {
      shopAddress: '',
      landmark: '',
      district: '',
      state: '',
      country: '',
      zipCode: '',
      tehsil: '',
    },
  });
  const [shopInfo, setShopInfo] = useState({
    seller: true,
    shopName: '',
    shopType: [],
    shopCategory: 'Distributor',
    phoneNumbers: [],
    panCard: { number: '', document: '' },
    tradeLicense: { number: '', document: '' },
    profileImage: '',
    shopImages: [],
    GST: { number: '', document: '' },
    email: { id: '', isVerified: false },
    errors: {
      shopName: '',
      shopType: '',
      shopCategory: '',
      phoneNumbers: '',
      tradeLicense: { number: '', document: '' },
      panCard: { number: '', document: '' },
      GST: { number: '', document: '' },
      email: '',
      profileImage: '',
      shopImages: '',
    },
  });
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [selectedNavigation, setSelectedNavigation] = useState('user-profile');
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [message, setMessage] = useState('');
  const [type, setType] = useState('');
  const [showIndicator, setShowIndicator] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Listen for window resize events
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    console.log(Boolean(localStorage.activeStore), 'this ');
    if (localStorage.activeStore) {
      let activeStore = JSON.parse(localStorage.activeStore);
      // activeStore = activeStore[0];

      setShopInfo({
        ...shopInfo,
        shopName: activeStore.name,
        shopType: activeStore.type,
        shopCategory: activeStore.category,
        phoneNumbers: activeStore.phoneNumbers,
        panCard: activeStore.panCard && '',
        tradeLicense: activeStore.tradeLicense && '',
        GST: activeStore.GST && '',
        email: { id: activeStore.email, isVerified: true },
        shopImages: activeStore.images,
        profileImage: activeStore.profileImage,
      });

      setShopLocation({
        ...shopLocation,
        shopAddress: activeStore.address?.shopAddress && '',
        landmark: activeStore.address?.landmark && '',
        district: activeStore.address?.district,
        state: activeStore.address?.state,
        country: activeStore.address?.country,
        zipCode: activeStore.address?.postalCode,
        tehsil: activeStore.address?.tehsil && '',
      });
    } else {
      if (selectedNavigation.includes('store')) {
        GetRequest('store/self').then(async (res) => {
          let activeStore = res.data.data;

          console.log(
            activeStore,
            activeStore.phoneNumbers.map((numberObj: any) => {
              // console.log(
              //   typeof numberObj.phoneNumber.slice(3),
              //   numberObj.phoneNumber.slice(3),
              //   'this is number obj'
              // );
              // return {
              //   countryCode: '+91',
              //   label: numberObj.label,
              //   nationalNumber: numberObj.phoneNumber.silce(3),
              // };
            }),
            'this is activeStore'
          );
          setShopInfo({
            ...shopInfo,
            shopName: activeStore.name,
            shopType: activeStore.type,
            shopCategory: activeStore.category,
            phoneNumbers: await activeStore.phoneNumbers.map(
              (numberObj: any) => {
                return {
                  countryCode: '+91',
                  label: numberObj.label,
                  // ** adding this national number in static later change into dynamic by seeing the issue in slice method **
                  nationalNumber: +91,
                  // nationalNumber: numberObj.phoneNumber.silce(3),
                };
              }
            ),
            panCard: activeStore.panCard && '',
            tradeLicense: activeStore.tradeLicense && '',
            GST: activeStore.GST && '',
            email: { id: activeStore.email, isVerified: true },
            shopImages: activeStore.images,
            profileImage: activeStore.profileImage,
          });

          console.log(
            shopInfo,
            shopLocation,
            activeStore,
            'coming from get request'
          );
        });
        // .catch((err) => {
        //   console.log(err, 'err in store self api');
        // });
      }
    }
  }, [selectedNavigation]);

  useEffect(() => {
    if (window.innerWidth < 575) {
      return setSelectedNavigation('');
    }
  }, [window.innerWidth]);

  const pancardValidation = (panNumber: any) => {
    let regex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
    if (regex.test(panNumber)) {
      return true;
    } else {
      return false;
    }
  };

  function validateGST(GST: any) {
    var expr = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    if (!expr.test(GST)) {
      return false;
    }
    return true;
  }

  function validateEmail(email: any) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true;
    }
    return false;
  }

  const handleChange = async (event: any) => {
    let { value, name } = event.target;
    let { errors } = shopInfo;
    switch (name) {
      case 'shopName':
        setShopInfo((prevState: any) => {
          return {
            ...prevState,
            [name]: value,
            errors: {
              ...prevState.errors,
              shopName: value == '' ? 'Name is required' : '',
            },
          };
        });
        break;
      case 'shopImages':
        let img1 = event.target.files[0];
        const formData1 = new FormData();
        formData1.append('file', img1);
        await PostRequest('file?type=store-image', formData1)
          .then((fileResponse: any) => {
            setShopInfo({
              ...shopInfo,
              shopImages: fileResponse.data.data.url.includes('pdf')
                ? shopInfo.shopImages
                : shopInfo.shopImages.concat(fileResponse.data.data.url),
              errors: {
                ...shopInfo.errors,
                shopImages: fileResponse.data.data.url.includes('pdf')
                  ? 'pdf file type not supported'
                  : '',
              },
            });
          })
          .catch((error) => {
            if (
              error.response.data.message ===
              'File larger than 1 MB not accepted'
            ) {
              setShopInfo({
                ...shopInfo,
                errors: {
                  ...shopInfo.errors,
                  shopImages: error.response.data.message,
                },
              });
            }
          });

        setTimeout(() => {
          setShopInfo((prevState) => {
            return {
              ...prevState,
              errors: {
                ...prevState.errors,
                shopImages: '',
              },
            };
          });
        }, 2000);
        break;
      case 'profileImage':
        let img = event.target.files[0];
        const formData = new FormData();
        formData.append('file', img);
        await PostRequest('file?type=store-image', formData)
          .then((fileResponse: any) => {
            setShopInfo({
              ...shopInfo,
              profileImage: fileResponse.data.data.url.includes('pdf')
                ? ''
                : fileResponse.data.data.url,
              errors: {
                ...shopInfo.errors,
                profileImage: fileResponse.data.data.url.includes('pdf')
                  ? 'pdf file type not supported'
                  : '',
              },
            });
          })
          .catch((error) => {
            if (
              error.response.data.message ===
              'File larger than 1 MB not accepted'
            ) {
              setShopInfo({
                ...shopInfo,
                errors: {
                  ...shopInfo.errors,
                  profileImage: error.response.data.message,
                },
              });
            }
          });
        setTimeout(() => {
          setShopInfo((prevState) => {
            return {
              ...prevState,
              errors: {
                ...prevState.errors,
                profileImage: '',
              },
            };
          });
        }, 2000);
        break;
      case 'shopName':
        errors.shopName = !value ? 'shopname is required' : '';
        break;
      case 'shopCategory':
        errors.shopCategory = !value ? 'shop category is required' : '';
        break;
      case 'email':
        if (value == '') {
          errors.email = 'email is required';
        } else if (!validateEmail(value)) {
          errors.email = 'email is invalid';
        } else {
          errors.email = '';
        }

        break;
      case 'panCard':
        value = value.toUpperCase();
        if (value === '') {
          errors.panCard.number = 'PAN Card is required';
          setShopInfo((prevState: any) => {
            return {
              ...prevState,
              [name]: { ...prevState[name], number: '' },
              errors: { ...errors },
            };
          });
        } else if (!pancardValidation(value)) {
          errors.panCard.number = 'PAN Card is invalid';
          setShopInfo((prevState: any) => {
            return {
              ...prevState,
              [name]: { ...prevState[name], number: value },
              errors: { ...errors },
            };
          });
        } else {
          errors.panCard.number = '';
          setShopInfo((prevState: any) => {
            return {
              ...prevState,
              [name]: { ...prevState[name], number: value },
              errors: { ...errors },
            };
          });
        }
        break;
      case 'GST':
        if (!validateGST(value)) {
          errors.GST.number = 'GST is invalid';
          setShopInfo((prevState: any) => {
            return {
              ...prevState,
              [name]: { ...prevState.GST, number: value },
              errors: { ...errors },
            };
          });
        } else {
          errors.GST.number = '';
          setShopInfo((prevState: any) => {
            return {
              ...prevState,
              [name]: { ...prevState.GST, number: value },
              errors: { ...errors },
            };
          });
        }
        break;

      case 'tradeLicense':
        setShopInfo((prevState: any) => {
          return {
            ...prevState,
            [name]: { ...prevState.tradeLicense, number: value },
            errors: { ...errors },
          };
        });
    }
    if (name == 'email') {
      setShopInfo((prevState: any) => {
        return {
          ...prevState,
          [name]: { id: value, isVerified: false },
          errors,
        };
      });
    }
  };

  return (
    <div className="account-settings-container">
      {/* {showIndicator ? <Indicator message={message} type={type} /> : ''} */}
      <NavBar
        screenWidth={screenWidth}
        selectedNavigation={selectedNavigation}
        setSelectedNavigation={setSelectedNavigation}
      />

      <>
        {selectedNavigation == 'user-profile' ? (
          <UserProfile
            setShowIndicator={setShowIndicator}
            setType={setType}
            setMessage={setMessage}
            setSelectedNavigation={setSelectedNavigation}
          />
        ) : (
          ''
        )}

        {selectedNavigation == 'store-profile' ? (
          <StoreProfile
            setShowIndicator={setShowIndicator}
            setType={setType}
            setMessage={setMessage}
            handleChange={handleChange}
            shopInfo={shopInfo}
            setShopInfo={setShopInfo}
            setSelectedNavigation={setSelectedNavigation}
          />
        ) : (
          ''
        )}

        {selectedNavigation == 'store-public-profile' ? (
          <StorePublicProfile />
        ) : (
          ''
        )}

        {selectedNavigation == 'store-contact' ? (
          <StoreContact
            setShowIndicator={setShowIndicator}
            setType={setType}
            setMessage={setMessage}
            handleChange={handleChange}
            shopInfo={shopInfo}
            validateEmail={validateEmail}
            setShopInfo={setShopInfo}
            setSelectedNavigation={setSelectedNavigation}
          />
        ) : (
          ''
        )}

        {selectedNavigation == 'store-location' ? (
          <StoreLocation
            handleChange={handleChange}
            setShopLocation={setShopLocation}
            shopLocation={shopLocation}
            latitude={latitude}
            setLatitude={setLatitude}
            longitude={longitude}
            setLongitude={setLongitude}
            setSelectedNavigation={setSelectedNavigation}
          />
        ) : (
          ''
        )}

        {selectedNavigation == 'store-legal' ? (
          <StoreLegal
            pancardValidation={pancardValidation}
            validateGST={validateGST}
            handleChange={handleChange}
            shopInfo={shopInfo}
            setShopInfo={setShopInfo}
            setSelectedNavigation={setSelectedNavigation}
          />
        ) : (
          ''
        )}
      </>
    </div>
  );
}

function NavBar(props: any) {
  let { screenWidth, selectedNavigation, setSelectedNavigation } = props;
  if (screenWidth >= 576) {
    return (
      <div
        style={{
          width: screenWidth > 576 ? '26%' : '100%',
          borderRight: screenWidth > 576 ? '0.5px solid #ddd' : 'none',
          marginLeft: screenWidth < 576 ? '1rem' : '0',
          marginRight: screenWidth < 576 ? '1rem' : '0',
        }}
        className="navigation"
      >
        <div>
          <div>
            User
            <hr />
          </div>
          <div
            className={`menu ${
              selectedNavigation == 'user-profile' ? 'active-nav ' : ''
            }`}
            style={{
              display: 'flex',
              alignItems: 'center',
              flexBasis: '100%',
              fontSize: 'smaller',
            }}
            onClick={() => setSelectedNavigation('user-profile')}
          >
            <div
              className={`icon `}
              style={{ width: '28px', height: '28px', fontSize: '1rem' }}
            >
              <FaUserCircle />
            </div>
            <span style={{ fontSize: '16px' }}>User Profile</span>
          </div>
        </div>
        <div
          onClick={() => {
            setSelectedNavigation('store-profile');
          }}
        >
          <div>
            Store
            <hr />
          </div>
        </div>

        <div className="submenu">
          <div
            className={`menu ${
              selectedNavigation == 'store-profile' ? 'active-nav' : ''
            }`}
            onClick={() => {
              setSelectedNavigation('store-profile');
            }}
          >
            <div className="icon">
              <FaStore />
            </div>
            <span>Store Profile</span>
          </div>

          <div
            className={`menu ${
              selectedNavigation == 'store-public-profile' ? 'active-nav' : ''
            }`}
            onClick={() => {
              setSelectedNavigation('store-public-profile');
            }}
          >
            <div className="icon">
              <FaStore />
            </div>
            <span>Store Public Profile</span>
          </div>

          <div
            className={`menu ${
              selectedNavigation == 'store-contact' ? 'active-nav' : ''
            }`}
            onClick={() => {
              setSelectedNavigation('store-contact');
            }}
          >
            <div className="icon">
              <FaEnvelope />
            </div>
            <span>Contact</span>
          </div>

          <div
            className={`menu ${
              selectedNavigation == 'store-location' ? 'active-nav' : ''
            }`}
            onClick={() => {
              setSelectedNavigation('store-location');
            }}
          >
            <div className="icon">
              <FaMapMarkerAlt />
            </div>
            <span>Location</span>
          </div>

          <div
            className={`menu ${
              selectedNavigation == 'store-legal' ? 'active-nav' : ''
            }`}
            onClick={() => {
              setSelectedNavigation('store-legal');
            }}
          >
            <div className="icon">
              <FaRegBuilding />
            </div>
            <span>Legal</span>
          </div>
        </div>
      </div>
    );
  } else if (screenWidth < 576 && selectedNavigation) {
    return <div style={{ display: 'none' }}></div>;
  } else {
    return (
      <div
        style={{
          width: screenWidth > 576 ? '26%' : '100%',
          borderRight: screenWidth > 576 ? '0.5px solid #ddd' : 'none',
          marginLeft: screenWidth < 576 ? '1rem' : '0',
          marginRight: screenWidth < 576 ? '1rem' : '0',
        }}
        className="navigation"
      >
        <div>
          <div>
            User
            <hr />
          </div>
          <div
            className={`menu ${
              selectedNavigation == 'user-profile' ? 'active-nav ' : ''
            }`}
            style={{
              display: 'flex',
              alignItems: 'center',
              flexBasis: '100%',
              fontSize: 'smaller',
            }}
            onClick={() => setSelectedNavigation('user-profile')}
          >
            <div
              className={`icon `}
              style={{ width: '28px', height: '28px', fontSize: '1rem' }}
            >
              <FaUserCircle />
            </div>
            <span style={{ fontSize: '16px' }}>User Profile</span>
          </div>
        </div>
        <div
          onClick={() => {
            setSelectedNavigation('store-profile');
          }}
        >
          <div>
            Store
            <hr />
          </div>
        </div>

        <div className="submenu">
          <div
            className={`menu ${
              selectedNavigation == 'store-profile' ? 'active-nav' : ''
            }`}
            onClick={() => {
              setSelectedNavigation('store-profile');
            }}
          >
            <div className="icon">
              <FaStore />
            </div>
            <span>Store Profile</span>
          </div>

          <div
            className={`menu ${
              selectedNavigation == 'store-public-profile' ? 'active-nav' : ''
            }`}
            onClick={() => {
              setSelectedNavigation('store-public-profile');
            }}
          >
            <div className="icon">
              <FaStore />
            </div>
            <span>Store Public Profile</span>
          </div>

          <div
            className={`menu ${
              selectedNavigation == 'store-contact' ? 'active-nav' : ''
            }`}
            onClick={() => {
              setSelectedNavigation('store-contact');
            }}
          >
            <div className="icon">
              <FaEnvelope />
            </div>
            <span>Contact</span>
          </div>

          <div
            className={`menu ${
              selectedNavigation == 'store-location' ? 'active-nav' : ''
            }`}
            onClick={() => {
              setSelectedNavigation('store-location');
            }}
          >
            <div className="icon">
              <FaMapMarkerAlt />
            </div>
            <span>Location</span>
          </div>

          <div
            className={`menu ${
              selectedNavigation == 'store-legal' ? 'active-nav' : ''
            }`}
            onClick={() => {
              setSelectedNavigation('store-legal');
            }}
          >
            <div className="icon">
              <FaRegBuilding />
            </div>
            <span>Legal</span>
          </div>
        </div>
      </div>
    );
  }
}
