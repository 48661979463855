import { RiFilter2Fill } from 'react-icons/ri';
import './styles/productFilter.scss';
import { GetRequest } from '../../../components/Request';
import { useEffect, useRef, useState } from 'react';
import { RxCross1 } from 'react-icons/rx';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../store';
import {
  addCategoryList,
  productCategorySelector,
} from '../../../features/category/Slice';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

export default function ProductFilter({
  showFilterOnLoad,
  clearAllFilter,
  //
  inputValueForSeller,
  fetchSeller,
  handleInputChangeForSeller,
  listsOfDistributors,
  sellerRef,
  handleActiveDistributor,
  sellerRefForCloseOnClickingOutside,
  setshowFilterOnLoad,
  isSellerOpen,
  activeDistributor,
  errorForFilter,
  setErrorForFilter,
  //for brand give below thing are responsible
  inputValueForBrand,
  setListsOfBrand,
  brandRefForCloseOnClickingOutside,
  setStopInfiniteScrollingForBrand,
  setIsBrandOpen,
  isBrandOpen,
  handleInputChangeForBrand,
  listsOfBrand,
  brandRef,
  handleActiveBrand,
  activeBrand,
  fetchBrand,
  //
  getProducts,
  // category
  listsOfCategory,
  isCategoryOpen,
  inputValueForCategory,
  categoryRef,
  categoryRefForCloseOnClickingOutside,
  fetchCategory,
  handleInputChangeForCategory,
  handleActiveCategory,
  activeCategory,
  //
  seeActiveStoreAvailableProduct,
  userWantToSeeOnlyStoreProduct,
  //
  userWantToSeeOnlySellerSellingInHisArea,
  setUserWantToSeeOnlySellerSellingInHisArea,
  // subcategory
  activeSubCategory,
  inputValueForSubCategory,
  handleInputChangeForSubCategory,
  listsOfSubCategory,
  showSubCategory,
  handleActiveSubCategory,
  subCategoryRef,
  handleClickOnSeeSubcategory,
}: //

any) {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const categoryListSelector = useSelector(productCategorySelector);
  const productFilterRef = useRef<any>(null);

  const handleClickOutside = (event: any) => {
    if (
      productFilterRef.current &&
      !productFilterRef.current.contains(event.target)
    ) {
      setshowFilterOnLoad(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  async function fetchCategoryAndStoreInAsyncStore() {
    try {
      const data = await localStorage.getItem('category');
      if (data) {
        dispatch(addCategoryList(JSON.parse(data)));
      } else {
        let categoryList: Record<string, any>[] = [];
        let page = 1;
        while (page) {
          const response = await GetRequest(
            'category?type=Product&limit=50&page=' + page
          );
          categoryList = [...categoryList, ...response.data];
          if (response.data.length < 50) {
            page = 0;
          } else page++;
        }
        await localStorage.setItem('category', JSON.stringify(categoryList));
      }
    } catch (error: any) {
      // showMessage({
      //   message: JSON.stringify(error),
      //   type: 'danger',
      // });
    }
  }

  // useEffect(() => {
  //   fetchCategoryAndStoreInAsyncStore();
  //   console.log(localStorage.getItem('category'), 'this is category');
  // }, []);

  if (showFilterOnLoad) {
    return (
      <div className="cnt-of-product-filter">
        <form action="#" className="filter-form" ref={productFilterRef}>
          <div className="heading">
            <h2>
              Filters <RiFilter2Fill />
            </h2>
            <button
              className="cross-icon"
              onClick={() => setshowFilterOnLoad(false)}
            >
              <RxCross1 />
            </button>
          </div>
          <p onClick={clearAllFilter} className="clear-all-btn">
            Clear All Filters
          </p>
          <div style={{ margin: '1.2rem 0' }}>
            <div
              style={{
                margin: '0.7rem 0',
              }}
            >
              <div className="autocomplete-container">
                <label htmlFor="#" className="input-label">
                  Brand
                </label>
                <div
                  style={{
                    position: 'relative',
                    width: '100%',
                  }}
                  onClick={fetchBrand}
                >
                  <input
                    type="text"
                    placeholder="Choose Brand"
                    className="autocomplete-input"
                    value={inputValueForBrand}
                    onChange={handleInputChangeForBrand}
                  />
                  <MdKeyboardArrowDown className="arrow-down-icon" />
                </div>

                {isBrandOpen && listsOfBrand.length > 0 ? (
                  <ul
                    className="suggestions-list"
                    style={{ overflowY: 'scroll' }}
                    ref={brandRefForCloseOnClickingOutside}
                  >
                    {listsOfBrand.length > 0 &&
                      listsOfBrand.map((brand: any, index: number) => (
                        <li
                          key={brand.name}
                          onClick={() => {
                            handleActiveBrand(brand);
                          }}
                          className="suggestion-item"
                        >
                          {brand.name}
                        </li>
                      ))}
                    {listsOfBrand.length > 0 && (
                      <div ref={brandRef} style={{ padding: '10px' }}></div>
                    )}
                  </ul>
                ) : (
                  ''
                )}
              </div>

              <div
                style={{
                  marginBottom: '0.8rem',
                }}
              >
                <input
                  value={userWantToSeeOnlySellerSellingInHisArea}
                  checked={userWantToSeeOnlySellerSellingInHisArea}
                  onChange={() =>
                    setUserWantToSeeOnlySellerSellingInHisArea(
                      !userWantToSeeOnlySellerSellingInHisArea
                    )
                  }
                  type="checkbox"
                  style={{
                    marginRight: '0.5rem',
                    display: 'inline-block',
                  }}
                  id="userWantToSeeOnlySellerSellingInHisArea"
                />
                <label htmlFor="userWantToSeeOnlySellerSellingInHisArea">
                  See only sellers selling in your area
                </label>
              </div>

              <div className="autocomplete-container">
                <label htmlFor="#" className="input-label">
                  Seller <span style={{ color: 'red' }}>*</span>
                </label>
                <div
                  style={{
                    position: 'relative',
                    width: '100%',
                  }}
                  onClick={fetchSeller}
                >
                  {' '}
                  <input
                    type="text"
                    placeholder="Choose Seller"
                    className="autocomplete-input"
                    value={inputValueForSeller}
                    onChange={handleInputChangeForSeller}
                  />
                  <MdKeyboardArrowDown className="arrow-down-icon" />
                </div>

                {isSellerOpen && listsOfDistributors.length > 0 ? (
                  <ul
                    className="suggestions-list"
                    style={{ overflowY: 'scroll' }}
                    ref={sellerRefForCloseOnClickingOutside}
                  >
                    {listsOfDistributors.length > 0 &&
                      listsOfDistributors.map((seller: any, index: number) => (
                        <li
                          key={index}
                          onClick={() => handleActiveDistributor(seller)}
                          className="suggestion-item"
                        >
                          {seller.storeName}
                        </li>
                      ))}

                    {listsOfDistributors.length > 0 && (
                      <div ref={sellerRef} style={{ padding: '10px' }}></div>
                    )}
                  </ul>
                ) : (
                  ''
                )}
              </div>

              {activeDistributor.storeId && (
                <div
                  style={{
                    marginBottom: '0.8rem',
                  }}
                >
                  <input
                    type="checkbox"
                    checked={userWantToSeeOnlyStoreProduct}
                    value={userWantToSeeOnlyStoreProduct}
                    onChange={seeActiveStoreAvailableProduct}
                    style={{
                      marginRight: '0.5rem',
                      display: 'inline-block',
                    }}
                    id="activeStoreAvailableProduct"
                  />
                  <label htmlFor="activeStoreAvailableProduct">
                    Only products which are available at the{' '}
                    {activeDistributor.storeName} store
                  </label>
                </div>
              )}

              <div className="autocomplete-container">
                <label htmlFor="#" className="input-label">
                  Category
                </label>
                <div
                  style={{
                    position: 'relative',
                    width: '100%',
                  }}
                  onClick={fetchCategory}
                >
                  <input
                    type="text"
                    placeholder="Choose Category"
                    className="autocomplete-input"
                    value={inputValueForCategory}
                    onChange={handleInputChangeForCategory}
                  />
                  <MdKeyboardArrowDown className="arrow-down-icon" />
                </div>

                {isCategoryOpen && listsOfCategory?.length > 0 ? (
                  <ul
                    className="suggestions-list"
                    style={{ overflowY: 'scroll' }}
                    ref={categoryRefForCloseOnClickingOutside}
                  >
                    {listsOfCategory?.length > 0 &&
                      listsOfCategory?.map((data: any, index: number) => (
                        <li
                          key={index}
                          onClick={() => handleActiveCategory(data)}
                          className="suggestion-item"
                        >
                          {data.name}
                        </li>
                      ))}

                    {listsOfCategory?.length > 0 && (
                      <div ref={categoryRef} style={{ padding: '10px' }}></div>
                    )}
                  </ul>
                ) : (
                  ''
                )}
              </div>

              {activeCategory?.name && listsOfSubCategory.length > 0 && (
                <div className="autocomplete-container">
                  <label htmlFor="#" className="input-label">
                    Sub Category
                  </label>
                  <div
                    style={{
                      position: 'relative',
                      width: '100%',
                    }}
                    onClick={handleClickOnSeeSubcategory}
                  >
                    <input
                      type="text"
                      placeholder="Choose Seller"
                      className="autocomplete-input"
                      value={activeSubCategory.name}
                      onChange={handleInputChangeForSubCategory}
                    />
                    <MdKeyboardArrowDown className="arrow-down-icon" />
                  </div>
                  {showSubCategory && listsOfSubCategory.length > 0 ? (
                    <ul
                      className="suggestions-list"
                      style={{ overflowY: 'scroll' }}
                      ref={subCategoryRef}
                    >
                      {listsOfSubCategory.map((data: any, index: number) => (
                        <li
                          key={index}
                          onClick={() => handleActiveSubCategory(data)}
                          className="suggestion-item"
                        >
                          {data.name}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    ''
                  )}
                </div>
              )}
            </div>
          </div>

          {errorForFilter && <p className="error">{errorForFilter}</p>}

          <div className="apply-filter-btn-cnt">
            <button
              onClick={async (e: any) => {
                e.preventDefault();

                if (activeDistributor.storeId) {
                  await getProducts();
                  let url = `/product?store_name=${activeDistributor.storeName}&store_id=${activeDistributor.storeId}`;
                  if (activeBrand.id) {
                    url += `&brand_name=${activeBrand.name}&brand_id=${activeBrand.id}`;
                  }

                  if (userWantToSeeOnlySellerSellingInHisArea) {
                    url += `&only_see_seller_in_your_area_=${userWantToSeeOnlySellerSellingInHisArea}`;
                  }

                  if (userWantToSeeOnlyStoreProduct) {
                    url += `&only_store_product=${userWantToSeeOnlyStoreProduct}`;
                  }

                  if (activeSubCategory.id) {
                    url += `&subcategory_name=${activeSubCategory.name}&subcategory_id=${activeSubCategory.id}`;
                  }

                  if (activeCategory.id) {
                    url += `&category_name=${activeCategory.name}&category_id=${activeCategory.id}`;
                  }

                  navigate(url);

                  // navigate(
                  //   `/product?brand_name=${activeBrand.name}&brand_id=${activeBrand.id}&store_name=${activeDistributor.storeName}&store_id=${activeDistributor.storeId}&only_see_seller_in_your_area_=${userWantToSeeOnlySellerSellingInHisArea}&category_name=${activeCategory.name}&category_id=${activeCategory.id}&subcategory_name=${activeSubCategory.name}&subcategory_id=${activeSubCategory.id}&only_store_product=${userWantToSeeOnlyStoreProduct}`
                  // );

                  setshowFilterOnLoad(false);
                } else {
                  setErrorForFilter('Please Select one seller');
                }
              }}
              className="apply-filter-btn"
            >
              Apply filters
            </button>
          </div>
        </form>
      </div>
    );
  } else {
    return <br />;
  }
}
