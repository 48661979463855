import { useEffect, useRef, useState } from 'react';
import './select.scss';
import { FiChevronDown } from 'react-icons/fi';

export default function InputText({
  label,
  value,
  handleClickOnOption,
  option,
}: any) {
  const [labelOnTop, setLabelOnTop] = useState<Boolean>(false);
  const [showOption, setShowOption] = useState<any>(false);
  const dropDownRef = useRef<any>(null);

  const handleClickOutside = (event: any) => {
    if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
      setShowOption(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="custom-select-cnt">
      <label
        className={labelOnTop ? 'label label-should-move-upward' : 'label'}
        htmlFor={label}
        onClick={() => {
          setShowOption(true);
          setLabelOnTop(true);
        }}
      >
        {label}
        <span style={{ display: labelOnTop ? 'none' : 'inline-block' }}>
          <FiChevronDown className="arrow" />
        </span>
      </label>

      <div
        className="custom-dropdown"
        onClick={() => {
          setShowOption(true);
          setLabelOnTop(true);
        }}
      >
        <div className="selected-option">
          {value ? value : 'Choose one'}
          {value ? <FiChevronDown className="arrow" /> : ''}
        </div>
        {showOption ? (
          <div className="options" ref={dropDownRef}>
            {option.map((data: any) => {
              return (
                <div
                  key={data}
                  className="option"
                  onClick={async () => {
                    await handleClickOnOption(data);
                    await setShowOption(false);
                  }}
                >
                  {data}
                </div>
              );
            })}
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
}

// <div className="input-text-cnt">
//   <label
//     className={labelOnTop ? 'label label-should-move-upward' : 'label'}
//     htmlFor={label}
//   >
//     {label == 'Name' ? 'Name*' : label}
//   </label>

//   <input
//     onFocus={() => setLabelOnTop(true)}
//     onBlur={() => {
//       if (value == '') {
//         setLabelOnTop(false);
//       } else {
//         setLabelOnTop(true);
//       }
//     }}
//     type="text"
//     id={label}
//     name={name}
//     value={value}
//     onChange={handleChange}
//     placeholder={labelOnTop && placeholder}
//     className="input-text"
//   />
// </div>
