import {
  FaFacebook,
  FaLinkedin,
  FaProductHunt,
  FaRoute,
  FaRupeeSign,
  FaStore,
  FaTwitter,
} from 'react-icons/fa';
import './home.scss';
import { Link } from 'react-router-dom';
import { IoIosArrowRoundForward, IoLogoInstagram } from 'react-icons/io';
import { useEffect, useState } from 'react';
import { GetRequest } from '../../components/Request';
import Loader from '../../layouts/Loader/Loader';

export default function Home(props: any) {
  const [sellerList, setSellerList] = useState<any>([]);

  useEffect(() => {
    GetRequest(`store?buyer=true`)
      .then((res) => {
        if (res.data.statusCode === 200) {
          setSellerList(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err, 'while fetching sellers');
      });
  }, []);

  return (
    <>
      <section className="home">
        <div className="tagline-n-banner common-container">
          <div className="cnt-of-tagline-n-small-description">
            <h1 className="tagline">Elevate Your Buying Experience.</h1>
            <p className="small-description">
              Connect seamlessly with distributors on Modulus Buy. Say goodbye
              to market complexities, secure orders, and simplify your journey.
            </p>
            <p className="about-app">
              <Link
                target="_blank"
                to="https://play.google.com/store/apps/details?id=com.modulusbuy.android"
              >
                <img
                  className="playstore-img"
                  src="/playstore.png"
                  alt="playstore"
                />
              </Link>

              <Link
                target="_blank"
                to="https://apps.apple.com/in/app/buy-modulus-buy/id6474479041"
              >
                <img
                  className="playstore-img"
                  src="/appstore.png"
                  alt="appstore"
                />
              </Link>
            </p>
          </div>

          <img
            src="/images/banner-1.png"
            className="sell-banner"
            alt="banner"
          />
        </div>

        <section className="speciality-section-cnt">
          <section className="speciality-section">
            <div className="each-speciality">
              <img
                src="/images/great-offers.png"
                alt="banner"
                className="special-icon"
              />

              <h5 className="great">Great Deals</h5>
            </div>
            <div className="each-speciality active">
              <img
                src="/images/delivery-on-time.png"
                alt="banner"
                className="special-icon"
              />

              <h5 className="great">Delivery On Time</h5>
            </div>
            <div className="each-speciality">
              <img
                src="/images/brand.png"
                alt="banner"
                className="special-icon"
              />
              <h5 className="great">Multiple Brands</h5>
            </div>
            <div className="each-speciality">
              <img
                src="/images/seller.png"
                alt="banner"
                className="special-icon"
              />
              <h5 className="great">Sellers Who Cares For You</h5>
            </div>
          </section>
        </section>

        {/* why we are best and what does we provide */}

        <div className="common-container more-info-section">
          <div className="banner-2">
            <img
              className="width-full"
              src="/images/banner-2.png"
              alt="ABOUT"
            />
          </div>
          <div className="large-description">
            <p className="small-description">
              Discover distributors effortlessly with Modulus Buy. Our platform
              ensures a seamless process, making it easy to connect with
              alternative sellers nearby. Say goodbye to market complexities;
              with just a few clicks, secure your orders, make valuable
              connections, and simplify your market journey with Modulus Buy.{' '}
            </p>
            <Link className="shop-now-btn" to="/login">
              Shop Now
            </Link>
          </div>
        </div>
      </section>

      {/* our seller */}
      <section className="seller-list">
        <div className="seller-list-container">
          {sellerList.length > 0 ? (
            sellerList.map((data: any) => {
              return (
                <article className="flex-49">
                  <figure className="figure-1 font-0">
                    <img
                      className="width-full"
                      src={
                        data.profileImage
                          ? data.profileImage
                          : 'images/seller.png'
                      }
                      style={{
                        objectFit: !data.profileImage ? 'contain' : 'cover',
                      }}
                      alt="values-1"
                    />
                  </figure>
                  <div className="small-article">
                    <h4 className="text-center">{data.name}</h4>
                    <p>
                      Ladies And Gentlemans, We Provide Variety Of Products.
                    </p>
                    <Link to="#">
                      Buy Now
                      <span>
                        <IoIosArrowRoundForward className="arrow-right" />
                      </span>
                    </Link>
                  </div>
                </article>
              );
            })
          ) : (
            <Loader />
          )}
        </div>
      </section>

      {/* footer */}
      <section className="padding background footer-links">
        <article className="container flex wrap justify-around sm-column footer">
          <div className="flexor flex-23 sm-flex-50 ">
            <h6>|17| Modulus Seventeen</h6>
            <address>
              Choori Kasaragod, Kerala 671124
              <br />
              Kerala ,India
            </address>
            <p>
              <span>Phone:</span> +91 7356001171
            </p>
            <p>
              <span>Email:</span> support@modulusbuy.com
            </p>
          </div>
          <nav className="Useful-links flex-20 sm-flex-50">
            <h4>Useful Links </h4>
            <ul>
              <li>
                {' '}
                <span>
                  <i className="fas fa-angle-right"></i>
                </span>{' '}
                <Link to="/store/create"> Add Store</Link>
              </li>
              <li>
                {' '}
                <span>
                  <i className="fas fa-angle-right"></i>
                </span>{' '}
                <Link to="https://sell.modulusseventeen.com/">|Sell|</Link>
              </li>

              <li>
                {' '}
                <span>
                  <i className="fas fa-angle-right"></i>
                </span>{' '}
                <Link to="/privacy"> Privacy Policy</Link>
              </li>
            </ul>
          </nav>
        </article>

        <footer id="contact" className="footer">
          <div className="container footer-social-links sm-center flex justify-between item-center">
            <div>
              <small>
                &copy; Copyright <span>|17|</span>. All Rights Reserved
              </small>{' '}
              <br />
            </div>
            <nav className="footer-nav">
              <ul className="flex">
                <li
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Link to="https://twitter.com/moduluseventeen">
                    <FaTwitter
                      style={{
                        display: 'block',
                      }}
                    />
                  </Link>{' '}
                </li>
                <li>
                  <Link to="https://www.facebook.com/modulusseventeen">
                    <FaFacebook />
                  </Link>{' '}
                </li>
                <li>
                  <Link to="https://www.instagram.com/modulusseventeen/?hl=en">
                    <IoLogoInstagram />
                  </Link>{' '}
                </li>
                <li>
                  <Link to="https://www.linkedin.com/company/modulus-seventeen/about/">
                    <FaLinkedin />
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
          <div className="top-btn">
            <Link to="#">
              <i className="fas fa-angle-up"></i>
            </Link>
          </div>
        </footer>
      </section>
    </>
  );
}
