import { styled } from "@mui/system";
import { FaSleigh, FaUserCircle } from "react-icons/fa";
import { FiChevronDown, FiChevronUp, FiEdit } from "react-icons/fi";
// import './order.scss';
import { useEffect, useRef, useState } from "react";
import { AiTwotoneDelete } from "react-icons/ai";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { RiFilter2Fill } from "react-icons/ri";
import {
  DeleteRequest,
  GetRequest,
  PatchRequest,
} from "../../components/Request";
import { LazyLoadImage } from "react-lazy-load-image-component";
import dayjs from "dayjs";

import Bill from "./OrderItems";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import OrderItemsUIForWeb from "./OrderItems";
import { Link, useNavigate, useParams } from "react-router-dom";
import ConfirmationBox from "./Confirmation";
import SellerRoute from "./SellerRoute";

const distributors = ["shubham", "rahul"];
const brands = ["parle", "neslte"];

export default function Order() {
  let { orderStatus } = useParams();
  let navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState(orderStatus);
  const [showFilters, setShowFilters] = useState(false);
  const [allOrder, setAllOrder] = useState<any>([]);
  const [selectedDistributor, setSelectedDistributor] = useState("");
  const [selectedFromDate, setSelectedFromDate] = useState(null);
  const [selectedToDate, setSelectedToDate] = useState(null);
  const [showPlaceOrder, setShowPlaceOrder] = useState<any>({});
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [orderIdConvertIntoPending, setOrderIdConvertIntoPending] =
    useState("");
  const [sellerList, setSellerList] = useState<any>([]);
  const [activeSellerRoute, setActiveSellerRoute] = useState<any>([]);
  const [showSellerRoute, setShowSellerRoute] = useState(false);
  // seller
  const [listsOfDistributors, setListsOfDistributors] = useState<any>([]);
  const [activeDistributor, setActiveDistributor] = useState<any>({});
  const sellerRef = useRef<any>(null);
  const [isSellerOpen, setIsSellerOpen] = useState(false);
  const sellerRefForCloseOnClickingOutside = useRef<any>(null);
  const [inputValueForSeller, setInputValueForSeller] = useState("");
  const [pageNumberForSeller, setPageNumberForSeller] = useState(1);
  const [stopInfiniteScrolling, setStopInfiniteScrolling] = useState(false);

  const handleDistributorChange = (event: any) => {
    let sellerStoreId: any;
    let value = event.target.value;

    if (value) {
      allOrder.forEach((order: any) => {
        if (order.sellerStore.name == value) {
          sellerStoreId = order.sellerStore._id;
        }
      });
      setSelectedDistributor(sellerStoreId);
    }
  };

  const handleFromDateChange = (date: any) => {
    const formattedDate: any = dayjs(date.$d).format(
      "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
    );

    setSelectedFromDate(formattedDate);
  };

  const handleToDateChange = (date: any) => {
    const formattedDate: any = dayjs(date.$d).format(
      "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
    );

    setSelectedToDate(formattedDate);
  };

  const applyFilters = async () => {
    let url = `order?status=${selectedOption}`;

    if (selectedFromDate) {
      url = url + `&from-date=${selectedFromDate}`;
    }
    if (selectedToDate) {
      url = url + `&to-date=${selectedToDate}`;
    }

    if (activeDistributor) {
      url = url + `&seller-store=${activeDistributor.storeId}`;
    }

    await GetRequest(url)
      .then((res) => {
        if (res.status == 200) {
          let response = res.data.data.map((obj: any) => {
            return {
              ...obj,
              showItem: false,
              totalAmount: obj.totalAmount.toFixed(2),
              itemList: [],
            };
          });
          setAllOrder(response);
        }
      })
      .catch((err) => {
        console.log(err, "getting err");
      });
  };

  useEffect(() => {
    if (orderStatus == "pending") {
      setSelectedOption("Pending");
    } else if (orderStatus == "ordered") {
      setSelectedOption("Ordered");
    }
  }, []);

  useEffect(() => {
    GetRequest(`order?status=${selectedOption}`)
      .then((res) => {
        if (res.status == 200) {
          let response = res.data.data.map((obj: any) => {
            return {
              ...obj,
              showItem: false,
              totalAmount: obj.totalAmount.toFixed(2),
              itemList: [],
            };
          });
          setAllOrder(response);
        }
      })
      .catch((err) => {
        console.log(err, "getting err");
      });
  }, [selectedOption]);

  function formatDateToDDMMMYY(inputDate: any) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const date = new Date(inputDate);

    const day = String(date.getUTCDate()).padStart(2, "0");
    const monthAbbreviation = months[date.getUTCMonth()];
    const year = String(date.getUTCFullYear()).slice(-4);

    const formattedDate = `${day}-${monthAbbreviation}-${year}`;

    return formattedDate;
  }

  const lastPrice = (product: any, selectedOption: any) => {
    // if (selectedOption === 'piece') {
    if (product.sellerProductDetail.pricePerUnit[selectedOption]?.unique) {
      return product.sellerProductDetail.pricePerUnit[selectedOption]?.price;
    } else {
      let lastPrice;
      let quantity;
      if (selectedOption == "piece") {
        quantity = product.quantity.pieceWise;
      }

      if (selectedOption == "box") {
        quantity = product.quantity.boxWise;
      }

      if (selectedOption == "bundle") {
        quantity = product.quantity.bundleWise;
      }

      const quantityWisePriceList =
        product.sellerProductDetail?.pricePerUnit[selectedOption]
          ?.quantityWisePriceList;

      if (quantityWisePriceList) {
        for (const priceObj of quantityWisePriceList) {
          const { price, moreThanOrEqual, lessThanOrEqual } = priceObj;

          if (quantity >= moreThanOrEqual && quantity <= lessThanOrEqual) {
            lastPrice = price;
          }
        }
      }

      return lastPrice;
    }
    // }
    // else if (selectedOption === 'bundle') {
    //   if (product.sellerProductDetail.pricePerUnit?.bundle?.unique) {
    //     return product.sellerProductDetail.pricePerUnit.bundle.price;
    //   } else {
    //     let lastPrice;
    //     const quantity = product.quantity.bundleWise;

    //     const quantityWisePriceList =
    //       product.sellerProductDetail.pricePerUnit?.bundle
    //         ?.quantityWisePriceList;

    //     for (const priceObj of quantityWisePriceList) {
    //       const { price, moreThanOrEqual, lessThanOrEqual } = priceObj;

    //       if (quantity >= moreThanOrEqual && quantity <= lessThanOrEqual) {
    //         lastPrice = price;
    //       }
    //     }

    //     return lastPrice;
    //   }
    // } else if (selectedOption === 'box') {
    //   if (product.sellerProductDetail.pricePerUnit.box.unique) {
    //     return product.sellerProductDetail.pricePerUnit.box.price;
    //   } else {
    //     let lastPrice;
    //     const quantity = product.quantity.boxWise;

    //     const quantityWisePriceList =
    //       product.sellerProductDetail.pricePerUnit.box.quantityWisePriceList;

    //     for (const priceObj of quantityWisePriceList) {
    //       const { price, moreThanOrEqual, lessThanOrEqual } = priceObj;

    //       if (quantity >= moreThanOrEqual && quantity <= lessThanOrEqual) {
    //         lastPrice = price;
    //       }
    //     }

    //     return lastPrice;
    //   }
    // }
  };

  const handleSeeProduct = async (
    orderId: string,
    showOrderItem: boolean,
    index: number
  ) => {
    if (!showOrderItem && allOrder[index].itemList?.length == 0) {
      await GetRequest(`order/${orderId}?seller-product-detail=true`)
        .then((res) => {
          if (res.data.statusCode == 200) {
            let obj = res.data.data;
            let allOrderCopy: any = [...allOrder];

            obj.itemList = obj.itemList.map((p: any) => {
              let selectedOption;
              let quantity;
              if (p.quantity.boxWise) {
                selectedOption = "box";
                quantity = p.quantity.boxWise;
              } else if (p.quantity.bundleWise) {
                selectedOption = "bundle";
                quantity = p.quantity.bundleWise;
              } else {
                selectedOption = "piece";
                quantity = p.quantity.pieceWise;
              }
              return {
                ...p,
                totalPrice: p.totalPrice.toFixed(2),

                product: {
                  ...p.product,
                  selectedOption,
                  quantity,
                  lastPriceOnWhichTotalPriceGettingCalculated: lastPrice(
                    p,
                    selectedOption
                  ),
                },
              };
            });
            obj.totalAmount = obj.totalAmount.toFixed(2);
            allOrderCopy = allOrderCopy.map((data: any) => {
              if (data._id == orderId) {
                return {
                  ...data,
                  itemList: obj.itemList,
                  showItem: true,
                };
              } else {
                return data;
              }
            });
            console.log(allOrderCopy[index], "check allorder");
            setAllOrder(allOrderCopy);
          }
        })
        .catch((err) => {
          console.log(err, "errrr  from fetch pending orders");
          // if (err.response.data.statusCode >= 400) {
          //   setMessage(err.response.data.message);
          //   setType('error');
          //   setShowIndicator(true);
          //   setTimeout(() => {
          //     setShowIndicator(false);
          //   }, 3000);
          // }
        });
    } else if (!showOrderItem && allOrder[index].itemList.length > 0) {
      let allOrderCopy: any = [...allOrder];
      allOrderCopy = allOrderCopy.map((data: any) => {
        if (data._id == orderId) {
          return {
            ...data,
            showItem: true,
          };
        } else {
          return data;
        }
      });
      console.log(allOrderCopy[index], "check allorder");
      setAllOrder(allOrderCopy);
    } else {
      let allOrderCopy: any = [...allOrder];
      console.log(allOrderCopy[index], "check allorder");
      allOrderCopy[index].showItem = false;
      return setAllOrder(allOrderCopy);
    }
  };

  const handleClickOnPlaceOrder = async (orderId: string, index: number) => {
    await GetRequest(
      `order/${orderId}?seller-product-detail=true&seller-store-detail=true`
    )
      .then((res) => {
        if (res.data.statusCode == 200) {
          let obj = res.data.data;
          obj.itemList = obj.itemList.map((p: any) => {
            let selectedOption;
            let quantity;
            if (p.quantity.boxWise) {
              selectedOption = "box";
              quantity = p.quantity.boxWise;
            } else if (p.quantity.bundleWise) {
              selectedOption = "bundle";
              quantity = p.quantity.bundleWise;
            } else {
              selectedOption = "piece";
              quantity = p.quantity.pieceWise;
            }
            return {
              ...p,
              totalPrice: p.totalPrice.toFixed(2),
              product: {
                ...p.product,
                selectedOption,
                quantity,
                lastPriceOnWhichTotalPriceGettingCalculated: lastPrice(
                  p,
                  selectedOption
                ),
              },
            };
          });
          setShowPlaceOrder(obj);
        }
      })
      .catch((err) => {
        console.log(err, "errrr  from fetch pending orders");
        // if (err.response.data.statusCode >= 400) {
        //   setMessage(err.response.data.message);
        //   setType('error');
        //   setShowIndicator(true);
        //   setTimeout(() => {
        //     setShowIndicator(false);
        //   }, 3000);
        // }
      });
  };

  const removeProductFromBag = async (
    indexOfItem: number,
    indexOfStore: number
  ) => {
    const orderIdToRemove = allOrder[indexOfStore].itemList[indexOfItem].orderId
      ? allOrder[indexOfStore].itemList[indexOfItem].orderId
      : allOrder[indexOfStore].itemList[indexOfItem].product.pendingOrderDetail
          .orderId;
    const productIdToRemove =
      allOrder[indexOfStore].itemList[indexOfItem].product._id;

    await DeleteRequest(`order/${orderIdToRemove}/product/${productIdToRemove}`)
      .then(async (res) => {
        if (res.data.statusCode === 200) {
          let newAllOrder = [...allOrder];
          newAllOrder[indexOfStore].totalAmount = (
            newAllOrder[indexOfStore].totalAmount -
            newAllOrder[indexOfStore].itemList[indexOfItem].totalPrice
          ).toFixed(2);
          newAllOrder[indexOfStore].totalItem =
            newAllOrder[indexOfStore].totalItem - 1;
          newAllOrder[indexOfStore].itemList = newAllOrder[
            indexOfStore
          ].itemList.filter(
            (p: any) =>
              p.product._id !=
              allOrder[indexOfStore].itemList[indexOfItem].product._id
          );

          if (newAllOrder[indexOfStore].itemList.length == 0) {
            newAllOrder = newAllOrder.filter((order: any) => {
              return order._id !== orderIdToRemove;
            });
          }

          setAllOrder(newAllOrder);
        }
      })
      .catch((err: any) => {
        console.log(err, "err in deleting from the bag");
        // if (err.response.data.statusCode >= 400) {
        //   setMessage(err.response.data.message);
        //   setType('error');
        //   setShowIndicator(true);
        //   setTimeout(() => {
        //     setShowIndicator(false);
        //   }, 3000);
        // }
      });
  };

  const lastPriceOnWhichTotalPriceGettingCalculated = (
    data: any,
    selectedOption: any
  ) => {
    if (selectedOption === "piece") {
      if (data.sellerProductDetail.pricePerUnit.piece.unique) {
        return data.sellerProductDetail.pricePerUnit.piece.price;
      } else {
        let lastPrice;
        const quantity = data.product.quantity;

        const quantityWisePriceList =
          data.sellerProductDetail.pricePerUnit.piece.quantityWisePriceList;

        for (const priceObj of quantityWisePriceList) {
          const { price, moreThanOrEqual, lessThanOrEqual } = priceObj;

          if (quantity >= moreThanOrEqual && quantity <= lessThanOrEqual) {
            lastPrice = price;
          }
        }

        return lastPrice;
      }
    } else if (selectedOption === "bundle") {
      if (data.sellerProductDetail.pricePerUnit.bundle.unique) {
        return data.sellerProductDetail.pricePerUnit.bundle.price;
      } else {
        let lastPrice;
        const quantity =
          data.product.quantity == undefined
            ? data.sellerProductDetail.minimumQuantity.bundle.quantity
            : data.product.quantity;

        const quantityWisePriceList =
          data.sellerProductDetail.pricePerUnit.bundle.quantityWisePriceList;

        for (const priceObj of quantityWisePriceList) {
          const { price, moreThanOrEqual, lessThanOrEqual } = priceObj;

          if (quantity >= moreThanOrEqual && quantity <= lessThanOrEqual) {
            lastPrice = price;
          }
        }

        return lastPrice;
      }
    } else {
      if (data.sellerProductDetail.pricePerUnit.box.unique) {
        return data.sellerProductDetail.pricePerUnit.box.price;
      } else {
        let lastPrice: any;
        const quantity =
          data.product.quantity == undefined
            ? data.sellerProductDetail.minimumQuantity.box.quantity
            : data.product.quantity;

        const quantityWisePriceList =
          data.sellerProductDetail.pricePerUnit.box.quantityWisePriceList;

        for (const priceObj of quantityWisePriceList) {
          const { price, moreThanOrEqual, lessThanOrEqual } = priceObj;

          if (quantity >= moreThanOrEqual && quantity <= lessThanOrEqual) {
            lastPrice = price;

            break;
          }
        }
        return lastPrice;
      }
    }
  };

  const handleIncrementInOrder = (
    data: any,
    index: number,
    indexOfStore: number
  ) => {
    let { selectedOption, _id } = data.product;
    if (selectedOption == "piece") {
      let { enable, number } =
        data.sellerProductDetail.minimumQuantity.piece.isMultiple;
      let allOrderCopy = [...allOrder];
      if (enable) {
        allOrderCopy[indexOfStore].itemList[index].product.quantity =
          allOrderCopy[indexOfStore].itemList[index].product.quantity + number;
        allOrderCopy[indexOfStore].itemList[
          index
        ].product.lastPriceOnWhichTotalPriceGettingCalculated =
          lastPriceOnWhichTotalPriceGettingCalculated(
            data,
            data.product.selectedOption
          );
      } else {
        allOrderCopy[indexOfStore].itemList[index].product.quantity =
          allOrderCopy[indexOfStore].itemList[index].product.quantity + 1;
        allOrderCopy[indexOfStore].itemList[
          index
        ].product.lastPriceOnWhichTotalPriceGettingCalculated =
          lastPriceOnWhichTotalPriceGettingCalculated(
            data,
            data.product.selectedOption
          );
      }
      return allOrderCopy;
      // changeSelectedOption(product, index);
    } else if (selectedOption == "bundle") {
      let { enable, number } =
        data.sellerProductDetail.minimumQuantity.bundle.isMultiple;
      let allOrderCopy = [...allOrder];
      if (enable) {
        allOrderCopy[indexOfStore].itemList[index].product.quantity =
          allOrderCopy[indexOfStore].itemList[index].product.quantity + number;
        allOrderCopy[indexOfStore].itemList[
          index
        ].product.lastPriceOnWhichTotalPriceGettingCalculated =
          lastPriceOnWhichTotalPriceGettingCalculated(
            data,
            data.product.selectedOption
          );
      } else {
        allOrderCopy[indexOfStore].itemList[index].product.quantity =
          allOrderCopy[indexOfStore].itemList[index].product.quantity + 1;
        allOrderCopy[indexOfStore].itemList[
          index
        ].product.lastPriceOnWhichTotalPriceGettingCalculated =
          lastPriceOnWhichTotalPriceGettingCalculated(
            data,
            data.product.selectedOption
          );
      }
      return allOrderCopy;
      // changeSelectedOption(product, index);
    } else {
      let { enable, number } =
        data.sellerProductDetail.minimumQuantity.box.isMultiple;
      let allOrderCopy = [...allOrder];
      if (enable) {
        allOrderCopy[indexOfStore].itemList[index].product.quantity =
          allOrderCopy[indexOfStore].itemList[index].product.quantity + number;
        allOrderCopy[indexOfStore].itemList[
          index
        ].product.lastPriceOnWhichTotalPriceGettingCalculated =
          lastPriceOnWhichTotalPriceGettingCalculated(
            data,
            data.product.selectedOption
          );
      } else {
        allOrderCopy[indexOfStore].itemList[index].product.quantity =
          allOrderCopy[indexOfStore].itemList[index].product.quantity + 1;
        allOrderCopy[indexOfStore].itemList[
          index
        ].product.lastPriceOnWhichTotalPriceGettingCalculated =
          lastPriceOnWhichTotalPriceGettingCalculated(
            data,
            data.product.selectedOption
          );
      }
      return allOrderCopy;
      // changeSelectedOption(product, index);
    }
  };

  const handleDecrementInOrder = (
    data: any,
    index: number,
    indexOfStore: number
  ) => {
    let { selectedOption, _id } = data.product;
    if (selectedOption == "piece") {
      let { enable, number } =
        data.sellerProductDetail.minimumQuantity.piece.isMultiple;
      let allOrderCopy = [...allOrder];
      if (enable) {
        allOrderCopy[indexOfStore].itemList[index].product.quantity =
          allOrderCopy[indexOfStore].itemList[index].product.quantity - number;
        allOrderCopy[indexOfStore].itemList[
          index
        ].product.lastPriceOnWhichTotalPriceGettingCalculated =
          lastPriceOnWhichTotalPriceGettingCalculated(
            data,
            data.product.selectedOption
          );
      } else {
        allOrderCopy[indexOfStore].itemList[index].product.quantity =
          allOrderCopy[indexOfStore].itemList[index].product.quantity - 1;
        allOrderCopy[indexOfStore].itemList[
          index
        ].product.lastPriceOnWhichTotalPriceGettingCalculated =
          lastPriceOnWhichTotalPriceGettingCalculated(
            data,
            data.product.selectedOption
          );
      }
      return allOrderCopy;
      // changeSelectedOption(product, index);
    } else if (selectedOption == "bundle") {
      let { enable, number } =
        data.sellerProductDetail.minimumQuantity.bundle.isMultiple;
      let allOrderCopy = [...allOrder];
      if (enable) {
        allOrderCopy[indexOfStore].itemList[index].product.quantity =
          allOrderCopy[indexOfStore].itemList[index].product.quantity - number;
        allOrderCopy[indexOfStore].itemList[
          index
        ].product.lastPriceOnWhichTotalPriceGettingCalculated =
          lastPriceOnWhichTotalPriceGettingCalculated(
            data,
            data.product.selectedOption
          );
      } else {
        allOrderCopy[indexOfStore].itemList[index].product.quantity =
          allOrderCopy[indexOfStore].itemList[index].product.quantity - 1;
        allOrderCopy[indexOfStore].itemList[
          index
        ].product.lastPriceOnWhichTotalPriceGettingCalculated =
          lastPriceOnWhichTotalPriceGettingCalculated(
            data,
            data.product.selectedOption
          );
      }
      return allOrderCopy;
      // changeSelectedOption(product, index);
    } else {
      let { enable, number } =
        data.sellerProductDetail.minimumQuantity.box.isMultiple;
      let allOrderCopy = [...allOrder];
      if (enable) {
        allOrderCopy[indexOfStore].itemList[index].product.quantity =
          allOrderCopy[indexOfStore].itemList[index].product.quantity - number;
        allOrderCopy[indexOfStore].itemList[
          index
        ].product.lastPriceOnWhichTotalPriceGettingCalculated =
          lastPriceOnWhichTotalPriceGettingCalculated(
            data,
            data.product.selectedOption
          );
      } else {
        allOrderCopy[indexOfStore].itemList[index].product.quantity =
          allOrderCopy[indexOfStore].itemList[index].product.quantity - 1;
        allOrderCopy[indexOfStore].itemList[
          index
        ].product.lastPriceOnWhichTotalPriceGettingCalculated =
          lastPriceOnWhichTotalPriceGettingCalculated(
            data,
            data.product.selectedOption
          );
      }
      return allOrderCopy;
      // changeSelectedOption(product, index);
    }
  };

  const giveQuantityInPieceForOrder = (
    data: any,
    quantity: any,
    changeIntoThisSelectedOption: any
  ) => {
    let info = data.product.info;

    if (changeIntoThisSelectedOption == "piece") {
      return quantity;
    } else if (changeIntoThisSelectedOption == "box") {
      return quantity * info.pieceInEachBox;
    } else {
      return quantity * info.pieceInEachBundle;
    }
  };

  const updateItemForQuantity = async (
    orderId: any,
    productId: any,
    product: any,
    quantityInPiece: any,
    indexOfItem: any,
    indexOfStore: number,
    data: Object
  ) => {
    await PatchRequest(`order/${orderId}/product/${productId}`, {
      quantityInPiece: quantityInPiece,
    })
      .then(async (res: any) => {
        if (res.data.statusCode == 200) {
          let { boxWise, bundleWise, pieceWise } = res.data.data.quantity;
          let allOrderCopy = [...allOrder];
          if (boxWise) {
            allOrderCopy[indexOfStore].itemList[indexOfItem].product.quantity =
              boxWise;
            allOrderCopy[indexOfStore].itemList[
              indexOfItem
            ].product.selectedOption = "box";
            allOrderCopy[indexOfStore].itemList[indexOfItem].totalPrice =
              res.data.data.totalPrice.toFixed(2);
            allOrderCopy[indexOfStore].itemList[
              indexOfItem
            ].product.lastPriceOnWhichTotalPriceGettingCalculated =
              lastPriceOnWhichTotalPriceGettingCalculated(data, "box");
          } else if (bundleWise) {
            allOrderCopy[indexOfStore].itemList[indexOfItem].product.quantity =
              bundleWise;
            allOrderCopy[indexOfStore].itemList[
              indexOfItem
            ].product.selectedOption = "bundle";
            allOrderCopy[indexOfStore].itemList[indexOfItem].totalPrice =
              res.data.data.totalPrice.toFixed(2);
            allOrderCopy[indexOfStore].itemList[
              indexOfItem
            ].product.lastPriceOnWhichTotalPriceGettingCalculated =
              lastPriceOnWhichTotalPriceGettingCalculated(data, "bundle");
          } else {
            allOrderCopy[indexOfStore].itemList[indexOfItem].product.quantity =
              pieceWise;
            allOrderCopy[indexOfStore].itemList[
              indexOfItem
            ].product.selectedOption = "piece";
            allOrderCopy[indexOfStore].itemList[indexOfItem].totalPrice =
              res.data.data.totalPrice.toFixed(2);
            allOrderCopy[indexOfStore].itemList[
              indexOfItem
            ].product.lastPriceOnWhichTotalPriceGettingCalculated =
              lastPriceOnWhichTotalPriceGettingCalculated(data, "piece");
          }
          // setting total amount for each order
          let totalAmount = 0;
          allOrderCopy[indexOfStore].itemList.forEach(
            (order: any, index: number) => {
              totalAmount += Number(order.totalPrice);
            }
          );
          allOrderCopy[indexOfStore].totalAmount = totalAmount.toFixed(2);
          allOrder[indexOfStore].totalTaxAmount =
            res.data.data.totalTaxPercentagePrice.map((tax: any) => {
              return {
                percentage: tax.percentage,
                type: tax.type,
                amount: tax.price,
              };
            });
          setAllOrder(allOrderCopy);
        }
      })
      .catch((err) => {
        console.log(err, "err in updating the product in order page");
        // if (err.response.data.statusCode >= 400) {
        //   setMessage(err.response.data.message);
        //   setType('error');
        //   setShowIndicator(true);
        //   setTimeout(() => {
        //     setShowIndicator(false);
        //   }, 3000);
        // }
      });
  };

  const showMinimumQuantityAccToSelectedOption = (data: any) => {
    if (data.product.selectedOption == "piece") {
      return data.sellerProductDetail.minimumQuantity.piece?.quantity;
    } else if (data.product.selectedOption == "bundle") {
      return data.sellerProductDetail.minimumQuantity.bundle?.quantity;
    } else {
      return data.sellerProductDetail.minimumQuantity.box?.quantity;
    }
  };

  const showNumberAccToSelectedOption = (data: any) => {
    if (data.product.selectedOption == "piece") {
      return data.sellerProductDetail.minimumQuantity.piece.isMultiple.number;
    } else if (data.product.selectedOption == "bundle") {
      return data.sellerProductDetail.minimumQuantity.bundle.isMultiple.number;
    } else {
      return data.sellerProductDetail.minimumQuantity.box.isMultiple.number;
    }
  };

  const handleChangeOnQuantity = (
    value: number,
    data: any,
    indexOfStore: number,
    indexOfItem: number
  ) => {
    let { _id } = data.product;
    let allOrderCopy = [...allOrder];
    allOrderCopy[indexOfStore].itemList = allOrderCopy[
      indexOfStore
    ].itemList.map((product: any) => {
      let { selectedOption } = product;
      if (product._id == _id) {
        if (value < showMinimumQuantityAccToSelectedOption(data)) {
          return {
            ...product,
            quantity: showMinimumQuantityAccToSelectedOption(data),
          };
        } else if (value > showMinimumQuantityAccToSelectedOption(data)) {
          if (selectedOption == "piece") {
            if (
              data.sellerProductDetail.minimumQuantity.piece.isMultiple.enable
            ) {
              let number =
                (value - showMinimumQuantityAccToSelectedOption(data)) %
                showNumberAccToSelectedOption(data);
              let numberToAddInInputValue =
                (value - showMinimumQuantityAccToSelectedOption(data)) /
                showNumberAccToSelectedOption(data);
              if (number != 0) {
                numberToAddInInputValue =
                  Math.floor(numberToAddInInputValue) + 1;
              }

              return {
                ...product,
                quantity:
                  showMinimumQuantityAccToSelectedOption(data) +
                  numberToAddInInputValue * showNumberAccToSelectedOption(data),
              };
            } else {
              return product;
            }
          } else if (selectedOption == "bundle") {
            if (
              data.sellerProductDetail.minimumQuantity.bundle.isMultiple.enable
            ) {
              let number =
                (value - showMinimumQuantityAccToSelectedOption(data)) %
                showNumberAccToSelectedOption(data);
              let numberToAddInInputValue =
                (value - showMinimumQuantityAccToSelectedOption(data)) /
                showNumberAccToSelectedOption(data);
              if (number != 0) {
                numberToAddInInputValue =
                  Math.floor(numberToAddInInputValue) + 1;
              }

              return {
                ...product,
                quantity:
                  showMinimumQuantityAccToSelectedOption(data) +
                  numberToAddInInputValue * showNumberAccToSelectedOption(data),
              };
            } else {
              return product;
            }
          } else {
            if (
              data.sellerProductDetail.minimumQuantity.box.isMultiple.enable
            ) {
              let number =
                (value - showMinimumQuantityAccToSelectedOption(data)) %
                showNumberAccToSelectedOption(data);
              let numberToAddInInputValue =
                (value - showMinimumQuantityAccToSelectedOption(data)) /
                showNumberAccToSelectedOption(data);
              if (number != 0) {
                numberToAddInInputValue =
                  Math.floor(numberToAddInInputValue) + 1;
              }

              return {
                ...product,
                quantity:
                  showMinimumQuantityAccToSelectedOption(data) +
                  numberToAddInInputValue * showNumberAccToSelectedOption(data),
              };
            } else {
              return product;
            }
          }
        }
      } else {
        return product;
      }
    });
    return allOrderCopy;
  };

  const handleClickOnPiece = async (
    allOrders: any,
    indexOfStore: number,
    indexOfItem: number
  ) => {
    if(allOrder[indexOfStore].itemList[indexOfItem].product.selectedOption === "piece"){
      return ;
    }else if(allOrder[indexOfStore].itemList[indexOfItem].quantity.pieceWise){
     

    //   let quantityInPiece =
    //   await giveQuantityInPieceForOrder(
    //     allOrder[indexOfStore].itemList[indexOfItem],
    //     allOrder[indexOfStore].itemList[indexOfItem].product.quantity,
    //     allOrder[indexOfStore].itemList[indexOfItem].product.selectedOption
    //   );
    // let orderId = allOrder[indexOfStore].itemList[indexOfItem].orderId;
    // await updateItemForQuantity(
    //   orderId,
    //   allOrder[indexOfStore].itemList[indexOfItem].product._id,
    //   allOrder[indexOfStore].itemList[indexOfItem],
    //   quantityInPiece,
    //   indexOfItem,
    //   indexOfStore,
    //   allOrder[indexOfStore].itemList[indexOfItem]
    // );
    let updatedOrders = [...allOrders];
    updatedOrders[indexOfStore].itemList[indexOfItem].product.selectedOption =
      "piece";
    updatedOrders[indexOfStore].itemList[indexOfItem].product.quantity =
      updatedOrders[indexOfStore].itemList[
        indexOfItem
      ].quantity.pieceWise;
    setAllOrder(updatedOrders);
    }

  };

  const handleClickOnBundle = async  (
    allOrders: any,
    indexOfStore: number,
    indexOfItem: number
  ) => {
    if(allOrder[indexOfStore].itemList[indexOfItem].product.selectedOption == "bundle"){
      return ;
    }
    if(!allOrder[indexOfStore].itemList[indexOfItem].quantity.bundleWise){
          let quantityInPiece =
          await giveQuantityInPieceForOrder(
            allOrder[indexOfStore].itemList[indexOfItem],
            allOrder[indexOfStore].itemList[indexOfItem].product.quantity,
            "bundle"
          );
        let orderId = allOrder[indexOfStore].itemList[indexOfItem].orderId;
        await updateItemForQuantity(
          orderId,
          allOrder[indexOfStore].itemList[indexOfItem].product._id,
          allOrder[indexOfStore].itemList[indexOfItem],
          quantityInPiece,
          indexOfItem,
          indexOfStore,
          allOrder[indexOfStore].itemList[indexOfItem]
        );
         
    }
    let updatedOrders = [...allOrders];
    console.log(updatedOrders[indexOfStore]);
    updatedOrders[indexOfStore].itemList[indexOfItem].product.selectedOption =
      "bundle";
    updatedOrders[indexOfStore].itemList[indexOfItem].product.quantity =
      updatedOrders[indexOfStore].itemList[
        indexOfItem
      ].quantity.bundleWise;

      
    setAllOrder(updatedOrders);
    console.log(updatedOrders);
  };
  const handleClickOnBox = async (
    allOrders: any,
    indexOfStore: number,
    indexOfItem: number
  ) => {
    if(allOrder[indexOfStore].itemList[indexOfItem].product.selectedOption == "box"){
      return ;
    }
    if(!allOrder[indexOfStore].itemList[indexOfItem].quantity.boxWise){
      let quantityInPiece =
      await giveQuantityInPieceForOrder(
        allOrder[indexOfStore].itemList[indexOfItem],
        allOrder[indexOfStore].itemList[indexOfItem].product.quantity,
        "box"
      );
    let orderId = allOrder[indexOfStore].itemList[indexOfItem].orderId;
    await updateItemForQuantity(
      orderId,
      allOrder[indexOfStore].itemList[indexOfItem].product._id,
      allOrder[indexOfStore].itemList[indexOfItem],
      quantityInPiece,
      indexOfItem,
      indexOfStore,
      allOrder[indexOfStore].itemList[indexOfItem]
    );
     
}
    let updatedOrders = [...allOrders];
    console.log(updatedOrders[indexOfStore]);
    updatedOrders[indexOfStore].itemList[indexOfItem].product.selectedOption =
      "box";
    updatedOrders[indexOfStore].itemList[indexOfItem].product.quantity =
      updatedOrders[indexOfStore].itemList[
        indexOfItem
      ].sellerProductDetail.minimumQuantity.box.quantity;
    setAllOrder(updatedOrders);
    console.log(updatedOrders);
  };
  // const handleClickOnPiece = async (
  //   data: any,
  //   indexOfStore: any,
  //   indexOfItem: any
  // ) => {
  //   // let updatedProducts = [...products];
  //   // updatedProducts[index].selectedOption = 'piece';
  //   // updatedProducts[index].quantity =
  //   //   product.sellerProductDetail.minimumQuantity.piece.quantity;
  //   // updatedProducts[index].totalPrice = showTotalPrice(product, 'piece');
  //   // updatedProducts[index].lastPriceOnWhichTotalPriceGettingCalculated =
  //   //   lastPriceOnWhichTotalPriceGettingCalculated(
  //   //     product,
  //   //     product.selectedOption
  //   //   );
  //   // setProducts(updatedProducts);
  //   // let productId = data.product._id;
  //   // let product = data.product;
  //   // let updatedProducts = await handleIncrementInOrder(
  //   //   data,
  //   //   indexOfItem,
  //   //   indexOfStore
  //   // );
  //   // let quantityInPiece = await giveQuantityInPieceForOrder(
  //   //   data,
  //   //   data.sellerProductDetail.minimumQuantity,
  //   //   "piece",
  //   // );
  //   // let orderId = data.orderId;
  //   // await updateItemForQuantity(
  //   //   orderId,
  //   //   productId,
  //   //   updatedProducts[indexOfItem],
  //   //   quantityInPiece,
  //   //   indexOfItem,
  //   //   indexOfStore,
  //   //   data
  //   // );
  // };

  // seller starts here

  const handleClickOutsideForSeller = (event: any) => {
    if (
      sellerRefForCloseOnClickingOutside.current &&
      !sellerRefForCloseOnClickingOutside.current.contains(event.target)
    ) {
      setIsSellerOpen(false);
    }
  };

  const fetchSeller = async () => {
    let url = "";

    if (inputValueForSeller) {
      url = `store?name=${inputValueForSeller}&seller-buyer-route-mapped=true&seller-buyer-route-mapped-only=true&seller=true&page=${pageNumberForSeller}&limit=5`;
    } else {
      url = `store?seller=true&seller-buyer-route-mapped=true&seller-buyer-route-mapped-only=true&page=${pageNumberForSeller}&limit=5`;
    }
    await GetRequest(url)
      .then(async (res) => {
        if (res.status == 200) {
          let response = await res.data.data.map((store: any) => {
            return { storeId: store._id, storeName: store.name };
          });
          setListsOfDistributors((prevState: any) => {
            return prevState.concat(response);
          });
          if (res.data.data.length === 0 || res.data.data.length < 5) {
            return await setStopInfiniteScrolling(true);
          } else if (res.data.data.length === 5) {
            setStopInfiniteScrolling(false);
            return setIsSellerOpen(true);
          }
        }
      })
      .catch((err) => {
        console.log(err, "this is err in fetching brand for the first");
      });
  };

  const onIntersectionForSeller = async (entries: any) => {
    const firstEntry = entries[0];

    if (firstEntry.isIntersecting && !stopInfiniteScrolling && isSellerOpen) {
      setPageNumberForSeller((prevPageNumber: any) => prevPageNumber + 1);
    }
  };

  useEffect(() => {
    if (sellerRef.current) {
      const observer = new IntersectionObserver(onIntersectionForSeller);
      observer.observe(sellerRef.current);
      return () => {
        observer.disconnect();
      };
    }
  }, [listsOfDistributors.length > 0, stopInfiniteScrolling]);

  useEffect(() => {
    if (isSellerOpen) {
      fetchSeller();
    }
  }, [pageNumberForSeller]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideForSeller);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideForSeller);
    };
  }, []);

  const handleInputChangeForSeller = async (event: any) => {
    const value = event.target.value;
    setInputValueForSeller(value);
    setActiveDistributor({});
    setListsOfDistributors([]);
    let url = value
      ? `store?name=${value}&seller-buyer-route-mapped=true&seller-buyer-route-mapped-only=true&page=${pageNumberForSeller}&limit=5`
      : `store?page=${pageNumberForSeller}&seller-buyer-route-mapped=true&seller-buyer-route-mapped-only=true&limit=5`;
    await GetRequest(url)
      .then(async (res) => {
        if (res.status == 200) {
          let response = await res.data.data.map((store: any) => {
            return { storeId: store._id, storeName: store.name };
          });
          setListsOfDistributors((prevState: any) => {
            return response;
          });
          if (res.data.data.length === 0 || res.data.data.length < 5) {
            return await setStopInfiniteScrolling(true);
          } else if (res.data.data.length === 5) {
            setStopInfiniteScrolling(false);
            return setIsSellerOpen(true);
          }
        }
      })
      .catch((err) => {
        setIsSellerOpen(false);
        console.log(err, "this is err in fetching brands");
      });
  };

  const handleActiveDistributor = async (sellerInfo: any) => {
    setActiveDistributor({
      storeName: sellerInfo.storeName,
      storeId: sellerInfo.storeId,
    });
    setInputValueForSeller(sellerInfo.storeName);
    setIsSellerOpen(false);
  };

  const fetchSellerRoute = async (sellerStoreId: string) => {
    await GetRequest(`route?seller-store=${sellerStoreId}`)
      .then((res) => {
        if (res.data.statusCode == 200) {
          setShowSellerRoute(true);
          setActiveSellerRoute(res.data.data);
        }
      })
      .catch((err) => console.log(err, "err in fetching seller routes"));
  };

  return (
    <div className="cnt_of_order">
      {showSellerRoute ? (
        <SellerRoute
          setActiveSellerRoute={setActiveSellerRoute}
          route={activeSellerRoute}
          setShowSellerRoute={setShowSellerRoute}
        />
      ) : (
        ""
      )}
      {Object.keys(showPlaceOrder).length > 0 ? (
        <OrderItemsUIForWeb
          editOrderedItem={false}
          setSelectedOption={setSelectedOption}
          showPlaceOrder={showPlaceOrder}
          setShowPlaceOrder={setShowPlaceOrder}
        />
      ) : (
        ""
      )}
      {showConfirmation ? (
        <ConfirmationBox
          setShowPlaceOrder={setShowPlaceOrder}
          setSelectedOption={setSelectedOption}
          orderIdConvertIntoPending={orderIdConvertIntoPending}
          setOrderIdConvertIntoPending={setOrderIdConvertIntoPending}
          setShowConfirmation={setShowConfirmation}
        />
      ) : (
        ""
      )}

      <div className="filters">
        <input type="checkbox" id="toggle" />
        <label
          htmlFor="toggle"
          onClick={() => setShowFilters(true)}
          className="filterIcon"
        >
          <span>
            <RiFilter2Fill />
          </span>
        </label>

        <div
          className={
            showFilters
              ? "cnt_of_brand_distributor-mobile"
              : "cnt_of_brand_distributor"
          }
        >
          <div className="heading-filter-n-cross-icon">
            <button
              className="go-back-button"
              onClick={() => setShowFilters(false)}
            >
              <MdOutlineKeyboardBackspace style={{ display: "inline" }} />
            </button>
            <h2>
              Filters <RiFilter2Fill />
            </h2>
          </div>

          <div style={{ margin: "1.2rem 0" }}>
            <div className="autocomplete-container">
              <input
                style={{ width: "100%" }}
                type="text"
                placeholder="Choose Seller"
                className="autocomplete-input"
                value={inputValueForSeller}
                onClick={fetchSeller}
                onChange={handleInputChangeForSeller}
              />
              {isSellerOpen && listsOfDistributors.length > 0 ? (
                <ul
                  className="suggestions-list"
                  style={{ overflowY: "scroll" }}
                  ref={sellerRefForCloseOnClickingOutside}
                >
                  {listsOfDistributors.length > 0 &&
                    listsOfDistributors.map((seller: any, index: number) => (
                      <li
                        key={index}
                        onClick={() => handleActiveDistributor(seller)}
                        className="suggestion-item"
                      >
                        {seller.storeName}
                      </li>
                    ))}

                  {listsOfDistributors.length > 0 && (
                    <div ref={sellerRef} style={{ padding: "10px" }}></div>
                  )}
                </ul>
              ) : (
                ""
              )}
            </div>
            {/* <FormControl

              fullWidth
              sx={{
                '& .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root': {
                  width: '160px',
                },
                '& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root': {
                  lineHeight: '1rem',
                  fontSize: '0.90rem',
                  color: '#9FA6B3',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderRadius: '0.35rem',
                    borderColor: '#E5E7EB',
                  },
                },
              }}
            >
              <InputLabel>Choose Seller</InputLabel>
              <Select
                name="distributor"
                style={{
                  width: '100%',
                }}
                sx={{
                  '& .MuiSelect-select': {
                    padding: '0.75rem 1.25rem',
                  },
                  '& .css-u0uils-MuiFormLabel-root-MuiInputLabel-root': {
                    borderColor: '#E5E7EB',
                  },
                }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                placeholder="Choose One"
                label="Distributor"
                onClick={getSellerList}
                onChange={handleDistributorChange}
                value={selectedDistributor}
              >
                {sellerList.map((data: any) => {
                  return <MenuItem value={data.name}>{data.name}</MenuItem>;
                })}
              </Select>
            </FormControl> */}
          </div>

          <div style={{ marginBottom: "1.2rem" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker label="From" onChange={handleFromDateChange} />
            </LocalizationProvider>
          </div>
          <div style={{ marginBottom: "1.2rem" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker label="To" onChange={handleToDateChange} />
            </LocalizationProvider>
          </div>
          <div className="apply-filter-btn-cnt">
            <button onClick={applyFilters} className="apply-filter-btn">
              Apply filters
            </button>
          </div>
        </div>
      </div>
      <div className="cnt_lists_of_distributors">
        <div className="cnt_buy_again">
          <ul className="order_page_header">
            <li
              onClick={() => {
                navigate("/order/Pending");
                setSelectedOption("Pending");
              }}
              className={orderStatus == "Pending" ? "active-nav" : ""}
            >
              <Link to="#">Pending</Link>
            </li>
            <li
              onClick={() => {
                navigate("/order/Ordered");
                setSelectedOption("Ordered");
              }}
              className={orderStatus == "Ordered" ? "active-nav" : ""}
            >
              <Link to="#">Ordered</Link>
            </li>
            <li
              onClick={() => {
                navigate("/order/Preparing");
                setSelectedOption("Preparing");
              }}
              className={orderStatus == "Preparing" ? "active-nav" : ""}
            >
              <Link to="$">Preparing</Link>
            </li>

            <li
              onClick={() => {
                navigate("/order/Cancelled");
                setSelectedOption("Cancelled");
              }}
              className={orderStatus == "Cancelled" ? "active-nav" : ""}
            >
              <Link to="#">Cancelled</Link>
            </li>
            <li
              onClick={() => {
                navigate("/order/Delivered");
                setSelectedOption("Delivered");
              }}
              className={orderStatus == "Delivered" ? "active-nav" : ""}
            >
              <Link to="#">Delivered</Link>
            </li>
          </ul>
        </div>
        <div>
          {selectedOption == "Pending" ? (
            <PendingOrderList
              handleClickOnPiece={handleClickOnPiece}
              handleClickOnBundle={handleClickOnBundle}
              handleClickOnBox={handleClickOnBox}
              // setActiveSellerRoute={setActiveSellerRoute}
              allOrder={allOrder}
              formatDateToDDMMMYY={formatDateToDDMMMYY}
              handleSeeProduct={handleSeeProduct}
              removeProductFromBag={removeProductFromBag}
              showMinimumQuantityAccToSelectedOption={
                showMinimumQuantityAccToSelectedOption
              }
              handleDecrementInOrder={handleDecrementInOrder}
              handleIncrementInOrder={handleIncrementInOrder}
              giveQuantityInPieceForOrder={giveQuantityInPieceForOrder}
              updateItemForQuantity={updateItemForQuantity}
              handleChangeOnQuantity={handleChangeOnQuantity}
              handleClickOnPlaceOrder={handleClickOnPlaceOrder}
              setAllOrder={setAllOrder}
              fetchSellerRoute={fetchSellerRoute}
            />
          ) : (
            ""
          )}
          {selectedOption == "Ordered" || selectedOption == "Delivered" ? (
            <OrderedList
              // setActiveSellerRoute={setActiveSellerRoute}
              setOrderIdConvertIntoPending={setOrderIdConvertIntoPending}
              setShowConfirmation={setShowConfirmation}
              allOrder={allOrder}
              formatDateToDDMMMYY={formatDateToDDMMMYY}
              handleSeeProduct={handleSeeProduct}
              removeProductFromBag={removeProductFromBag}
              showMinimumQuantityAccToSelectedOption={
                showMinimumQuantityAccToSelectedOption
              }
              fetchSellerRoute={fetchSellerRoute}
              handleDecrementInOrder={handleDecrementInOrder}
              handleIncrementInOrder={handleIncrementInOrder}
              giveQuantityInPieceForOrder={giveQuantityInPieceForOrder}
              updateItemForQuantity={updateItemForQuantity}
              handleChangeOnQuantity={handleChangeOnQuantity}
              handleClickOnPlaceOrder={handleClickOnPlaceOrder}
              setAllOrder={setAllOrder}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}
function formatDateToDDMMMYY(inputDate: any) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const date = new Date(inputDate);

  const day = String(date.getUTCDate()).padStart(2, "0");
  const monthAbbreviation = months[date.getUTCMonth()];
  const year = String(date.getUTCFullYear()).slice(-4);

  const formattedDate = `${day}-${monthAbbreviation}-${year}`;

  return formattedDate;
}
function PendingOrderList(props: any) {
  let {
    allOrder,
    handleSeeProduct,
    removeProductFromBag,
    showMinimumQuantityAccToSelectedOption,
    handleDecrementInOrder,
    handleIncrementInOrder,
    giveQuantityInPieceForOrder,
    updateItemForQuantity,
    handleChangeOnQuantity,
    handleClickOnPlaceOrder,
    setAllOrder,
    fetchSellerRoute,
    handleClickOnPiece,
    handleClickOnBundle,
    handleClickOnBox,
  } = props;
  return (
    <ul className="lists_of_distributors">
      {/* <SellerRoute /> */}
      {allOrder.length > 0 &&
        allOrder.map((order: any, indexOfStore: number) => {
          return (
            <>
              <li
                style={{
                  borderBottom: "1px solid lightgray",
                  flexDirection: "column",
                }}
                className="each_distributor"
              >
                <div className="distributor_info">
                  <div style={{}} className="cnt-of-seller">
                    <div className="user_icon">
                      <img
                        className="seller-profile-img"
                        src={
                          order.sellerStore.profileImage
                            ? order.sellerStore.profileImage
                            : "/images/distributor_icon.jpg"
                        }
                        alt="distributor"
                      />
                    </div>
                    <div
                      style={{
                        flexBasis: "87%",
                      }}
                      className="margin-left"
                    >
                      <div className="cnt_of_date_n_name">
                        <h2 className="distributor_name">
                          {order.sellerStore.name}
                        </h2>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        className="margin-left-20"
                      >
                        <div className="cnt_of_order_btn_n_total_price">
                          <p className="date">
                            {formatDateToDDMMMYY(order.createdAt)}
                          </p>
                          <p className="total_item">
                            <span>{"Items : "} </span>
                            {order.totalItem}
                          </p>
                        </div>
                        <div className="cnt_of_tax_n_total_item">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            {order.totalTaxAmountList.map((tax: any) => (
                              <p className="order_tax">
                                {tax.type}
                                <span> ₹ {tax.amount.toFixed(2)}</span>
                              </p>
                            ))}
                          </div>
                        </div>

                        <div
                          style={{
                            position: "relative",
                            flexBasis: "32%",
                            display: "flex",
                            justifyContent: "end",
                          }}
                          className="total-price-n-place-order-btn-cnt"
                        >
                          <button
                            className="place-order-btn"
                            onClick={() => {
                              handleClickOnPlaceOrder(order._id, indexOfStore);
                            }}
                          >
                            Place Order
                          </button>
                          <Link
                            to="#"
                            onClick={() =>
                              fetchSellerRoute(
                                order.sellerStore._id,
                                indexOfStore
                              )
                            }
                            style={{
                              color: "#14539a",
                              marginRight: "0.5rem",
                              fontSize: "0.85rem",
                              fontWeight: 700,
                              position: "absolute",
                              left: "-226%",
                              fontStyle: "italic",
                              top: "78%",
                              right: "13%",
                            }}
                          >
                            (Discover seller routes in your area now! Click
                            here)
                          </Link>
                          <p className="total_price seller-total-price">
                            <span>{"Total" + " "} </span>
                            {"     ₹" + order.totalAmount}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="see_products">
                    <Link
                      to="#"
                      onClick={() =>
                        handleSeeProduct(
                          order._id,
                          order.showItem,
                          indexOfStore
                        )
                      }
                    >
                      {!order.showItem ? "See Products" : "Hide Products"}
                      {order.showItem ? <FiChevronUp /> : <FiChevronDown />}
                    </Link>
                  </div>

                  <ul className="orders">
                    {order.itemList.length > 0 &&
                      order.showItem &&
                      order.itemList.map((data: any, indexOfItem: number) => {
                        return (
                          <li className="product_cnt">
                            <div className="product">
                              <div className="cnt_of_product_img_n_quantity">
                                <LazyLoadImage
                                  alt={data.product.name}
                                  className="product_img"
                                  src={
                                    data.product.imageList == undefined
                                      ? "/images/no-image-available-icon.jpeg"
                                      : data.product.imageList[0]
                                  }
                                />
                              </div>
                              <Link
                                to="#"
                                onClick={() =>
                                  removeProductFromBag(
                                    indexOfItem,
                                    indexOfStore
                                  )
                                }
                                className="cancel_order"
                              >
                                Delete
                              </Link>
                              <div
                                style={{
                                  flexBasis: "80%",
                                  flexWrap: "wrap",
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                                className="marginLeft"
                              >
                                <div
                                  className="basis-50-mb"
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div>
                                    <h1 className="title">
                                      {data.product.name}
                                    </h1>
                                    <p className="price">
                                      Price : ₹{" "}
                                      {
                                        data.product
                                          .lastPriceOnWhichTotalPriceGettingCalculated
                                      }
                                    </p>
                                    <p className="mrp-sm-screen-mobile">
                                      MRP ₹ {data.product.mrp}
                                    </p>
                                    <div className="quantity">
                                      <div>
                                        <button
                                          style={{
                                            cursor:
                                              showMinimumQuantityAccToSelectedOption(
                                                data
                                              ) == data.product.quantity
                                                ? "not-allowed"
                                                : "pointer",
                                          }}
                                          onMouseOver={(event: any) => {
                                            if (
                                              showMinimumQuantityAccToSelectedOption(
                                                data
                                              ) == data.product.quantity
                                            ) {
                                              return event.target.classList.add(
                                                "minus-1"
                                              );
                                            }
                                          }}
                                          onMouseLeave={(event: any) => {
                                            return event.target.classList.remove(
                                              "minus-1"
                                            );
                                          }}
                                          onClick={async () => {
                                            if (
                                              showMinimumQuantityAccToSelectedOption(
                                                data
                                              ) !== data.product.quantity
                                            ) {
                                              let productId = data.product._id;
                                              let product = data.product;
                                              let updatedProducts =
                                                await handleDecrementInOrder(
                                                  data,
                                                  indexOfItem,
                                                  indexOfStore
                                                );

                                              let quantityInPiece =
                                                await giveQuantityInPieceForOrder(
                                                  data,
                                                  data.product.quantity,
                                                  data.product.selectedOption
                                                );
                                              let orderId = data.orderId;
                                              await updateItemForQuantity(
                                                orderId,
                                                productId,
                                                updatedProducts[indexOfItem],
                                                quantityInPiece,
                                                indexOfItem,
                                                indexOfStore,
                                                data
                                              );
                                            }
                                          }}
                                          className="minus"
                                        >
                                          -
                                        </button>
                                        <div className="minimum_quantity_info">
                                          <span>
                                            minimum Quantity to buy in
                                            {" " +
                                              data.product.selectedOption}{" "}
                                            is
                                            {" " +
                                              showMinimumQuantityAccToSelectedOption(
                                                data
                                              )}
                                          </span>
                                        </div>

                                        <input
                                          onBlur={async (event: any) => {
                                            let productId = data.product._id;
                                            let product = data.product;
                                            let updatedAllOrder =
                                              await handleChangeOnQuantity(
                                                data.product.quantity,
                                                data,
                                                indexOfStore,
                                                indexOfItem
                                              );
                                            let orderId = data.orderId;
                                            let quantityInPiece =
                                              await giveQuantityInPieceForOrder(
                                                updatedAllOrder[indexOfStore]
                                                  .itemList[indexOfItem],
                                                updatedAllOrder[indexOfStore]
                                                  .itemList[indexOfItem].product
                                                  .quantity,
                                                updatedAllOrder[indexOfStore]
                                                  .itemList[indexOfItem].product
                                                  .selectedOption
                                              );

                                            await updateItemForQuantity(
                                              orderId,
                                              productId,
                                              updatedAllOrder[indexOfItem],
                                              quantityInPiece,
                                              indexOfItem,
                                              indexOfStore,
                                              data
                                            );
                                          }}
                                          onChange={async (event: any) => {
                                            let allOrderCopy = [...allOrder];
                                            allOrderCopy[indexOfStore].itemList[
                                              indexOfItem
                                            ].product.quantity =
                                              event.target.value;
                                            setAllOrder(allOrderCopy);
                                          }}
                                          type="number"
                                          name="quantity"
                                          value={data.product.quantity}
                                        />
                                        <button
                                          onClick={async () => {
                                            let productId = data.product._id;
                                            let product = data.product;
                                            let updatedProducts =
                                              await handleIncrementInOrder(
                                                data,
                                                indexOfItem,
                                                indexOfStore
                                              );

                                            let quantityInPiece =
                                              await giveQuantityInPieceForOrder(
                                                data,
                                                data.product.quantity,
                                                data.product.selectedOption
                                              );
                                            let orderId = data.orderId;

                                            await updateItemForQuantity(
                                              orderId,
                                              productId,
                                              updatedProducts[indexOfItem],
                                              quantityInPiece,
                                              indexOfItem,
                                              indexOfStore,
                                              data
                                            );
                                          }}
                                          className="plus"
                                        >
                                          +
                                        </button>
                                      </div>
                                    </div>

                                    <div>
                                      <div className="options_like_box_bundle_piece">
                                        <div>
                                          {data.sellerProductDetail
                                            .minimumQuantity.piece &&
                                          data.sellerProductDetail.pricePerUnit
                                            .piece ? (
                                            <button
                                              // onClick={() =>
                                              //   handleClickOnPiece(product, index)
                                              // }
                                              style={{
                                                backgroundColor:
                                                  data.product.selectedOption ==
                                                  "piece"
                                                    ? "#eef2f6"
                                                    : "",
                                              }}
                                            >
                                              Piece
                                            </button>
                                          ) : (
                                            ""
                                          )}

                                          {data.sellerProductDetail
                                            .minimumQuantity.bundle &&
                                          data.sellerProductDetail.pricePerUnit
                                            .bundle &&
                                          data.product.info
                                            .pieceInEachBundle ? (
                                            <button
                                              style={{
                                                backgroundColor:
                                                  data.product.selectedOption ==
                                                  "bundle"
                                                    ? "#eef2f6"
                                                    : "",
                                                display: "flex",
                                                paddingRight: "3.65rem",
                                              }}
                                              onClick={() =>
                                                handleClickOnBundle(
                                                  allOrder,
                                                  indexOfStore,
                                                  indexOfItem
                                                )
                                              }
                                            >
                                              <span>Bundle</span>{" "}
                                              <span
                                                style={{
                                                  marginLeft: "0.25rem",
                                                }}
                                              >
                                                *
                                              </span>
                                            </button>
                                          ) : (
                                            ""
                                          )}

                                          {data.sellerProductDetail
                                            .minimumQuantity.box &&
                                          data.sellerProductDetail.pricePerUnit
                                            .box &&
                                          data.product.info.pieceInEachBox ? (
                                            <button
                                              onClick={() =>
                                                handleClickOnBox(
                                                  allOrder,
                                                  indexOfStore,
                                                  indexOfItem
                                                )
                                              }
                                              style={{
                                                backgroundColor:
                                                  data.product.selectedOption ==
                                                  "box"
                                                    ? "#eef2f6"
                                                    : "",
                                              }}
                                            >
                                              Box
                                              <span
                                                style={{
                                                  fontSize: "9px",
                                                  position: "absolute",
                                                  marginLeft: "2px",
                                                }}
                                              >
                                                {"  #"}
                                              </span>
                                            </button>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    </div>

                                    <p
                                      className="order_id"
                                      style={{
                                        color: "#14539A",
                                      }}
                                    >
                                      Total Pieces sfjsdklfs :{" "}
                                      {data.quantity.pieceWise}
                                    </p>
                                  </div>
                                </div>

                                <div>
                                  <p className="mrp">
                                    MRP ₹ {data.product.mrp}
                                  </p>
                                  <div className="tax-cnt">
                                    {data.totalTaxPercentagePrice.map(
                                      (tax: any, index: number) => {
                                        return (
                                          <p className="order_date tax">
                                            {tax.type}
                                            <span>
                                              {"  " + tax.percentage + "%  "}
                                            </span>
                                          </p>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>

                                <div className="cnt_of_options_n_total_price">
                                  <p
                                    className="price"
                                    style={{
                                      margin: "0",
                                      textAlign: "right",
                                    }}
                                  >
                                    <span className="total_span">Total</span> ₹
                                    {data.totalPrice}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                  </ul>
                  <div className="place-order-btn-cnt-mb">
                    <button
                      className="place-order-btn-mb"
                      onClick={() =>
                        handleClickOnPlaceOrder(order._id, indexOfStore)
                      }
                    >
                      Place Order
                    </button>
                  </div>
                </div>
              </li>
            </>
          );
        })}
    </ul>
  );
}

function OrderedList(props: any) {
  let {
    setOrderIdConvertIntoPending,
    setShowConfirmation,
    allOrder,
    formatDateToDDMMMYY,
    handleSeeProduct,
    removeProductFromBag,
    showMinimumQuantityAccToSelectedOption,
    handleDecrementInOrder,
    handleIncrementInOrder,
    giveQuantityInPieceForOrder,
    updateItemForQuantity,
    handleChangeOnQuantity,
    handleClickOnPlaceOrder,
    setAllOrder,
    fetchSellerRoute,
  } = props;
  console.log(allOrder, "this is itemList");
  return (
    <ul className="lists_of_distributors">
      {allOrder.length > 0 &&
        allOrder.map((order: any, indexOfStore: number) => {
          return (
            <>
              <li
                style={{ borderBottom: "1px solid lightgray" }}
                className="each_distributor"
              >
                {order.status !== "Delivered" && (
                  <div className="convert-update-to-pending-btn-cnt">
                    <button
                      className="convert-update-to-pending-btn"
                      onClick={() => {
                        setOrderIdConvertIntoPending(order._id);
                        setShowConfirmation(true);
                      }}
                    >
                      <FiEdit />
                    </button>
                  </div>
                )}

                <div className="distributor_info">
                  <div className="cnt-of-seller">
                    <div className="user_icon">
                      <img
                        className="seller-profile-img"
                        src={
                          order.sellerStore.profileImage
                            ? order.sellerStore.profileImage
                            : "/images/distributor_icon.jpg"
                        }
                        alt="distributor"
                      />
                    </div>
                    <div
                      style={{
                        flexBasis: "87%",
                      }}
                      className="margin-left"
                    >
                      <div className="cnt_of_date_n_name">
                        <h2 className="distributor_name">
                          {order.sellerStore.name}
                        </h2>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        className="margin-left-20"
                      >
                        <div className="cnt_of_order_btn_n_total_price">
                          <p className="date">
                            {formatDateToDDMMMYY(order.createdAt)}
                          </p>
                          <p className="total_item">
                            <span>{"Items : "} </span>
                            {order.totalItem}
                          </p>
                        </div>
                        <div className="cnt_of_tax_n_total_item">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            {order.totalTaxAmountList.map((tax: any) => (
                              <p className="order_tax">
                                {tax.type}
                                <span> ₹ {tax.amount.toFixed(2)}</span>
                              </p>
                            ))}
                          </div>
                        </div>

                        <div
                          style={{
                            position: "relative",
                            flexBasis: "32%",
                            display: "flex",
                            justifyContent: "end",
                          }}
                          className="total-price-n-place-order-btn-cnt"
                        >
                          <Link
                            to="#"
                            onClick={() =>
                              fetchSellerRoute(
                                order.sellerStore._id,
                                indexOfStore
                              )
                            }
                            style={{
                              color: "#14539a",
                              marginRight: "0.5rem",
                              fontSize: "0.85rem",
                              fontWeight: 700,
                              position: "absolute",
                              left: "-226%",
                              fontStyle: "italic",
                              top: "78%",
                              right: "13%",
                            }}
                          >
                            (Discover seller routes in your area now! Click
                            here)
                          </Link>
                          <p className="total_price seller-total-price">
                            <span>{"Total" + " "} </span>
                            {"     ₹" + order.totalAmount}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="see_products">
                    <Link
                      to="#"
                      onClick={() =>
                        handleSeeProduct(
                          order._id,
                          order.showItem,
                          indexOfStore
                        )
                      }
                    >
                      {!order.showItem ? "See Products" : "Hide Products"}
                      {order.showItem ? <FiChevronUp /> : <FiChevronDown />}
                    </Link>
                  </div>

                  <ul className="orders">
                    {order.itemList.length > 0 &&
                      order.showItem &&
                      order.itemList.map((data: any, indexOfItem: number) => {
                        return (
                          <li className="product_cnt">
                            <div className="product">
                              <div className="cnt_of_product_img_n_quantity">
                                <LazyLoadImage
                                  alt={data.product.name}
                                  className="product_img"
                                  src={
                                    data.product.imageList == undefined
                                      ? "/images/no-image-available-icon.jpeg"
                                      : data.product.imageList[0]
                                  }
                                />
                              </div>

                              <div
                                style={{
                                  flexBasis: "80%",
                                  flexWrap: "wrap",
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                                className="marginLeft"
                              >
                                <div
                                  className="basis-50-mb"
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div>
                                    <h1 className="title">
                                      {data.product.name}
                                    </h1>
                                    <p className="price">
                                      Price : ₹{" "}
                                      {
                                        data.product
                                          .lastPriceOnWhichTotalPriceGettingCalculated
                                      }
                                    </p>
                                    <p className="mrp-sm-screen-mobile">
                                      MRP ₹ {data.product.mrp}
                                    </p>
                                    <div className="quantity">
                                      <div>
                                        <span>
                                          Quantity
                                          {`${
                                            data.product.selectedOption !=
                                            "piece"
                                              ? `(in ${data.product.selectedOption})`
                                              : ""
                                          }`}
                                        </span>{" "}
                                        <input
                                          readOnly={true}
                                          // onBlur={async (event: any) => {
                                          //   let productId = data.product._id;
                                          //   let product = data.product;
                                          //   let updatedAllOrder =
                                          //     await handleChangeOnQuantity(
                                          //       data.product.quantity,
                                          //       data,
                                          //       indexOfStore,
                                          //       indexOfItem
                                          //     );
                                          //   let orderId = data.orderId;
                                          //   let quantityInPiece =
                                          //     await giveQuantityInPieceForOrder(
                                          //       updatedAllOrder[indexOfStore]
                                          //         .itemList[indexOfItem],
                                          //       updatedAllOrder[indexOfStore]
                                          //         .itemList[indexOfItem].product
                                          //         .quantity,
                                          //       updatedAllOrder[indexOfStore]
                                          //         .itemList[indexOfItem].product
                                          //         .selectedOption
                                          //     );

                                          //   await updateItemForQuantity(
                                          //     orderId,
                                          //     productId,
                                          //     updatedAllOrder[indexOfItem],
                                          //     quantityInPiece,
                                          //     indexOfItem,
                                          //     indexOfStore,
                                          //     data
                                          //   );
                                          // }}
                                          // onChange={async (event: any) => {
                                          //   let allOrderCopy = [...allOrder];
                                          //   allOrderCopy[indexOfStore].itemList[
                                          //     indexOfItem
                                          //   ].product.quantity =
                                          //     event.target.value;
                                          //   setAllOrder(allOrderCopy);
                                          // }}
                                          type="number"
                                          name="quantity"
                                          value={data.product.quantity}
                                        />
                                      </div>
                                    </div>

                                    <p
                                      className="order_id"
                                      style={{
                                        color: "#14539A",
                                      }}
                                    >
                                      Total Pieces djsklfkldjs :{" "}
                                      {data.quantity.pieceWise}
                                    </p>
                                  </div>
                                </div>

                                <div>
                                  <p className="mrp">
                                    MRP ₹ {data.product.mrp}
                                  </p>
                                  <div className="tax-cnt">
                                    {data.totalTaxPercentagePrice.map(
                                      (tax: any, index: number) => {
                                        return (
                                          <p className="order_date tax">
                                            {tax.type}
                                            <span>
                                              {"  " + tax.percentage + "%  "}
                                            </span>
                                          </p>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>

                                <div className="cnt_of_options_n_total_price">
                                  <p
                                    className="price"
                                    style={{
                                      margin: "0",
                                      textAlign: "right",
                                    }}
                                  >
                                    <span className="total_span">Total</span> ₹
                                    {data.totalPrice}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </li>
            </>
          );
        })}
    </ul>
  );
}
