import './App.css';
import './index.css';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Dashboard from './pages/user/dashboard/Dashboard';
import Cart from './pages/cart/Cart';
import Products from './pages/user/products/Products';
import AddShop from './pages/user/addStore/AddStore';
import Login from './pages/auth/login/Login';
import Notification from './pages/notification/Notification';
import EditProfile from './pages/editProfile/EditProfile';
import UpdateShop from './pages/user/updateShop/UpdateShop';
import Order from './pages/order/Order';
import PublicLayout from './components/PublicLayout';
import PrivateLayout from './components/PrivateLayout';
import UpdateUser from './pages/user/updateUser/UpdateUser';
// import Profile from './pages/user/profile/Profile';
import ActiveStoreState from './context/ActiveStore/ActiveStoreState';
import Profile from './pages/user/profileSettings/Profile';
import Registeration from './pages/register/Registeration';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from './store';
import {
  fetchUserInfoAsyncThunk,
  fetchUserStoreListAsyncThunk,
  isLoginSelector,
  storeListSelector,
  addCurrentUser,
  loadingSelector,
  userSelector,
  fetchStoreSelfAsyncThunk,
  errorSelector,
} from './features/auth/Slice';
import { useEffect } from 'react';
import { PostRequest } from './components/Request';
import Indicator from './common/Indicators/Indicator';
import PublicHeader from './layouts/sidebar/PublicHeader';
import PrivateHeader from './layouts/sidebar/PrivateHeader';

// function App() {
//   const isLogedIn = useSelector(isLoginSelector);
//   const dispatch = useDispatch<AppDispatch>();
//   const storeListInfo = useSelector(storeListSelector);

//   useEffect(() => {
//     if (isLogedIn) {
//       dispatch(fetchUserInfoAsyncThunk({}));
//       dispatch(fetchUserStoreListAsyncThunk());
//     }
//   }, [isLogedIn]);

//   const makeStoreActive = async () => {
//     await PostRequest('auth/change-profile', {
//       self: false,
//       storeId: storeListInfo[0]._id,
//     })
//       .then(async (res: any) => {
//         if (res.data.statusCode === 200) {
//           dispatch(addCurrentUser(storeListInfo[0]));
//           dispatch(changeProfileAsyncThunk());
//         }
//       })
//       .catch((err) => {
//         console.log(err, 'err in changing the profile');
//       });
//   };

//   useEffect(() => {
//     if (isLogedIn) {
//       if (storeListInfo.length === 1) {
//         makeStoreActive();
//       }
//     }
//   }, [storeListInfo]);

//   return (
//     <Routes>
//       <Route path="/" element={<PublicLayout />}>
//         {/* public routes */}
//         <Route path="/login" element={<Login />} />
//         <Route path="/register" element={<Registeration />} />
//       </Route>

//       <Route
//         path="/"
//         element={
//           <ActiveStoreState>
//             <PrivateLayout />
//           </ActiveStoreState>
//         }
//       >
//         {/* <Route path="register" element={<Register />} />
//         <Route path="linkpage" element={<LinkPage />} />
//         <Route path="unauthorized" element={<Unauthorized />} /> */}

//         {/* we want to protect these routes */}

//         {/* <Route element={<RequireAuth />}> */}
//         <Route path="/dashboard" element={<Dashboard />} />
//         <Route path="/products" element={<Products />} />
//         <Route path="/order/:orderStatus" element={<Order />} />
//         <Route path="/store" element={<AddShop />} />
//         <Route path="/cart" element={<Cart />} />
//         {/* <Route path="/notifications" element={<Notification />} /> */}
//         {/* <Route path="/edit" element={<EditProfile />} /> */}
//         {/* <Route path="/edit/currentshop" element={<UpdateShop />} /> */}
//         {/* <Route path="/edit/currentuser" element={<UpdateUser />} /> */}
//         <Route path="/profile" element={<Profile />} />
//       </Route>
//     </Routes>
//   );
// }

// export default App;

// import './App.css';
import { Route as ReactRoute } from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoute';
import Home from './pages/home/Home';
import PrivacyPolicy from './pages/privacy/Privacy';
import Support from './pages/helpAndSupport/Support';
// import Dashboard from './pages/user/dashboard/Dashboard';
// import Route from './pages/user/route/Route';
// import Login from './pages/auth/login/Login';
// import PolygonMap from './pages/user/polygon/PolygonMap';
// import AddStore from './pages/user/addStore/AddStore';
// import Brand from './pages/user/brand/Brand';
// import Profile from './pages/user/profileSetting/Profile';
// import SellerProfile from './pages/user/sellerProfile/SellerProfile';
// import ProductsLists from './pages/user/productList/ProductList';
// import ProductState from './context/ProductState';
// import Schedule from './pages/user/schedule/Schedule';
// import ActiveStoreState from './context/ActiveStore/ActiveStoreState';
// import Order from './pages/user/order/Order';

// import BuyerAccToRoute from './pages/user/buyer/BuyerAccToRoute';
// import BrandList from './pages/user/brand/BrandList';
// import Registeration from './pages/register/Registeration';
// import { useDispatch, useSelector } from 'react-redux';
// import { AppDispatch } from './store';
// import {
//   fetchUserInfoAsyncThunk,
//   fetchUserStoreListAsyncThunk,
//   isLoginSelector,
//   storeListSelector,
//   // changeProfileAsyncThunk,
//   addCurrentUser,
//   switchProfileAsyncThunk,
//   fetchStoreSelfAsyncThunk,
//   errorSelector,
//   loadingSelector,
//   userSelector,
// } from './features/auth/Slice';
// import { useEffect, useState } from 'react';
// import { PostRequest } from './component/Request';
// import ProtectedRoute from './component/ProtectedRoute';
// import PrivateHeader from './layout/sidebar/PrivateHeader';
// import PublicHeader from './layout/sidebar/PublicHeader';
// import { useLocation } from 'react-router-dom';
// import Indicator from './common/Indicator/Indicator';
// import { userInfo } from 'os';
// import Home from './pages/Home/Home';

function App() {
  const location = useLocation();
  // const previousUrl = window && window.location&&window.location?.state?.prevUrl;
  const navigate = useNavigate();
  const isLogedIn = useSelector(isLoginSelector);
  const isLoading = useSelector(loadingSelector);
  const dispatch = useDispatch<AppDispatch>();
  const storeListInfo = useSelector(storeListSelector);
  const userInfo = useSelector(userSelector);

  useEffect(() => {
    dispatch(
      fetchUserInfoAsyncThunk({
        showIndicatorForErrorRequest: false,
      })
    );
  }, []);

  useEffect(() => {
    if (isLoading === 'Fulfilled' && isLogedIn) {
      dispatch(fetchUserStoreListAsyncThunk());
      dispatch(fetchStoreSelfAsyncThunk());
      // if (location.pathname === '/register' || location.pathname === '/login') {
      //   navigate('/dashboard');
      // } else {
      //   navigate(location.pathname);
      // }
    } else {
      console.log(location.pathname === '/stores', 'this is');
      // if (location.pathname === '/register') {
      //   return navigate('/register');
      // } else {
      //   navigate('/login');
      // }
    }
  }, [isLogedIn]);

  return isLogedIn ? (
    <AuthenticatedApp isLogedIn={isLogedIn} />
  ) : (
    <NonAuthenticatedApp />
  );
}

function NonAuthenticatedApp() {
  const error = useSelector(errorSelector);

  return (
    <>
      {error ? (
        <Indicator
          message={error.message}
          type={error.type}
          errorId={error.errorId}
        />
      ) : (
        ''
      )}
      <PublicHeader />
      <Routes>
        <ReactRoute path="/" element={<Home />} />
        <ReactRoute path="/login" element={<Login />} />
        <ReactRoute path="/support" element={<Support />} />
        <ReactRoute path="/register" element={<Registeration />} />
        <ReactRoute path="/privacy" element={<PrivacyPolicy />} />
      </Routes>
    </>
  );
}

function AuthenticatedApp({ isLogedIn }: any) {
  const error = useSelector(errorSelector);

  return (
    <>
      {error ? (
        <Indicator
          message={error.message}
          type={error.type}
          errorId={error.errorId}
        />
      ) : (
        ''
      )}
      <ActiveStoreState>
        <PrivateHeader />
      </ActiveStoreState>

      <Routes>
        <ReactRoute path="/" element={<Home />} />
        <ReactRoute
          path="/dashboard"
          element={
            <ActiveStoreState>
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            </ActiveStoreState>
          }
        />
        <ReactRoute path="/privacy" element={<PrivacyPolicy />} />
        <ReactRoute
          path="/order/:orderStatus"
          element={
            <ProtectedRoute>
              <Order />
            </ProtectedRoute>
          }
        />

        <ReactRoute
          path="/product"
          element={
            <ProtectedRoute>
              <Products />
            </ProtectedRoute>
          }
        />

        <ReactRoute
          path="/store/create"
          element={
            <ProtectedRoute>
              <AddShop />
            </ProtectedRoute>
          }
        />

        <ReactRoute
          path="/profile"
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />
        <ReactRoute path="/support" element={<Support />} />
        <ReactRoute path="/privacy" element={<PrivacyPolicy />} />
        {/* <ReactRoute
          path="/store/:slug"
          element={
            <ProtectedRoute>
              <SellerProfile />
            </ProtectedRoute>
          }
        /> */}

        <ReactRoute />
      </Routes>
    </>
  );
}

export default App;
