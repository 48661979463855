import { useState } from 'react';
import './inputDate.scss';

export default function InputDate({
  placeholder,
  label,
  value,
  handleChange,
  name,
}: any) {
  const [labelOnTop, setLabelOnTop] = useState<Boolean>(false);

  return (
    <div className="input-date-cnt">
      <label
        className={labelOnTop ? 'label label-should-move-upward' : 'label'}
        htmlFor={label}
      >
        {label}
      </label>
      <input
        onFocus={() => setLabelOnTop(true)}
        onBlur={() => {
          if (value == '') {
            setLabelOnTop(false);
          } else {
            setLabelOnTop(true);
          }
        }}
        type="date"
        id={label}
        name={name}
        value={value}
        onChange={handleChange}
        placeholder={labelOnTop && placeholder}
        className="input-date"
      />
    </div>
  );
}
