import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useEffect, useState, useRef } from 'react';
import { MdLocalOffer } from 'react-icons/md';
import { RxCross2 } from 'react-icons/rx';
import { FaTrophy } from 'react-icons/fa';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/system';
import SubCart from './Subcart';
import Indicator from '../../../common/Indicators/Indicator';
import {
  DeleteRequest,
  GetRequest,
  PatchRequest,
  PostRequest,
} from '../../../components/Request';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import OrderItemsUIForWeb from '../../order/OrderItems';
import ProductFilter from './ProductFilter';
import OrderFromSpecificStore from './ProductItems';
import { RiFilter2Fill } from 'react-icons/ri';
import { FiSearch } from 'react-icons/fi';

const CssTextField = styled(TextField)({
  '& .MuiInputBase-input': {
    padding: '0.75rem 1.25rem',
  },
  '& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root': {
    fontSize: '0.90rem',
    color: '#9FA6B3',
  },
  '& .MuiOutlinedInput-root': {
    padding: '5px',
    '& fieldset': {
      borderRadius: '0.35rem',
      borderColor: '#E5E7EB',
    },
  },
});

export default function Products(_props: any) {
  const [showIndicator, setShowIndicator] = useState(false);
  const [message, setMessage] = useState('');
  const [type, setType] = useState('');
  const [bag, setBag] = useState<any>({
    buyerType: 'Store',
    sellerStoreId: '',
    brandId: '',
    itemList: [],
    totalItemList: 0,
  });
  const [msg, setMsg] = useState(
    'Please select either only seller or brand and seller to see the products'
  );
  const [activeBrand, setActiveBrand] = useState<any>({});
  const [activeDistributor, setActiveDistributor] = useState<any>({});
  const [listsOfBrand, setListsOfBrand] = useState<any>([]);
  const [listsOfDistributors, setListsOfDistributors] = useState<any>([]);
  const [isMouseOverMinusButton, setIsMouseOverMinusButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [lastPageNumber, setLastPageNumber] = useState<any>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [limit, setLimit] = useState(6);
  const [mainArray, setMainArray] = useState([]);
  const [products, setProducts] = useState<any>([]);
  const endOfListRef = useRef(null);
  const offerPopUpRef = useRef<any>();
  const priceTiersPopUpRef = useRef<any>();
  const [isFetching, setIsFetching] = useState(false);
  const [isSellerOpen, setIsSellerOpen] = useState(false);
  const [isBrandOpen, setIsBrandOpen] = useState(false);
  const sellerRef = useRef<any>(null);
  const [observer, setObserver] = useState<any>(null);
  const [brandObserver, setBrandObserver] = useState<any>(null);
  const brandRef = useRef<any>(null);
  const brandRefForCloseOnClickingOutside = useRef<any>(null);
  const sellerRefForCloseOnClickingOutside = useRef<any>(null);
  const [inputValueForSeller, setInputValueForSeller] = useState('');
  const [inputValueForBrand, setInputValueForBrand] = useState('');
  const [pageNumberForSeller, setPageNumberForSeller] = useState(1);
  const [pageNumberForBrand, setPageNumberForBrand] = useState(1);
  const [stopInfiniteScrolling, setStopInfiniteScrolling] = useState(false);
  const [stopInfiniteScrollingForBrand, setStopInfiniteScrollingForBrand] =
    useState(false);
  const [showPlaceOrder, setShowPlaceOrder] = useState<any>({});
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [orderIdConvertIntoPending, setOrderIdConvertIntoPending] =
    useState('');
  const [showFilterOnLoad, setshowFilterOnLoad] = useState(true);
  const [editOrderedItem, setEditOrderedItem] = useState(false);
  const [orderFromSpecificStore, setOrderFromSpecificStore] = useState<any>({});
  const [errorForFilter, setErrorForFilter] = useState('');
  const productListAndSearchBarRef = useRef<any>(null);
  const productListRefForInfiniteScrollingInSearchBar = useRef<any>(null);
  const [allProductInDropDown, setAllProductInDropDown] = useState<any>([]);
  const [searchedValueForProduct, setSearchedValueForProduct] = useState('');
  // category starts here
  const [pageNumberForCategory, setPageNumberForCategory] = useState<any>(1);
  const [listsOfCategory, setListsOfCategory] = useState<any>([]);
  const [
    stopInfiniteScrollingForCategory,
    setStopInfiniteScrollingForCategory,
  ] = useState<any>(false);
  const [isCategoryOpen, setIsCategoryOpen] = useState<any>(false);
  const [inputValueForCategory, setInputValueForCategory] = useState<any>('');
  const [activeCategory, setActiveCategory] = useState<any>({});
  const categoryRef = useRef<any>(null);
  const categoryRefForCloseOnClickingOutside = useRef<any>(null);
  //
  const [userWantToSeeOnlyStoreProduct, setUserWantToSeeOnlyStoreProduct] =
    useState<Boolean>(true);
  const [
    userWantToSeeOnlySellerSellingInHisArea,
    setUserWantToSeeOnlySellerSellingInHisArea,
  ] = useState<Boolean>(true);
  // sub category starts
  const [activeSubCategory, setActiveSubCategory] = useState<any>({});
  const [listsOfSubCategory, setListsOfSubCategory] = useState<any>([]);
  const [showSubCategory, setShowSubCategory] = useState<Boolean>(false);
  const [inputValueForSubCategory, setInputValueForSubCategory] =
    useState<string>('');
  const subCategoryRef = useRef<any>(null);

  const handleClickOutsideForSubCategory = (event: any) => {
    if (
      subCategoryRef.current &&
      !subCategoryRef.current.contains(event.target)
    ) {
      setShowSubCategory(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideForSubCategory);
    return () => {
      document.removeEventListener(
        'mousedown',
        handleClickOutsideForSubCategory
      );
    };
  }, []);

  const handleInputChangeForSubCategory = (event: any) => {
    let { value } = event.target;

    // find the from the list of subcategory
  };

  const handleActiveSubCategory = (data: any) => {
    setActiveSubCategory(() => data);
    setShowSubCategory(false);
  };

  const handleClickOnSeeSubcategory = () => {
    setShowSubCategory(true);
  };

  // sub category ends here

  const seeActiveStoreAvailableProduct = (event: any) => {
    setUserWantToSeeOnlyStoreProduct(() => !userWantToSeeOnlyStoreProduct);
  };

  const handleClickOutsideForCategory = (event: any) => {
    if (
      categoryRefForCloseOnClickingOutside.current &&
      !categoryRefForCloseOnClickingOutside.current.contains(event.target)
    ) {
      setIsCategoryOpen(false);
    }
  };

  const handleActiveCategory = async (data: any) => {
    setActiveCategory(() => {
      return { name: data.name, id: data._id };
    });
    setActiveSubCategory({});
    setInputValueForCategory(data.name);
    await setListsOfSubCategory(() => data.subCategoryList);
    setIsCategoryOpen(false);
    setPageNumberForCategory(1);
    setListsOfCategory([]);
  };

  const onIntersectionForCategory = async (entries: any) => {
    const firstEntry = entries[0];

    if (
      firstEntry.isIntersecting &&
      !stopInfiniteScrollingForCategory &&
      isCategoryOpen
    ) {
      setPageNumberForCategory((prevPageNumber: any) => prevPageNumber + 1);
    }
  };

  useEffect(() => {
    if (categoryRef.current) {
      const observer = new IntersectionObserver(onIntersectionForCategory);
      observer.observe(categoryRef.current);
      return () => {
        observer.disconnect();
      };
    }
  }, [listsOfCategory.length > 0, stopInfiniteScrollingForCategory]);

  useEffect(() => {
    if (isCategoryOpen) {
      fetchCategory();
    }
  }, [pageNumberForCategory]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideForCategory);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideForCategory);
    };
  }, []);

  const handleInputChangeForCategory = async (event: any) => {
    const value = event.target.value;
    setInputValueForCategory(value);
    setActiveCategory({});
    setListsOfCategory([]);
    setActiveSubCategory({});
    setListsOfSubCategory([]);
    setShowSubCategory(false);

    let url = value
      ? `category?type=Product&name=${value}&page=${pageNumberForCategory}&limit=100`
      : `category?type=Product&page=${pageNumberForCategory}&limit=100`;
    await GetRequest(url)
      .then(async (res) => {
        if (res.data.statusCode == 200) {
          setListsOfCategory((prevState: any) => {
            return res.data.data;
          });
          setIsCategoryOpen(true);
          if (res.data.data.length === 0 || res.data.data.length < 5) {
            return await setStopInfiniteScrollingForCategory(true);
          } else if (res.data.data.length === 5) {
            setStopInfiniteScrollingForCategory(false);
          }
        }
      })
      .catch((err) => {
        setIsCategoryOpen(false);
        // console.log(err, 'this is err in fetching brands');
      });
  };

  const fetchCategory = async () => {
    let url = '';
    url = inputValueForCategory
      ? `category?name=${inputValueForCategory}&type=Product&page=${pageNumberForCategory}&limit=100`
      : `category?type=Product&page=${pageNumberForCategory}&limit=100`;
    await GetRequest(url)
      .then(async (res) => {
        if (res.status == 200) {
          setListsOfCategory(res.data.data);
          setIsCategoryOpen(true);

          if (res.data.data.length === 0 || res.data.data.length < 5) {
            return await setStopInfiniteScrollingForCategory(true);
          } else if (res.data.data.length === 5) {
            setStopInfiniteScrollingForCategory(false);
          }
        }
      })
      .catch((err) => {
        console.log(err, 'this is err in fetching category for the first');
      });
  };

  // category ends here

  const handleChangeOnSearchedValue = (event: any) => {
    let { value } = event.target;
    setSearchedValueForProduct(value);
    setPageNumber(1);

    if (value === '') {
      setAllProductInDropDown([]);
    }
  };

  const getProductBasedOnSearchValue = () => {};

  const handleClickOnProductInDropDown = (data: any) => {};

  // seller starts here

  const handleClickOutsideForSeller = (event: any) => {
    if (
      sellerRefForCloseOnClickingOutside.current &&
      !sellerRefForCloseOnClickingOutside.current.contains(event.target)
    ) {
      setIsSellerOpen(false);
      setPageNumberForSeller(1);
      setStopInfiniteScrolling(false);
      setListsOfDistributors([]);
    }
  };

  const fetchSeller = async () => {
    let url = '';

    if (activeBrand?.id) {
      url = `store?seller=true&seller-store-in-buyer-area=true&page=${pageNumberForSeller}&limit=5&brand=${activeBrand.id}`;
    } else if (inputValueForSeller) {
      url = `store?name=${inputValueForSeller}&seller=true&seller-store-in-buyer-area=true&page=${pageNumberForSeller}&limit=5`;
    } else if (activeBrand.id && inputValueForSeller) {
      url = `store?name=${inputValueForSeller}&seller=true&seller-store-in-buyer-area=true&page=${pageNumberForSeller}&limit=5&brand=${activeBrand.id}`;
    } else {
      url = `store?seller=true&seller-store-in-buyer-area=true&page=${pageNumberForSeller}&limit=5`;
    }

    if (userWantToSeeOnlySellerSellingInHisArea) {
      url = url + '&seller-store-in-buyer-area-only=true';
    }

    await GetRequest(url)
      .then(async (res) => {
        if (res.status == 200) {
          let response = await res.data.data.map((store: any) => {
            return { storeId: store._id, storeName: store.name };
          });
          setListsOfDistributors((prevState: any) => {
            return prevState.concat(response);
          });
          await setIsSellerOpen(true);
          if (res.data.data.length === 0 || res.data.data.length < 5) {
            return await setStopInfiniteScrolling(true);
          } else if (res.data.data.length === 5) {
            return setStopInfiniteScrolling(false);
          }
        }
      })
      .catch((err) => {
        console.log(err, 'this is err in fetching brand for the first');
      });
  };

  const onIntersectionForSeller = async (entries: any) => {
    const firstEntry = entries[0];

    if (firstEntry.isIntersecting && !stopInfiniteScrolling && isSellerOpen) {
      setPageNumberForSeller((prevPageNumber: any) => prevPageNumber + 1);
    }
  };

  useEffect(() => {
    if (sellerRef.current) {
      const observer = new IntersectionObserver(onIntersectionForSeller);
      observer.observe(sellerRef.current);
      return () => {
        observer.disconnect();
      };
    }
  }, [listsOfDistributors.length > 0, stopInfiniteScrolling]);

  useEffect(() => {
    if (isSellerOpen) {
      fetchSeller();
    }
  }, [pageNumberForSeller]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideForSeller);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideForSeller);
    };
  }, []);

  const handleInputChangeForSeller = async (event: any) => {
    const value = event.target.value;
    let url = '';
    setInputValueForSeller(value);
    setActiveDistributor({});
    setListsOfDistributors([]);
    setUserWantToSeeOnlyStoreProduct(false);

    if (activeBrand?.id && value) {
      url = `store?name=${value}&seller=true&seller-store-in-buyer-area=true&page=${pageNumberForSeller}&limit=5&brand=${activeBrand.id}`;
    } else if (value) {
      url = `store?name=${value}&seller=true&seller-store-in-buyer-area=true&page=${pageNumberForSeller}&limit=5`;
    } else if (activeBrand.id && !value) {
      url = `store?seller=true&seller-store-in-buyer-area=true&page=${pageNumberForSeller}&limit=5&brand=${activeBrand.id}`;
    } else {
      url = `store?seller=true&seller-store-in-buyer-area=true&page=${pageNumberForSeller}&limit=5`;
    }

    if (userWantToSeeOnlySellerSellingInHisArea) {
      url = url + '&seller-store-in-buyer-area-only=true';
    }

    // let url = value
    //   ? `store?name=${value}&page=${pageNumberForSeller}&limit=5`
    //   : `store?page=${pageNumberForSeller}&limit=5`;
    await GetRequest(url)
      .then(async (res) => {
        if (res.data.statusCode == 200) {
          let response = await res.data.data.map((store: any) => {
            return { storeId: store._id, storeName: store.name };
          });
          setListsOfDistributors((prevState: any) => {
            return response;
          });
          if (res.data.data.length === 0 || res.data.data.length < 5) {
            return await setStopInfiniteScrolling(true);
          } else if (res.data.data.length === 5) {
            setStopInfiniteScrolling(false);
            return setIsSellerOpen(true);
          }
        }
      })
      .catch((err) => {
        setIsSellerOpen(false);
        // console.log(err, 'this is err in fetching brands');
      });
  };

  useEffect(() => {
    if (Object.keys(showPlaceOrder).length == 0) {
      getProducts();
    }
  }, [showPlaceOrder]);

  const handleActiveDistributor = async (sellerInfo: any) => {
    setActiveDistributor({
      storeName: sellerInfo.storeName,
      storeId: sellerInfo.storeId,
    });
    setInputValueForSeller(sellerInfo.storeName);
    setIsSellerOpen(false);
    setPageNumberForSeller(1);
    setListsOfDistributors([]);
    setErrorForFilter('');
    setMsg('');
  };

  // seller ends here

  // brand starts here

  const handleClickOutsideForBrand = (event: any) => {
    if (
      brandRefForCloseOnClickingOutside.current &&
      !brandRefForCloseOnClickingOutside.current.contains(event.target)
    ) {
      setIsBrandOpen(false);
      setPageNumberForBrand(1);
      setListsOfBrand([]);
      setStopInfiniteScrollingForBrand(false);
    }
  };

  const fetchBrand = async () => {
    let url = '';

    if (activeDistributor.storeId) {
      url = `brand?seller=true&buyer-seller-store=true&page=${pageNumberForBrand}&limit=5&seller-store=${activeDistributor.storeId}`;
    } else if (inputValueForBrand) {
      url = `brand?name=${inputValueForBrand}&seller=true&buyer-seller-store=true&page=${pageNumberForBrand}&limit=5`;
    } else if (activeDistributor.storeId && inputValueForBrand) {
      url = `brand?name=${inputValueForBrand}&seller=true&buyer-seller-store=true&page=${pageNumberForBrand}&limit=5&seller-store=${activeDistributor.storeId}&`;
    } else {
      url = `brand?seller=true&buyer-seller-store=true&page=${pageNumberForBrand}&limit=5`;
    }

    await GetRequest(url)
      .then(async (res) => {
        if (res.data.statusCode === 200) {
          let response = await res.data.data.map((brand: any) => {
            return { id: brand._id, name: brand.name };
          });
          setListsOfBrand((prevState: any) => {
            return prevState.concat(response);
          });
          setIsBrandOpen(true);
          if (res.data.data.length === 0 || res.data.data.length < 5) {
            await setStopInfiniteScrollingForBrand(true);
          } else if (res.data.data.length === 5) {
            await setStopInfiniteScrollingForBrand(false);
          }
        }
      })
      .catch((err) => {
        console.log(err, 'this is err in fetching brand for the first');
      });
  };

  const onIntersectionForBrand = async (entries: any) => {
    const firstEntry = entries[0];

    if (
      firstEntry.isIntersecting &&
      !stopInfiniteScrollingForBrand &&
      isBrandOpen
    ) {
      setPageNumberForBrand((prevPageNumber: any) => prevPageNumber + 1);
    }
  };

  useEffect(() => {
    if (brandRef.current) {
      const observer = new IntersectionObserver(onIntersectionForBrand);
      observer.observe(brandRef.current);
      return () => {
        observer.disconnect();
      };
    }
  }, [listsOfBrand.length > 0, stopInfiniteScrollingForBrand]);

  useEffect(() => {
    if (isBrandOpen) {
      fetchBrand();
    }
  }, [pageNumberForBrand]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideForBrand);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideForBrand);
    };
  }, []);

  const handleInputChangeForBrand = async (event: any) => {
    const value = event.target.value;
    setInputValueForBrand(value);
    setActiveBrand({});
    setListsOfBrand([]);
    let url = value
      ? `brand?name=${value}&pageNumber=${pageNumberForBrand}&limit=5`
      : `brand?pageNumber=${pageNumberForBrand}&limit=5`;
    await GetRequest(url)
      .then(async (res) => {
        if (res.status == 200) {
          let response = await res.data.data.map((brand: any) => {
            return { id: brand._id, name: brand.name };
          });
          setListsOfBrand((prevState: any) => {
            return response;
          });
          if (res.data.data.length === 0 || res.data.data.length < 5) {
            return await setStopInfiniteScrollingForBrand(true);
          } else if (res.data.data.length === 5) {
            setStopInfiniteScrollingForBrand(false);
            return setIsBrandOpen(true);
          }
        }
      })
      .catch((err) => {
        setIsBrandOpen(false);
        console.log(err, 'this is err in fetching brands');
      });
  };

  const handleActiveBrand = async (brandInfo: any) => {
    setActiveBrand({
      id: brandInfo.id,
      name: brandInfo.name,
    });
    setInputValueForBrand(brandInfo.name);
    setIsBrandOpen(false);
    setListsOfBrand([]);
    setPageNumberForBrand(1);
    setMsg('');
  };

  // brand end here

  const calculateTotalPrice = (bag: any) => {
    let total = 0;

    bag.itemList.forEach((i: any) => {
      total += Number(i.totalPrice);
    });

    return total.toFixed(2);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 480) {
        setLimit(3);
      } else {
        setLimit(6);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const showTotalPrice = (product: any, selectedOption: any) => {
    if (selectedOption === 'piece') {
      if (product.sellerProductDetail.pricePerUnit.piece.unique) {
        return product.quantity !== undefined
          ? (
              product.quantity *
              product.sellerProductDetail.pricePerUnit.piece.price
            ).toFixed(2)
          : (
              product.sellerProductDetail.minimumQuantity.piece.quantity *
              product.sellerProductDetail.pricePerUnit.piece.price
            ).toFixed(2);
      } else {
        const quantity =
          product.sellerProductDetail.minimumQuantity.piece.quantity;
        const quantityWisePriceList =
          product.sellerProductDetail.pricePerUnit.piece.quantityWisePriceList;

        let totalPrice = 0;
        for (const priceObj of quantityWisePriceList) {
          const { price, moreThanOrEqual, lessThanOrEqual } = priceObj;

          if (quantity >= moreThanOrEqual && quantity <= lessThanOrEqual) {
            const quantityInRange = quantity - moreThanOrEqual + 1;
            totalPrice += quantityInRange * price;
          } else if (quantity > lessThanOrEqual) {
            const quantityInRange = lessThanOrEqual - moreThanOrEqual + 1;
            totalPrice += quantityInRange * price;
          }
        }

        return totalPrice.toFixed(2);
      }
    } else if (selectedOption === 'bundle') {
      if (product?.sellerProductDetail?.pricePerUnit?.bundle?.unique) {
        return product?.quantity !== undefined
          ? (
              product.quantity *
              product?.info?.pieceInEachBundle *
              product.sellerProductDetail.pricePerUnit.bundle.price
            ).toFixed(2)
          : (
              product.sellerProductDetail.minimumQuantity.bundle.quantity *
              product?.info?.pieceInEachBundle *
              product.sellerProductDetail.pricePerUnit.bundle.price
            ).toFixed(2);
      } else {
        const quantity =
          product.sellerProductDetail?.minimumQuantity?.bundle?.quantity;
        const quantityWisePriceList =
          product.sellerProductDetail?.pricePerUnit?.bundle
            ?.quantityWisePriceList;

        let totalPrice = 0;
        if (quantityWisePriceList && quantityWisePriceList.length > 0) {
          for (const priceObj of quantityWisePriceList) {
            const { price, moreThanOrEqual, lessThanOrEqual } = priceObj;

            if (quantity >= moreThanOrEqual && quantity <= lessThanOrEqual) {
              const quantityInRange = quantity - moreThanOrEqual + 1;
              totalPrice += quantityInRange * price;
            } else if (quantity > lessThanOrEqual) {
              const quantityInRange = lessThanOrEqual - moreThanOrEqual + 1;
              totalPrice += quantityInRange * price;
            }
          }
        }

        return totalPrice.toFixed(2);
      }
    } else {
      if (product.sellerProductDetail.pricePerUnit.box.unique) {
        return product?.quantity !== undefined
          ? (
              product.quantity *
              product.sellerProductDetail.pricePerUnit.box.price
            ).toFixed(2)
          : (
              product.sellerProductDetail.minimumQuantity.box.quantity *
              product.sellerProductDetail.pricePerUnit.box.price
            ).toFixed(2);
      } else {
        const quantity =
          product.sellerProductDetail.minimumQuantity.box.quantity;
        const quantityWisePriceList =
          product.sellerProductDetail.pricePerUnit.box.quantityWisePriceList;

        let totalPrice = 0;
        for (const priceObj of quantityWisePriceList) {
          const { price, moreThanOrEqual, lessThanOrEqual } = priceObj;

          if (quantity >= moreThanOrEqual && quantity <= lessThanOrEqual) {
            const quantityInRange = quantity - moreThanOrEqual + 1;
            totalPrice += quantityInRange * price;
          } else if (quantity > lessThanOrEqual) {
            const quantityInRange = lessThanOrEqual - moreThanOrEqual + 1;
            totalPrice += quantityInRange * price;
          }
        }

        return totalPrice.toFixed(2);
      }
    }
  };

  const lastPriceOnWhichTotalPriceGettingCalculated = (
    product: any,
    selectedOption: any,
    quantity: any
  ) => {
    let lastPrice: number = 0;

    if (product.sellerProductDetail?.pricePerUnit[selectedOption]?.unique) {
      lastPrice =
        product.sellerProductDetail?.pricePerUnit[selectedOption].price;
    } else {
      if (
        product.sellerProductDetail?.pricePerUnit[selectedOption]
          ?.quantityWisePriceList
      ) {
        for (const { price, moreThanOrEqual, lessThanOrEqual } of product
          .sellerProductDetail?.pricePerUnit[selectedOption]
          ?.quantityWisePriceList) {
          if (
            quantity >= moreThanOrEqual &&
            quantity <= (lessThanOrEqual ?? Number.POSITIVE_INFINITY)
          ) {
            lastPrice = price;
          }
        }
      }
    }

    return lastPrice;
  };

  const lastPriceInBag = (product: any, selectedOption: any) => {
    if (selectedOption === 'piece') {
      if (product.sellerProductDetail.pricePerUnit.piece.unique) {
        return product.sellerProductDetail.pricePerUnit.piece.price;
      } else {
        let lastPrice;
        const quantity = product.quantity.pieceWise;

        const quantityWisePriceList =
          product.sellerProductDetail?.pricePerUnit?.piece
            ?.quantityWisePriceList;
        if (quantityWisePriceList) {
          for (const priceObj of quantityWisePriceList) {
            const { price, moreThanOrEqual, lessThanOrEqual } = priceObj;

            if (quantity >= moreThanOrEqual && quantity <= lessThanOrEqual) {
              lastPrice = price;
            }
          }
        }

        return lastPrice;
      }
    } else if (selectedOption === 'bundle') {
      if (product?.sellerProductDetail?.pricePerUnit?.bundle?.unique) {
        return product.sellerProductDetail.pricePerUnit.bundle.price;
      } else {
        let lastPrice;
        const quantity = product.quantity.bundleWise;

        const quantityWisePriceList =
          product.sellerProductDetail?.pricePerUnit?.bundle
            ?.quantityWisePriceList;
        if (quantityWisePriceList) {
          for (const priceObj of quantityWisePriceList) {
            const { price, moreThanOrEqual, lessThanOrEqual } = priceObj;

            if (quantity >= moreThanOrEqual && quantity <= lessThanOrEqual) {
              lastPrice = price;
            }
          }
        }

        return lastPrice;
      }
    } else if (selectedOption === 'box') {
      if (product.sellerProductDetail?.pricePerUnit?.box?.unique) {
        return product.sellerProductDetail.pricePerUnit.box.price;
      } else {
        let lastPrice;
        const quantity = product.quantity.boxWise;

        const quantityWisePriceList =
          product.sellerProductDetail?.pricePerUnit?.box?.quantityWisePriceList;
        if (quantityWisePriceList) {
          for (const priceObj of quantityWisePriceList) {
            const { price, moreThanOrEqual, lessThanOrEqual } = priceObj;

            if (quantity >= moreThanOrEqual && quantity <= lessThanOrEqual) {
              lastPrice = price;
            }
          }
        }

        return lastPrice;
      }
    }
  };

  // update product only for the product list only
  const updateProduct = async (
    orderId: any,
    productId: any,
    product: any,
    quantityInPiece: any,
    indexOfProduct: any
  ) => {
    await PatchRequest(`order/${orderId}/product/${productId}`, {
      quantityInPiece: quantityInPiece,
    })
      .then(async (res: any) => {
        let updatedProducts = [...products];
        if (res.data.statusCode == 200) {
          const updatedProductsCopy = [...updatedProducts];
          updatedProductsCopy[indexOfProduct].pendingOrderDetail =
            res.data.data;

          setProducts(updatedProductsCopy);
          const newBag: any = {
            ...bag,
            itemList: bag.itemList.map((item: any) => {
              if (
                updatedProductsCopy[indexOfProduct].sellerProductDetail._id ==
                item.sellerProductDetail._id
              ) {
                return {
                  ...item,
                  product: updatedProductsCopy[indexOfProduct],
                  sellerProductDetail:
                    updatedProducts[indexOfProduct].sellerProductDetail,
                  quantity: res.data.data.quantity,
                  totalPrice: updatedProductsCopy[indexOfProduct].totalPrice,
                };
              } else {
                return item;
              }
            }),
          };
          newBag.totalAmount = calculateTotalPrice(newBag);
          setBag(newBag);
          setTimeout(() => {
            let updateProductsOnceMore = [...products];
            updateProductsOnceMore[indexOfProduct].addedToCart = 'added';
            setProducts(updateProductsOnceMore);
          }, 200);
        }
      })
      .catch((err) => {
        console.log(err, 'err in updating the product');
        if (err.response.data.statusCode >= 400) {
          setMessage(err.response.data.message);
          setType('error');
          setShowIndicator(true);
          setTimeout(() => {
            setShowIndicator(false);
          }, 3000);
        }
      });
  };

  // update product only for the bag's option only
  const updateProductForOption = async (
    orderId: any,
    productId: any,
    product: any,
    quantityInPiece: any,
    indexOfProduct: any
  ) => {
    await PatchRequest(`order/${orderId}/product/${productId}`, {
      quantityInPiece: quantityInPiece,
    })
      .then(async (res: any) => {
        let updatedProducts = [...products];
        if (res.data.statusCode == 200) {
          const updatedProductsCopy = [...updatedProducts];
          let { boxWise, bundleWise } = res.data.data.quantity;
          updatedProductsCopy[indexOfProduct].pendingOrderDetail =
            res.data.data;

          if (boxWise) {
            updatedProductsCopy[indexOfProduct].selectedOption = 'box';
            updatedProductsCopy[indexOfProduct].quantity =
              updatedProducts[
                indexOfProduct
              ].sellerProductDetail.minimumQuantity.box.quantity;
            updatedProductsCopy[
              indexOfProduct
            ].lastPriceOnWhichTotalPriceGettingCalculated =
              await lastPriceOnWhichTotalPriceGettingCalculated(
                updatedProductsCopy[indexOfProduct],
                'box',
                updatedProductsCopy[indexOfProduct].quantity
              );
            updatedProductsCopy[indexOfProduct].totalPrice =
              await showTotalPrice(product, 'box');
          } else if (bundleWise) {
            updatedProductsCopy[indexOfProduct].selectedOption = 'bundle';
            updatedProductsCopy[indexOfProduct].quantity =
              updatedProducts[
                indexOfProduct
              ].sellerProductDetail.minimumQuantity.bundle.quantity;
            updatedProductsCopy[
              indexOfProduct
            ].lastPriceOnWhichTotalPriceGettingCalculated =
              lastPriceOnWhichTotalPriceGettingCalculated(
                product,
                'bundle',
                updatedProductsCopy[indexOfProduct].quantity
              );
            updatedProductsCopy[indexOfProduct].totalPrice = showTotalPrice(
              product,
              'bundle'
            );
          } else {
            updatedProductsCopy[indexOfProduct].selectedOption = 'piece';
            updatedProductsCopy[indexOfProduct].quantity =
              updatedProducts[
                indexOfProduct
              ].sellerProductDetail.minimumQuantity.piece.quantity;
            updatedProductsCopy[
              indexOfProduct
            ].lastPriceOnWhichTotalPriceGettingCalculated =
              lastPriceOnWhichTotalPriceGettingCalculated(
                product,
                'piece',
                updatedProductsCopy[indexOfProduct].quantity
              );
            updatedProductsCopy[indexOfProduct].totalPrice = showTotalPrice(
              product,
              'piece'
            );
          }
          setProducts(updatedProductsCopy);
          const newBag: any = {
            ...bag,
            itemList: bag.itemList.map((item: any) => {
              if (
                updatedProductsCopy[indexOfProduct].sellerProductDetail._id ==
                item.sellerProductDetail._id
              ) {
                return {
                  ...item,
                  product: updatedProductsCopy[indexOfProduct],
                  sellerProductDetail:
                    updatedProducts[indexOfProduct].sellerProductDetail,
                  quantity: res.data.data.quantity,
                  totalPrice: updatedProductsCopy[indexOfProduct].totalPrice,
                };
              } else {
                return item;
              }
            }),
          };
          newBag.totalAmount = calculateTotalPrice(newBag);
          setBag(newBag);
          setTimeout(() => {
            let updateProductsOnceMore = [...products];
            updateProductsOnceMore[indexOfProduct].addedToCart = 'added';
            setProducts(updateProductsOnceMore);
          }, 200);
        }
      })
      .catch((err) => {
        console.log(err, 'err in updating the product');
        if (err.response.data.statusCode >= 400) {
          setMessage(err.response.data.message);
          setType('error');
          setShowIndicator(true);
          setTimeout(() => {
            setShowIndicator(false);
          }, 3000);
        }
      });
  };

  // update product only for the bag's quantity
  const updateProductForQuantity = async (
    orderId: any,
    productId: any,
    product: any,
    quantityInPiece: any,
    indexOfProduct: any
  ) => {
    await PatchRequest(`order/${orderId}/product/${productId}`, {
      quantityInPiece: quantityInPiece,
    })
      .then(async (res: any) => {
        if (res.data.statusCode == 200) {
          product.pendingOrderDetail = res.data.data;
          product.totalPrice = res.data.data.totalPrice.toFixed(2);
          setProducts((prevState: any) =>
            prevState.map((data: any, index: Number) => {
              if (index == indexOfProduct) {
                return product;
              } else {
                return data;
              }
            })
          );
          const newBag: any = {
            ...bag,
            itemList: bag.itemList.map((item: any) => {
              if (
                product.sellerProductDetail._id == item.sellerProductDetail._id
              ) {
                return {
                  ...item,
                  product: product,
                  sellerProductDetail: product.sellerProductDetail,
                  quantity: res.data.data.quantity,
                  totalPrice: product.totalPrice,
                };
              } else {
                return item;
              }
            }),
          };
          newBag.totalAmount = calculateTotalPrice(newBag);
          setBag(newBag);
          setTimeout(() => {
            let updateProductsOnceMore = [...products];
            updateProductsOnceMore[indexOfProduct].addedToCart = 'added';
            setProducts(updateProductsOnceMore);
          }, 200);
        }
      })
      .catch((err) => {
        console.log(err, 'err in updating the product');
        if (err.response.data.statusCode >= 400) {
          setMessage(err.response.data.message);
          setType('error');
          setShowIndicator(true);
          setTimeout(() => {
            setShowIndicator(false);
          }, 3000);
        }
      });
  };

  const lastPrice = (product: any, selectedOption: any) => {
    if (selectedOption === 'piece') {
      if (product.sellerProductDetail.pricePerUnit.piece.unique) {
        return product.sellerProductDetail.pricePerUnit.piece.price;
      } else {
        let lastPrice;
        const quantity = product.quantity.pieceWise;

        const quantityWisePriceList =
          product.sellerProductDetail.pricePerUnit.piece.quantityWisePriceList;

        for (const priceObj of quantityWisePriceList) {
          const { price, moreThanOrEqual, lessThanOrEqual } = priceObj;

          if (quantity >= moreThanOrEqual && quantity <= lessThanOrEqual) {
            lastPrice = price;
          }
        }

        return lastPrice;
      }
    } else if (selectedOption === 'bundle') {
      if (product.sellerProductDetail.pricePerUnit.bundle.unique) {
        return product.sellerProductDetail.pricePerUnit.bundle.price;
      } else {
        let lastPrice;
        const quantity = product.quantity.bundleWise;

        const quantityWisePriceList =
          product.sellerProductDetail.pricePerUnit.bundle.quantityWisePriceList;

        for (const priceObj of quantityWisePriceList) {
          const { price, moreThanOrEqual, lessThanOrEqual } = priceObj;

          if (quantity >= moreThanOrEqual && quantity <= lessThanOrEqual) {
            lastPrice = price;
          }
        }

        return lastPrice;
      }
    } else if (selectedOption === 'box') {
      if (product.sellerProductDetail.pricePerUnit.box.unique) {
        return product.sellerProductDetail.pricePerUnit.box.price;
      } else {
        let lastPrice;
        const quantity = product.quantity.boxWise;

        const quantityWisePriceList =
          product.sellerProductDetail.pricePerUnit.box.quantityWisePriceList;

        for (const priceObj of quantityWisePriceList) {
          const { price, moreThanOrEqual, lessThanOrEqual } = priceObj;

          if (quantity >= moreThanOrEqual && quantity <= lessThanOrEqual) {
            lastPrice = price;
          }
        }

        return lastPrice;
      }
    }
  };

  const fetchOrderInOrderedState = async () => {
    return await GetRequest(
      `order?status=Ordered&seller-store=${activeDistributor.storeId}&order-detail=true&seller-product-detail=true&seller-store-detail=true`
    )
      .then((res) => {
        if (res.data.statusCode === 200) {
          let obj = res.data.data[0];

          // && {
          //   buyerType: 'Store',
          //   sellerStoreId: '',
          //   brandId: '',
          //   itemList: [],
          //   totalItemList: 0,
          // };

          // if (Object.keys(obj).length > 0) {

          obj.itemList =
            obj?.itemList?.length > 0
              ? obj?.itemList.map((p: any) => {
                  let selectedOption;
                  let quantity;
                  if (p.quantity.boxWise) {
                    selectedOption = 'box';
                    quantity = p.quantity.boxWise;
                  } else if (p.quantity.bundleWise) {
                    selectedOption = 'bundle';
                    quantity = p.quantity.bundleWise;
                  } else {
                    selectedOption = 'piece';
                    quantity = p.quantity.pieceWise;
                  }
                  return {
                    ...p,
                    totalPrice: p.totalPrice.toFixed(2),
                    product: {
                      ...p.product,
                      selectedOption,
                      quantity,
                      lastPriceOnWhichTotalPriceGettingCalculated: lastPrice(
                        p,
                        selectedOption
                      ),
                    },
                  };
                })
              : [];
          // }
          setOrderFromSpecificStore(obj);

          console.log(obj, res.data.data, 'this is the object');

          if (res.data.data.length == 0) {
            return false;
          } else {
            return res.data.data[0].status == 'Pending' ? false : true;
          }
        }
      })
      .catch((err) => {
        console.log(err, 'getting err');
      });
  };

  const fetchPendingOrder = async (storeId: any) => {
    await GetRequest(
      `order?seller-store=${storeId}&status=Pending&order-detail=true&seller-product-detail=true`
    )
      .then(async (res) => {
        if (res.data.statusCode == 200) {
          if (res.data.data.length == 0) {
            await fetchOrderInOrderedState();
          } else {
            let obj =
              res.data.data.length > 0
                ? res.data.data[0]
                : {
                    buyerType: 'Store',
                    sellerStoreId: '',
                    brandId: '',
                    itemList: [],
                    totalItemList: 0,
                    totalAmount: 0,
                  };

            obj.itemList = obj.itemList.map((p: any) => {
              let selectedOption;
              if (p.quantity.boxWise) {
                selectedOption = 'box';
              } else if (p.quantity.bundleWise) {
                selectedOption = 'bundle';
              } else {
                selectedOption = 'piece';
              }
              return {
                ...p,
                totalPrice: p.totalPrice.toFixed(2),
                product: {
                  ...p.product,
                  lastPriceOnWhichTotalPriceGettingCalculated: lastPriceInBag(
                    p,
                    selectedOption
                  ),
                },
              };
            });
            obj.totalAmount = obj.totalAmount.toFixed(2);
            setBag(obj);
          }
        }
      })
      .catch((err) => {
        console.log(err, 'this is the error');
        if (err.response?.data?.statusCode >= 400) {
          setMessage(err.response.data.message);
          setType('error');
          setShowIndicator(true);
          setTimeout(() => {
            setShowIndicator(false);
          }, 3000);
        }
      });
  };

  const showSelectedOption = (product: any) => {
    if (product.pendingOrderDetail.quantity.boxWise) {
      return 'box';
    } else if (product.pendingOrderDetail.quantity.bundleWise) {
      return 'bundle';
    } else {
      return 'piece';
    }
  };

  const showQuantity = (product: any) => {
    if (product.pendingOrderDetail.quantity.boxWise) {
      return product.pendingOrderDetail.quantity.boxWise;
    } else if (product.pendingOrderDetail.quantity.bundleWise) {
      return product.pendingOrderDetail.quantity.bundleWise;
    } else {
      return product.pendingOrderDetail.quantity.pieceWise;
    }
  };

  const getProducts = async () => {
    let sellerStoreId = activeDistributor.storeId;
    let limit = 6;
    let pageNumber = 1;
    let url = `product?seller-product-detail=true&seller-store=${sellerStoreId}&pending-order-detail=true&limit=${limit}&page=${pageNumber}`;

    setLoading(true);
    if (!sellerStoreId) {
      // If sellerStoreId is not present, set the message
      setMsg('Please select one seller of your area');
    } else {
      // every filter is there.

      setMsg('');

      if (activeBrand.id) {
        url += `&brand=${activeBrand.id}`;
      }

      if (activeCategory.id) {
        url += `&category=${activeCategory.id}`;
      }

      if (activeSubCategory._id) {
        url += `&sub-category=${activeSubCategory._id}`;
      }

      if (userWantToSeeOnlyStoreProduct) {
        url += `&seller-product-only=true`;
      }

      try {
        let response: any = await GetRequest(url);
        if (response.status == 200) {
          setLastPageNumber(Math.ceil(response.data.totalCount / 8));
          if (response.data.data.length == 0) {
            return setMsg('No Products Found');
          }
          let newArrayOfProducts = [...response.data.data].flat();

          console.log(newArrayOfProducts, 'this is the new array of products');

          newArrayOfProducts = newArrayOfProducts.map((product: any) => {
            if (product.sellerProductDetail !== null) {
              if (product.sellerProductDetail?.minimumQuantity?.piece) {
                return {
                  ...product,

                  selectedOption:
                    product.pendingOrderDetail !== null
                      ? showSelectedOption(product)
                      : 'piece',
                  currentActiveImageIndex:
                    product.imageList?.length > 0 ? 0 : null,
                  quantity:
                    product.pendingOrderDetail != null
                      ? showQuantity(product)
                      : product.sellerProductDetail.minimumQuantity.piece
                          .quantity,
                  addedToCart:
                    product.pendingOrderDetail == null ? false : 'added',
                  showPricePerUnit: false,
                  showOfferList: false,
                  totalPrice:
                    product.pendingOrderDetail != null
                      ? product.pendingOrderDetail.totalPrice.toFixed(2)
                      : showTotalPrice(product, 'piece'),
                  lastPriceOnWhichTotalPriceGettingCalculated:
                    lastPriceOnWhichTotalPriceGettingCalculated(
                      product,
                      product.pendingOrderDetail !== null
                        ? showSelectedOption(product)
                        : 'piece',
                      product.pendingOrderDetail != null
                        ? showQuantity(product)
                        : product.sellerProductDetail.minimumQuantity.piece
                            .quantity
                    ),
                };
              }

              if (product?.sellerProductDetail?.minimumQuantity?.bundle) {
                return {
                  ...product,
                  currentActiveImageIndex:
                    product.imageList?.length > 0 ? 0 : null,
                  selectedOption:
                    product.pendingOrderDetail !== null
                      ? showSelectedOption(product)
                      : 'bundle',
                  quantity:
                    product.pendingOrderDetail != null
                      ? showQuantity(product)
                      : product.sellerProductDetail.minimumQuantity.bundle
                          .quantity,
                  addedToCart:
                    product.pendingOrderDetail == null ? false : 'added',
                  showPricePerUnit: false,
                  showOfferList: false,
                  totalPrice:
                    product.pendingOrderDetail != null
                      ? product.pendingOrderDetail.totalPrice.toFixed(2)
                      : showTotalPrice(product, 'bundle'),
                  lastPriceOnWhichTotalPriceGettingCalculated:
                    lastPriceOnWhichTotalPriceGettingCalculated(
                      product,
                      product.pendingOrderDetail !== null
                        ? showSelectedOption(product)
                        : 'bundle',
                      product.pendingOrderDetail != null
                        ? showQuantity(product)
                        : product.sellerProductDetail.minimumQuantity.bundle
                            .quantity
                    ),
                };
              }

              if (product?.sellerProductDetail?.minimumQuantity?.box) {
                return {
                  ...product,
                  selectedOption:
                    product.pendingOrderDetail !== null
                      ? showSelectedOption(product)
                      : 'box',
                  currentActiveImageIndex:
                    product.imageList?.length > 0 ? 0 : null,
                  quantity:
                    product.pendingOrderDetail !== null
                      ? showQuantity(product)
                      : product.sellerProductDetail.minimumQuantity.box
                          .quantity,
                  addedToCart:
                    product.pendingOrderDetail == null ? false : 'added',
                  showPricePerUnit: false,
                  showOfferList: false,
                  lastPriceOnWhichTotalPriceGettingCalculated:
                    lastPriceOnWhichTotalPriceGettingCalculated(
                      product,
                      product.pendingOrderDetail !== null
                        ? showSelectedOption(product)
                        : 'box',
                      product.pendingOrderDetail != null
                        ? showQuantity(product)
                        : product.sellerProductDetail.minimumQuantity.box
                            .quantity
                    ),
                  totalPrice:
                    product.pendingOrderDetail != null
                      ? product.pendingOrderDetail.totalPrice.toFixed(2)
                      : showTotalPrice(product, 'box'),
                };
              }
            } else {
              return {
                ...product,
                selectedOption: '',
                currentActiveImageIndex:
                  product.imageList?.length > 0 ? 0 : null,
                quantity: 0,
                addedToCart: null,
                showPricePerUnit: false,
                showOfferList: false,
                lastPriceOnWhichTotalPriceGettingCalculated: 0,
                totalPrice: 0,
              };
            }
          });

          await fetchPendingOrder(sellerStoreId);
          setProducts(newArrayOfProducts);
        }
      } catch (error) {
        console.log(error, 'this is the error while fetching products');
      }
    }
    setLoading(false);
  };

  const handleAddToBag = async (
    event: any,
    newProduct: any,
    indexOfProduct: any
  ) => {
    let orderInOrderedStatus = await fetchOrderInOrderedState();

    if (!orderInOrderedStatus) {
      let { _id } = newProduct;
      let updatedProducts = [...products];
      let selectedOption = products[indexOfProduct].selectedOption;
      let info = products[indexOfProduct].info;
      let quantityInPiece;
      // setting pieceInQuantity
      if (selectedOption == 'box') {
        quantityInPiece =
          info.pieceInEachBox * products[indexOfProduct].quantity;
      } else if (selectedOption == 'bundle') {
        quantityInPiece =
          info.pieceInEachBundle * products[indexOfProduct].quantity;
      } else {
        quantityInPiece = products[indexOfProduct].quantity;
      }
      updatedProducts[indexOfProduct].addedToCart = 'adding';
      setProducts(updatedProducts);

      // hitting post order request

      if (products[indexOfProduct].pendingOrderDetail == null) {
        await PostRequest(`order`, {
          buyerType: 'Store',
          sellerStoreId: activeDistributor.storeId,
          brandId: activeBrand.id,
          productId: products[indexOfProduct]._id,
          quantityInPiece,
        })
          .then(async (res: any) => {
            if (res.data.statusCode == 201) {
              const updatedProductsCopy = [...updatedProducts];
              let length = bag.itemList.length;
              const newBag: any = {
                _id: res.data.data.order._id,
                buyerType: 'Store',
                sellerStoreId: activeDistributor.storeId,
                brandId: activeBrand.id,
                totalItemList: bag.itemList.length + 1,
                itemList:
                  length > 0
                    ? bag.itemList.concat({
                        product: products[indexOfProduct],
                        sellerProductDetail:
                          products[indexOfProduct].sellerProductDetail,
                        quantity: res.data.data.quantity,
                        totalPrice: products[indexOfProduct].totalPrice,
                      })
                    : [
                        {
                          product: products[indexOfProduct],
                          sellerProductDetail:
                            products[indexOfProduct].sellerProductDetail,
                          quantity: res.data.data.quantity,
                          totalPrice: products[indexOfProduct].totalPrice,
                        },
                      ],
              };
              newBag.totalAmount = calculateTotalPrice(newBag);
              updatedProductsCopy[indexOfProduct].pendingOrderDetail =
                res.data.data;
              setProducts(updatedProductsCopy);
              setBag(newBag);

              setTimeout(() => {
                let updateProductsOnceMore = [...products];
                updateProductsOnceMore[indexOfProduct].addedToCart = 'added';
                setProducts(updateProductsOnceMore);
              }, 200);
            }
          })
          .catch((err) => {
            if (err.response.data.statusCode >= 400) {
              if (
                err.response.data.message ==
                'Already you have ordered, you can go to order page and click edit for updating this order'
              ) {
                setShowConfirmation(true);
                console.log(newProduct, products, 'this i');
              } else {
                setMessage(err.response.data.message);
                setType('error');
                setShowIndicator(true);
                setTimeout(() => {
                  setShowIndicator(false);
                }, 3000);
              }
            }
          });
      } else {
        await updateProduct(
          products[indexOfProduct].pendingOrderDetail.orderId
            ? products[indexOfProduct].pendingOrderDetail.orderId
            : products[indexOfProduct].pendingOrderDetail.order._id,
          products[indexOfProduct]._id,
          products[indexOfProduct],
          quantityInPiece,
          indexOfProduct
        );
      }
    }
  };

  const handleClickOnPiece = (product: any, index: any) => {
    let updatedProducts = [...products];
    updatedProducts[index].selectedOption = 'piece';
    updatedProducts[index].quantity =
      product.sellerProductDetail.minimumQuantity.piece.quantity;
    updatedProducts[index].totalPrice = showTotalPrice(product, 'piece');
    updatedProducts[index].lastPriceOnWhichTotalPriceGettingCalculated =
      lastPriceOnWhichTotalPriceGettingCalculated(
        product,
        product.selectedOption,
        updatedProducts[index].quantity
      );

    setProducts(updatedProducts);
  };

  const handleClickOnBundle = (product: any, index: any) => {
    let updatedProducts = [...products];
    updatedProducts[index].selectedOption = 'bundle';
    updatedProducts[index].quantity =
      product.sellerProductDetail.minimumQuantity.bundle.quantity;
    updatedProducts[index].totalPrice = showTotalPrice(product, 'bundle');
    updatedProducts[index].lastPriceOnWhichTotalPriceGettingCalculated =
      lastPriceOnWhichTotalPriceGettingCalculated(
        product,
        product.selectedOption,
        updatedProducts[index].quantity
      );
    setProducts(updatedProducts);
  };

  const handleClickOnBox = (product: any, index: any) => {
    let updatedProducts = [...products];
    updatedProducts[index].selectedOption = 'box';
    updatedProducts[index].quantity =
      product.sellerProductDetail.minimumQuantity.box.quantity;
    updatedProducts[index].totalPrice = showTotalPrice(product, 'box');
    updatedProducts[index].lastPriceOnWhichTotalPriceGettingCalculated =
      lastPriceOnWhichTotalPriceGettingCalculated(
        product,
        product.selectedOption,
        updatedProducts[index].quantity
      );
    setProducts(updatedProducts);
  };

  const showUpdateBag = (addedToCart: any) => {
    if (addedToCart == 'added') {
      return '✔ Update bag';
    } else if (addedToCart == 'adding') {
      return <span>✔</span>;
    } else {
      return (
        <>
          <img
            className="add_to_bag_icon"
            src="/images/add_to_cart_icon.svg"
            alt="bag"
          />
          Add To Bag
        </>
      );
    }
  };

  const removeProductFromBag = async (index: any) => {
    const orderIdToRemove = bag.itemList[index].orderId
      ? bag.itemList[index].orderId
      : bag.itemList[index].product.pendingOrderDetail.order._id;
    const productIdToRemove = bag.itemList[index].product._id;

    await DeleteRequest(`order/${orderIdToRemove}/product/${productIdToRemove}`)
      .then(async (res) => {
        if (res.data.statusCode === 200) {
          let updatedProducts = [...products];
          let newBag = { ...bag };
          updatedProducts = updatedProducts.map((p: any) => {
            if (productIdToRemove == p._id) {
              return {
                ...p,
                pendingOrderDetail: null,
                addedToCart: false,
              };
            } else {
              return p;
            }
          });
          setProducts(updatedProducts);

          newBag.itemList = newBag.itemList.filter(
            (p: any) => p.product._id != bag.itemList[index].product._id
          );
          newBag.totalItemList = newBag.totalItemList - 1;
          newBag.totalAmount = (
            newBag.totalAmount - bag.itemList[index].totalPrice
          ).toFixed(2);

          setBag(newBag);
        }
      })
      .catch((err) => {
        console.log(err, 'err in deleting from the bag');
        if (err.response.data.statusCode >= 400) {
          setMessage(err.response.data.message);
          setType('error');
          setShowIndicator(true);
          setTimeout(() => {
            setShowIndicator(false);
          }, 3000);
        }
      });
  };

  // const handleDecrement = (product: any, index: number) => {
  //   const { selectedOption, sellerProductDetail } = product;
  //   const { minimumQuantity } = sellerProductDetail;
  //   const { isMultiple, quantity } = minimumQuantity[selectedOption];

  //   const newProducts = [...products];

  //   if (newProducts[index].quantity > quantity) {
  //     const decrementValue = isMultiple ? isMultiple.number : 1;

  //     newProducts[index].quantity -= decrementValue;
  //     newProducts[index].lastPriceOnWhichTotalPriceGettingCalculated =
  //       lastPriceOnWhichTotalPriceGettingCalculated(
  //         product,
  //         selectedOption,
  //         newProducts[index].quantity
  //       );

  //     setProducts(newProducts);
  //     changeSelectedOption(product, index);
  //   }
  // };

  const handleDecrement = (product: any, index: number) => {
    let { selectedOption, id } = product;

    if (selectedOption == 'piece') {
      let { enable, number } =
        product.sellerProductDetail.minimumQuantity.piece.isMultiple;
      let newProducts = [...products];
      if (
        newProducts[index].quantity >
        product.sellerProductDetail.minimumQuantity.piece.quantity
      ) {
        if (enable) {
          newProducts[index].quantity = newProducts[index].quantity - number;
          newProducts[index].lastPriceOnWhichTotalPriceGettingCalculated =
            lastPriceOnWhichTotalPriceGettingCalculated(
              product,
              product.selectedOption,
              newProducts[index].quantity
            );
        } else {
          newProducts[index].quantity = newProducts[index].quantity - 1;
          newProducts[index].lastPriceOnWhichTotalPriceGettingCalculated =
            lastPriceOnWhichTotalPriceGettingCalculated(
              product,
              product.selectedOption,
              newProducts[index].quantity
            );
        }
      }
      setProducts(newProducts);
      changeSelectedOption(product, index);
    } else if (selectedOption == 'bundle') {
      let { enable, number } =
        product.sellerProductDetail.minimumQuantity.bundle.isMultiple;
      let newProducts = [...products];
      if (
        newProducts[index].quantity >
        product.sellerProductDetail.minimumQuantity.bundle.quantity
      ) {
        if (enable) {
          newProducts[index].quantity = newProducts[index].quantity - number;
          newProducts[index].lastPriceOnWhichTotalPriceGettingCalculated =
            lastPriceOnWhichTotalPriceGettingCalculated(
              product,
              product.selectedOption,
              newProducts[index].quantity
            );
        } else {
          newProducts[index].quantity = newProducts[index].quantity - 1;
          newProducts[index].lastPriceOnWhichTotalPriceGettingCalculated =
            lastPriceOnWhichTotalPriceGettingCalculated(
              product,
              product.selectedOption,
              newProducts[index].quantity
            );
        }
      }
      setProducts(newProducts);
      changeSelectedOption(product, index);
    } else {
      let { enable, number } =
        product.sellerProductDetail.minimumQuantity.box.isMultiple;
      let newProducts = [...products];
      if (
        newProducts[index].quantity >
        product.sellerProductDetail.minimumQuantity.box.quantity
      ) {
        if (enable) {
          newProducts[index].quantity = newProducts[index].quantity - number;
          newProducts[index].lastPriceOnWhichTotalPriceGettingCalculated =
            lastPriceOnWhichTotalPriceGettingCalculated(
              product,
              product.selectedOption,
              newProducts[index].quantity
            );
        } else {
          newProducts[index].quantity = newProducts[index].quantity - 1;
          newProducts[index].lastPriceOnWhichTotalPriceGettingCalculated =
            lastPriceOnWhichTotalPriceGettingCalculated(
              product,
              product.selectedOption,
              newProducts[index].quantity
            );
        }
      }
      setProducts(newProducts);
    }
  };

  const handleIncrement = async (product: any, index: number) => {
    const { selectedOption, sellerProductDetail } = product;
    const { minimumQuantity } = sellerProductDetail;
    const { isMultiple } = minimumQuantity[selectedOption];
    const newProducts = [...products];
    const incrementValue = isMultiple ? Number(isMultiple.number) : 1;

    newProducts[index].quantity += incrementValue;
    newProducts[index].lastPriceOnWhichTotalPriceGettingCalculated =
      await lastPriceOnWhichTotalPriceGettingCalculated(
        product,
        selectedOption,
        newProducts[index].quantity
      );

    setProducts(newProducts);
    changeSelectedOption(product, index);
  };

  const handleIncrementInBag = (product: any, index: number) => {
    let { selectedOption, _id } = product;

    if (selectedOption == 'piece') {
      let { enable, number } =
        product.sellerProductDetail.minimumQuantity.piece.isMultiple;
      let newProducts = [...products];
      if (enable) {
        newProducts[index].quantity = newProducts[index].quantity + number;
        newProducts[index].lastPriceOnWhichTotalPriceGettingCalculated =
          lastPriceOnWhichTotalPriceGettingCalculated(
            product,
            product.selectedOption,
            newProducts[index].quantity
          );
      } else {
        newProducts[index].quantity = newProducts[index].quantity + 1;
        newProducts[index].lastPriceOnWhichTotalPriceGettingCalculated =
          lastPriceOnWhichTotalPriceGettingCalculated(
            product,
            product.selectedOption,
            newProducts[index].quantity
          );
      }
      changeSelectedOption(product, index);
      return newProducts;
    } else if (selectedOption == 'bundle') {
      let { enable, number } =
        product.sellerProductDetail.minimumQuantity.bundle.isMultiple;
      let newProducts = [...products];
      if (enable) {
        newProducts[index].quantity = newProducts[index].quantity + number;
        newProducts[index].lastPriceOnWhichTotalPriceGettingCalculated =
          lastPriceOnWhichTotalPriceGettingCalculated(
            product,
            product.selectedOption,
            newProducts[index].quantity
          );
      } else {
        newProducts[index].quantity = newProducts[index].quantity + 1;
        newProducts[index].lastPriceOnWhichTotalPriceGettingCalculated =
          lastPriceOnWhichTotalPriceGettingCalculated(
            product,
            product.selectedOption,
            newProducts[index].quantity
          );
      }
      changeSelectedOption(product, index);
      return newProducts;
    } else {
      let { enable, number } =
        product.sellerProductDetail.minimumQuantity.box.isMultiple;
      let newProducts = [...products];
      if (enable) {
        newProducts[index].quantity = newProducts[index].quantity + number;
        newProducts[index].lastPriceOnWhichTotalPriceGettingCalculated =
          lastPriceOnWhichTotalPriceGettingCalculated(
            product,
            product.selectedOption,
            newProducts[index].quantity
          );
      } else {
        newProducts[index].quantity = newProducts[index].quantity + 1;
        newProducts[index].lastPriceOnWhichTotalPriceGettingCalculated =
          lastPriceOnWhichTotalPriceGettingCalculated(
            product,
            product.selectedOption,
            newProducts[index].quantity
          );
      }
      changeSelectedOption(product, index);
      return newProducts;
    }
  };

  const handleDecrementInBag = (product: any, index: number) => {
    let { selectedOption, _id } = product;
    if (selectedOption == 'piece') {
      let { enable, number } =
        product.sellerProductDetail.minimumQuantity.piece.isMultiple;
      let newProducts = [...products];
      if (enable) {
        newProducts[index].quantity = newProducts[index].quantity - number;
        newProducts[index].lastPriceOnWhichTotalPriceGettingCalculated =
          lastPriceOnWhichTotalPriceGettingCalculated(
            product,
            product.selectedOption,
            newProducts[index].quantity
          );
      } else {
        newProducts[index].quantity = newProducts[index].quantity - 1;
        newProducts[index].lastPriceOnWhichTotalPriceGettingCalculated =
          lastPriceOnWhichTotalPriceGettingCalculated(
            product,
            product.selectedOption,
            newProducts[index].quantity
          );
      }
      // changeSelectedOption(product, index);
      return newProducts;
    } else if (selectedOption == 'bundle') {
      let { enable, number } =
        product.sellerProductDetail.minimumQuantity.bundle.isMultiple;
      let newProducts = [...products];
      if (enable) {
        newProducts[index].quantity = newProducts[index].quantity - number;
        newProducts[index].lastPriceOnWhichTotalPriceGettingCalculated =
          lastPriceOnWhichTotalPriceGettingCalculated(
            product,
            product.selectedOption,
            newProducts[index].quantity
          );
      } else {
        newProducts[index].quantity = newProducts[index].quantity - 1;
        newProducts[index].lastPriceOnWhichTotalPriceGettingCalculated =
          lastPriceOnWhichTotalPriceGettingCalculated(
            product,
            product.selectedOption,
            newProducts[index].quantity
          );
      }
      // changeSelectedOption(product, index);
      return newProducts;
    } else {
      let { enable, number } =
        product.sellerProductDetail.minimumQuantity.box.isMultiple;
      let newProducts = [...products];
      if (enable) {
        newProducts[index].quantity = newProducts[index].quantity - number;
        newProducts[index].lastPriceOnWhichTotalPriceGettingCalculated =
          lastPriceOnWhichTotalPriceGettingCalculated(
            product,
            product.selectedOption,
            newProducts[index].quantity
          );
      } else {
        newProducts[index].quantity = newProducts[index].quantity - 1;
        newProducts[index].lastPriceOnWhichTotalPriceGettingCalculated =
          lastPriceOnWhichTotalPriceGettingCalculated(
            product,
            product.selectedOption,
            newProducts[index].quantity
          );
      }
      // changeSelectedOption(product, index);
      return newProducts;
    }
  };

  const changeSelectedOption = (product: any, index: number) => {
    const { selectedOption, quantity, info, sellerProductDetail } = product;
    const { minimumQuantity } = sellerProductDetail;

    if (info) {
      const { pieceInEachBundle, pieceInEachBox } = info;
      const newProducts = [...products];

      if (selectedOption === 'piece') {
        if (quantity % pieceInEachBundle === 0 && minimumQuantity.bundle) {
          let newQuantity = Math.floor(quantity / pieceInEachBundle);

          if (newQuantity >= minimumQuantity.bundle.quantity) {
            newProducts[index].selectedOption = 'bundle';
            newProducts[index].quantity = Math.floor(
              quantity / pieceInEachBundle
            );
          }
        }
        if (quantity % pieceInEachBox === 0 && minimumQuantity.box) {
          let newQuantity = Math.floor(quantity / pieceInEachBox);

          if (newQuantity >= minimumQuantity.box.quantity) {
            newProducts[index].selectedOption = 'box';
            newProducts[index].quantity = Math.floor(quantity / pieceInEachBox);
          }
        }
      } else if (selectedOption === 'bundle') {
        if (quantity % pieceInEachBox === 0 && minimumQuantity.box) {
          let newQuantity = quantity * pieceInEachBundle;
          newQuantity = newQuantity / pieceInEachBox;

          if (newQuantity >= minimumQuantity.box.quantity) {
            newProducts[index].selectedOption = 'box';
            newProducts[index].quantity = Math.floor(newQuantity);
          }
        }
      }

      newProducts[index].lastPriceOnWhichTotalPriceGettingCalculated =
        lastPriceOnWhichTotalPriceGettingCalculated(
          product,
          newProducts[index].selectedOption,
          newProducts[index].quantity
        );

      setProducts(newProducts);
    }
  };

  // const changeSelectedOption = (product: any, index: number) => {
  //   const { selectedOption, quantity, info, sellerProductDetail } = product;
  //   const { minimumQuantity } = sellerProductDetail;

  //   if (info) {
  //     const { pieceInEachBundle, pieceInEachBox } = info;
  //     const newProducts = [...products];

  //     if (selectedOption === 'piece') {
  //       const { bundle } = minimumQuantity;
  //       if (quantity % pieceInEachBundle === 0 && bundle) {
  //         newProducts[index].selectedOption = 'bundle';
  //         newProducts[index].quantity = quantity / pieceInEachBundle;
  //       } else if (quantity % pieceInEachBox === 0 && minimumQuantity.box) {
  //         newProducts[index].selectedOption = 'box';
  //         newProducts[index].quantity = quantity / pieceInEachBox;
  //       }
  //     } else if (selectedOption === 'bundle') {
  //       const { box } = minimumQuantity;
  //       const calculatedQuantity = (quantity * pieceInEachBundle) / pieceInEachBox;

  //       if (!String(calculatedQuantity).includes('.') && box) {
  //         newProducts[index].selectedOption = 'box';
  //         newProducts[index].quantity = calculatedQuantity;
  //       }
  //     }

  //     newProducts[index].lastPriceOnWhichTotalPriceGettingCalculated =
  //       lastPriceOnWhichTotalPriceGettingCalculated(
  //         product,
  //         newProducts[index].selectedOption,
  //         newProducts[index].quantity
  //       );

  //     setProducts(newProducts);
  //   }
  // };

  const showMinimumQuantityAccToSelectedOption = (product: any) => {
    if (product.selectedOption === 'piece') {
      return product.sellerProductDetail?.minimumQuantity?.piece?.quantity;
    } else if (product.selectedOption === 'bundle') {
      return product.sellerProductDetail?.minimumQuantity?.bundle?.quantity;
    } else {
      return product?.sellerProductDetail?.minimumQuantity?.box?.quantity;
    }
  };

  const showNumberAccToSelectedOption = (product: any) => {
    if (product.selectedOption === 'piece') {
      return product.sellerProductDetail.minimumQuantity.piece.isMultiple
        .number;
    } else if (product.selectedOption === 'bundle') {
      return product.sellerProductDetail.minimumQuantity.bundle.isMultiple
        .number;
    } else {
      return product.sellerProductDetail.minimumQuantity.box.isMultiple.number;
    }
  };

  const handleChangeOnQuantity = (event: any, product: any, index: any) => {
    let { _id } = product;
    let newProductsArray = [...products];

    newProductsArray = newProductsArray.map((product: any) => {
      let { selectedOption } = product;

      if (product._id === _id) {
        if (
          event.target.value < showMinimumQuantityAccToSelectedOption(product)
        ) {
          return {
            ...product,
            quantity: showMinimumQuantityAccToSelectedOption(product),
          };
        } else if (
          event.target.value > showMinimumQuantityAccToSelectedOption(product)
        ) {
          if (selectedOption == 'piece') {
            if (
              product.sellerProductDetail.minimumQuantity.piece.isMultiple
                .enable
            ) {
              let number =
                (event.target.value -
                  showMinimumQuantityAccToSelectedOption(product)) %
                showNumberAccToSelectedOption(product);
              let numberToAddInInputValue =
                (event.target.value -
                  showMinimumQuantityAccToSelectedOption(product)) /
                showNumberAccToSelectedOption(product);
              if (number != 0) {
                numberToAddInInputValue =
                  Math.floor(numberToAddInInputValue) + 1;
              }

              return {
                ...product,
                quantity:
                  showMinimumQuantityAccToSelectedOption(product) +
                  numberToAddInInputValue *
                    showNumberAccToSelectedOption(product),
              };
            } else {
              return product;
            }
          } else if (selectedOption == 'bundle') {
            if (
              product.sellerProductDetail.minimumQuantity.bundle.isMultiple
                .enable
            ) {
              let number =
                (event.target.value -
                  showMinimumQuantityAccToSelectedOption(product)) %
                showNumberAccToSelectedOption(product);
              let numberToAddInInputValue =
                (event.target.value -
                  showMinimumQuantityAccToSelectedOption(product)) /
                showNumberAccToSelectedOption(product);
              if (number != 0) {
                numberToAddInInputValue =
                  Math.floor(numberToAddInInputValue) + 1;
              }

              return {
                ...product,
                quantity:
                  showMinimumQuantityAccToSelectedOption(product) +
                  numberToAddInInputValue *
                    showNumberAccToSelectedOption(product),
              };
            } else {
              return product;
            }
          } else {
            if (
              product.sellerProductDetail.minimumQuantity.box.isMultiple.enable
            ) {
              let number =
                (event.target.value -
                  showMinimumQuantityAccToSelectedOption(product)) %
                showNumberAccToSelectedOption(product);
              let numberToAddInInputValue =
                (event.target.value -
                  showMinimumQuantityAccToSelectedOption(product)) /
                showNumberAccToSelectedOption(product);
              if (number != 0) {
                numberToAddInInputValue =
                  Math.floor(numberToAddInInputValue) + 1;
              }

              return {
                ...product,
                quantity:
                  showMinimumQuantityAccToSelectedOption(product) +
                  numberToAddInInputValue *
                    showNumberAccToSelectedOption(product),
              };
            } else {
              return product;
            }
          }
        } else {
          return product;
        }
      } else {
        return product;
      }
    });

    newProductsArray = newProductsArray.map((product: any) => {
      if (product._id === _id) {
        return {
          ...product,
          lastPriceOnWhichTotalPriceGettingCalculated:
            lastPriceOnWhichTotalPriceGettingCalculated(
              product,
              product.selectedOption,
              product.quantity
            ),
          totalPrice: showTotalPrice(product, product.selectedOption),
        };
      } else {
        return product;
      }
    });
    setProducts(newProductsArray);
  };

  const handleRightArrowClick = (productIndex: any, imageIndex: any) => {
    if (imageIndex < products[productIndex].imageList.length - 1) {
      let updatedProduct = [...products];
      updatedProduct[productIndex].currentActiveImageIndex = imageIndex + 1;
      setProducts(updatedProduct);
    }
  };

  const handleLeftArrowClick = (productIndex: any, imageIndex: any) => {
    if (imageIndex > 0) {
      let updatedProduct = [...products];
      updatedProduct[productIndex].currentActiveImageIndex = imageIndex - 1;

      setProducts(updatedProduct);
    }
  };

  const clearAllFilter = () => {
    setUserWantToSeeOnlySellerSellingInHisArea(() => false);
    setActiveBrand(() => {
      return {};
    });
    setActiveCategory(() => {
      return {};
    });
    setActiveDistributor(() => {
      return {};
    });
    setActiveSubCategory(() => {
      return {};
    });
    setInputValueForBrand(() => '');
    setInputValueForCategory(() => '');
    setInputValueForSeller(() => '');
    setInputValueForSubCategory(() => '');
    setListsOfBrand(() => []);
    setListsOfCategory(() => []);
    setListsOfDistributors(() => []);
    setListsOfSubCategory(() => []);
    setBag(() => {
      return {
        buyerType: 'Store',
        sellerStoreId: '',
        brandId: '',
        itemList: [],
        totalItemList: 0,
      };
    });
    setProducts(() => []);
  };

  return (
    <div className="parent_cnt_order_page">
      <ProductFilter
        clearAllFilter={clearAllFilter}
        handleClickOnSeeSubcategory={handleClickOnSeeSubcategory}
        userWantToSeeOnlySellerSellingInHisArea={
          userWantToSeeOnlySellerSellingInHisArea
        }
        setUserWantToSeeOnlySellerSellingInHisArea={
          setUserWantToSeeOnlySellerSellingInHisArea
        }
        //
        seeActiveStoreAvailableProduct={seeActiveStoreAvailableProduct}
        userWantToSeeOnlyStoreProduct={userWantToSeeOnlyStoreProduct}
        // for seller given below
        fetchSeller={fetchSeller}
        sellerRefForCloseOnClickingOutside={sellerRefForCloseOnClickingOutside}
        showFilterOnLoad={showFilterOnLoad}
        setshowFilterOnLoad={setshowFilterOnLoad}
        inputValueForSeller={inputValueForSeller}
        setListsOfDistributors={setListsOfDistributors}
        setStopInfiniteScrollingForSeller={setStopInfiniteScrolling}
        setIsSellerOpen={setIsSellerOpen}
        isSellerOpen={isSellerOpen}
        handleInputChangeForSeller={handleInputChangeForSeller}
        listsOfDistributors={listsOfDistributors}
        sellerRef={sellerRef}
        handleActiveDistributor={handleActiveDistributor}
        observer={observer}
        activeDistributor={activeDistributor}
        errorForFilter={errorForFilter}
        setErrorForFilter={setErrorForFilter}
        // for brand given below
        brandRefForCloseOnClickingOutside={brandRefForCloseOnClickingOutside}
        inputValueForBrand={inputValueForBrand}
        setListsOfBrand={setListsOfBrand}
        setStopInfiniteScrollingForBrand={setStopInfiniteScrollingForBrand}
        setIsBrandOpen={setIsBrandOpen}
        isBrandOpen={isBrandOpen}
        handleInputChangeForBrand={handleInputChangeForBrand}
        listsOfBrand={listsOfBrand}
        brandRef={brandRef}
        handleActiveBrand={handleActiveBrand}
        brandObserver={brandObserver}
        activeBrand={activeBrand}
        fetchBrand={fetchBrand}
        // for category
        pageNumberForCategory={pageNumberForCategory}
        setPageNumberForCategory={setPageNumberForCategory}
        listsOfCategory={listsOfCategory}
        setListsOfCategory={setListsOfCategory}
        stopInfiniteScrollingForCategory={stopInfiniteScrollingForCategory}
        setStopInfiniteScrollingForCategory={
          setStopInfiniteScrollingForCategory
        }
        isCategoryOpen={isCategoryOpen}
        setIsCategoryOpen={setIsCategoryOpen}
        inputValueForCategory={inputValueForCategory}
        setInputValueForCategory={setInputValueForCategory}
        activeCategory={activeCategory}
        setActiveCategory={setActiveCategory}
        categoryRef={categoryRef}
        categoryRefForCloseOnClickingOutside={
          categoryRefForCloseOnClickingOutside
        }
        fetchCategory={fetchCategory}
        handleInputChangeForCategory={handleInputChangeForCategory}
        handleActiveCategory={handleActiveCategory}
        //sub-category
        activeSubCategory={activeSubCategory}
        listsOfSubCategory={listsOfSubCategory}
        inputValueForSubCategory={inputValueForSubCategory}
        showSubCategory={showSubCategory}
        handleInputChangeForSubCategory={handleInputChangeForSubCategory}
        handleActiveSubCategory={handleActiveSubCategory}
        subCategoryRef={subCategoryRef}
        // apply filters
        getProducts={getProducts}
      />
      {/* routes/ schedules */}
      <input type="checkbox" id="toggle" />
      <label
        htmlFor="toggle"
        onClick={() => setshowFilterOnLoad(true)}
        className="filterIcon"
        style={{
          width: '50px',
          height: '50px',
          display: 'flex',
          fontSize: '24px',
        }}
      >
        <span>
          <RiFilter2Fill />
        </span>
      </label>
      {Object.keys(showPlaceOrder).length > 0 ? (
        <OrderItemsUIForWeb
          editOrderedItem={editOrderedItem}
          setEditOrderedItem={setEditOrderedItem}
          showPlaceOrder={showPlaceOrder}
          setShowPlaceOrderForProductPage={setShowPlaceOrder}
        />
      ) : (
        ''
      )}
      {Object.keys(orderFromSpecificStore).length > 0 ? (
        <OrderFromSpecificStore
          getProducts={getProducts}
          showPlaceOrder={orderFromSpecificStore}
          setShowPlaceOrder={setOrderFromSpecificStore}
        />
      ) : (
        ''
      )}
      <div
        className="order_page_big_cnt"
        style={{
          position: 'relative',
        }}
      >
        <div className="small_cnt">
          <div className="relative">
            <div className="search-bar-component">
              <div className="search-bar" ref={productListAndSearchBarRef}>
                <input
                  type="text"
                  name="product-name"
                  placeholder="Search Product..."
                  value={searchedValueForProduct}
                  onChange={handleChangeOnSearchedValue}
                />
                <button
                  className="search-icon"
                  onClick={getProductBasedOnSearchValue}
                >
                  <FiSearch />
                </button>
                {allProductInDropDown.length > 0 ? (
                  <ul
                    id={'product-list-n-search-bar'}
                    className="product-list"
                    style={{ overflowY: 'scroll' }}
                  >
                    {allProductInDropDown.map((data: any, index: number) => (
                      <li
                        key={index}
                        onClick={() => handleClickOnProductInDropDown(data)}
                        className="each-product"
                      >
                        <img
                          src={
                            data.imageList.length > 0
                              ? data.imageList[0]
                              : '/no-image-available-icon.jpeg'
                          }
                          alt={data._id}
                          className="product-img"
                        />
                        <div>
                          <p className="name"> {data.name}</p>
                          <p className="mrp">MRP - ₹{data.mrp}</p>
                        </div>
                      </li>
                    ))}
                    {allProductInDropDown.length > 0 && (
                      <div
                        ref={productListRefForInfiniteScrollingInSearchBar}
                        style={{ padding: '10px' }}
                      ></div>
                    )}
                  </ul>
                ) : (
                  ''
                )}
              </div>
            </div>

            <ul
              className="products_cnt"
              style={{
                justifyContent: products.length > 0 ? 'start' : 'center',
              }}
            >
              {products.length > 0 ? (
                products.map((product: any, index: number) => {
                  return (
                    <li
                      key={index}
                      className={
                        product?.sellerProductDetail
                          ? 'product'
                          : 'product out-of-stock'
                      }
                    >
                      <div className="product_img_container">
                        {product.imageList?.length > 1 && (
                          <button
                            style={{
                              cursor:
                                product.currentActiveImageIndex < 0
                                  ? 'not-allowed'
                                  : 'pointer',
                              background: 'white',
                            }}
                            onClick={() =>
                              handleLeftArrowClick(
                                index,
                                product.currentActiveImageIndex
                              )
                            }
                            className="arrows left_arrow"
                          >
                            <MdKeyboardArrowLeft />
                          </button>
                        )}
                        <LazyLoadImage
                          alt={product.name}
                          className="product_img"
                          src={
                            product.imageList == undefined
                              ? '/images/no-image-available-icon.jpeg'
                              : product.imageList[
                                  product.currentActiveImageIndex
                                ]
                          }
                        />
                        {product.imageList?.length > 1 && (
                          <button
                            className="arrows right_arrow"
                            style={{
                              cursor:
                                product.currentActiveImageIndex ==
                                product.imageList.length - 1
                                  ? 'not-allowed'
                                  : 'pointer',
                              background: 'white',
                            }}
                            onClick={() =>
                              handleRightArrowClick(
                                index,
                                product.currentActiveImageIndex
                              )
                            }
                          >
                            <MdKeyboardArrowRight />
                          </button>
                        )}
                      </div>

                      <div className="product_info_cnt">
                        <div className="title text-center">
                          <h1>{product.name}</h1>
                        </div>

                        <div className="cnt_of_price_n_mrp">
                          <p className="price">
                            ₹
                            {product.lastPriceOnWhichTotalPriceGettingCalculated +
                              ' '}
                          </p>
                          <p className="mrp">{'  MRP ₹' + product.mrp}</p>
                        </div>

                        <div className="quantity">
                          <div>
                            <button
                              style={{
                                cursor:
                                  showMinimumQuantityAccToSelectedOption(
                                    product
                                  ) == product.quantity
                                    ? 'not-allowed'
                                    : 'pointer',
                              }}
                              onMouseOver={(event: any) => {
                                if (
                                  showMinimumQuantityAccToSelectedOption(
                                    product
                                  ) == product.quantity
                                ) {
                                  return event.target.classList.add('minus-1');
                                }
                              }}
                              onMouseLeave={(event: any) => {
                                return event.target.classList.remove('minus-1');
                              }}
                              onClick={async (event: any) => {
                                await handleDecrement(product, index);
                                let updatedProducts = [...products];
                                updatedProducts[index].totalPrice =
                                  await showTotalPrice(
                                    product,
                                    product.selectedOption
                                  );
                                setProducts(updatedProducts);

                                if (
                                  showMinimumQuantityAccToSelectedOption(
                                    product
                                  ) == product.quantity
                                ) {
                                  return event.target.classList.add('minus-1');
                                }
                              }}
                              className="minus"
                            >
                              -
                            </button>
                            <div className="minimum_quantity_info">
                              <span>
                                minimum Quantity to buy in
                                {' ' + product.selectedOption} is
                                {' ' +
                                  showMinimumQuantityAccToSelectedOption(
                                    product
                                  )}
                              </span>
                            </div>

                            <input
                              onBlur={(event: any) => {
                                return handleChangeOnQuantity(
                                  event,
                                  product,
                                  index
                                );
                              }}
                              onChange={async (event: any) => {
                                let newProducts = [...products];
                                newProducts[index].quantity =
                                  event.target.value;
                                setProducts(newProducts);
                              }}
                              type="number"
                              name="quantity"
                              value={product.quantity}
                            />
                            <button
                              onClick={async () => {
                                await handleIncrement(product, index);
                                let updatedProducts = [...products];
                                updatedProducts[index].totalPrice =
                                  await showTotalPrice(
                                    product,
                                    product.selectedOption
                                  );
                                return setProducts(updatedProducts);
                              }}
                              className="plus"
                            >
                              +
                            </button>
                          </div>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          <div className="options_like_box_bundle_piece">
                            <div>
                              {product?.sellerProductDetail !== null &&
                              product?.sellerProductDetail?.minimumQuantity
                                .piece &&
                              product?.sellerProductDetail?.pricePerUnit
                                .piece ? (
                                <button
                                  onClick={() =>
                                    handleClickOnPiece(product, index)
                                  }
                                  style={{
                                    backgroundColor:
                                      product.selectedOption == 'piece'
                                        ? '#eef2f6'
                                        : '',
                                  }}
                                >
                                  Piece
                                </button>
                              ) : (
                                ''
                              )}

                              {product?.sellerProductDetail !== null &&
                              product?.sellerProductDetail?.minimumQuantity
                                .bundle &&
                              product?.sellerProductDetail?.pricePerUnit
                                .bundle &&
                              product?.info?.pieceInEachBundle ? (
                                <button
                                  style={{
                                    backgroundColor:
                                      product.selectedOption == 'bundle'
                                        ? '#eef2f6'
                                        : '',
                                  }}
                                  onClick={() =>
                                    handleClickOnBundle(product, index)
                                  }
                                >
                                  Bundle *
                                </button>
                              ) : (
                                ''
                              )}

                              {product?.sellerProductDetail !== null &&
                              product?.sellerProductDetail?.minimumQuantity
                                .box &&
                              product?.sellerProductDetail?.pricePerUnit.box &&
                              product?.info?.pieceInEachBox ? (
                                <button
                                  onClick={() =>
                                    handleClickOnBox(product, index)
                                  }
                                  style={{
                                    backgroundColor:
                                      product.selectedOption == 'box'
                                        ? '#eef2f6'
                                        : '',
                                  }}
                                >
                                  Box
                                  <span
                                    style={{
                                      fontSize: '9px',
                                      position: 'absolute',
                                      marginLeft: '2px',
                                    }}
                                  >
                                    {'  #'}
                                  </span>
                                </button>
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="profit_cnt">
                          <span className="total_price">
                            <span
                              style={{
                                fontStyle: 'italic',
                                fontSize: '0.85rem',
                              }}
                            >
                              Total
                            </span>{' '}
                            {' ₹' + product.totalPrice}
                          </span>
                        </div>
                        <div className="star_info">
                          {product?.info?.pieceInEachBundle ? (
                            <p>
                              * Bundle =
                              {' ' + product?.info?.pieceInEachBundle + ' '}
                              pieces.
                            </p>
                          ) : (
                            ''
                          )}
                          {product?.info?.pieceInEachBundle &&
                          product?.info?.pieceInEachBox ? (
                            <span
                              style={{
                                display: 'block',
                                height: '15px',
                                borderLeft: '0.2px solid #666666',
                                margin: '5px 0px',
                              }}
                            ></span>
                          ) : (
                            ''
                          )}

                          {product?.info?.pieceInEachBox ? (
                            <p
                              style={{
                                position: 'relative',
                                marginLeft:
                                  product?.info?.pieceInEachBox &&
                                  product?.info?.pieceInEachBundle
                                    ? '0px'
                                    : '0.6rem',
                              }}
                            >
                              <span
                                style={{
                                  fontSize: '9px',
                                  position: 'absolute',
                                  top: '-1px',
                                  left: '-8px',
                                }}
                              >
                                {' # '}
                              </span>
                              Box = {' ' + product?.info?.pieceInEachBox + ' '}
                              pieces.
                            </p>
                          ) : (
                            ''
                          )}
                        </div>

                        <hr
                          style={{
                            marginBottom: '0.6rem',
                          }}
                        />

                        <div className="offers-popup-container">
                          {/* when offers we will apply then use it */}

                          {/* {product.sellerProductDetail?.offer?.pieceOfferList
                            ?.length > 0 ||
                          product.sellerProductDetail?.offer?.bundleOfferList
                            ?.length > 0 ||
                          product.sellerProductDetail?.offer?.boxOfferList
                            ?.length > 0 ? (
                            <button
                              className="show-offers-link"
                              onClick={() => {
                                let updatedProduct = [...products];

                                updatedProduct[index].showOfferList = true;
                                setProducts(updatedProduct);
                              }}
                            >
                              <MdLocalOffer />
                              Show Offers
                            </button>
                          ) : null} */}
                          {product.showOfferList && (
                            <div className="popup-overlay">
                              <div
                                className="popup-content"
                                ref={offerPopUpRef}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                  }}
                                >
                                  <h2 className="popup-title">Offers</h2>
                                  <button
                                    className="close-popup-btn"
                                    onClick={() => {
                                      let updatedProduct = [...products];
                                      updatedProduct[index].showOfferList =
                                        false;
                                      setProducts(updatedProduct);
                                    }}
                                  >
                                    <RxCross2 />
                                  </button>
                                </div>

                                <div className="offers-list">
                                  <div>
                                    {product.sellerProductDetail?.offer.pieceOfferList.map(
                                      (piece: any) => (
                                        <p key={piece.quantity}>
                                          Buy {piece.quantity} {piece.type} get{' '}
                                          {piece.free.quantity} piece free
                                        </p>
                                      )
                                    )}
                                  </div>
                                  <hr />
                                  <div>
                                    {product.sellerProductDetail?.offer.bundleOfferList.map(
                                      (bundle: any) => (
                                        <p key={bundle.quantity}>
                                          Buy {bundle.quantity} {bundle.type}{' '}
                                          get {bundle.free.quantity} bundle free
                                        </p>
                                      )
                                    )}
                                  </div>
                                  <hr />
                                  <div>
                                    {product.sellerProductDetail?.offer.boxOfferList.map(
                                      (box: any) => (
                                        <p key={box.quantity}>
                                          Buy {box.quantity} {box.type} get{' '}
                                          {box.free.quantity} box free
                                        </p>
                                      )
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="range-of-prices-container">
                          <button
                            className="range-of-prices-link"
                            onClick={() => {
                              let updatedProduct = [...products];
                              updatedProduct[index].showPricePerUnit = true;
                              setProducts(updatedProduct);
                            }}
                          >
                            <FaTrophy /> Check Price Tiers
                          </button>
                          {product.showPricePerUnit && (
                            <div className="popup-overlay">
                              <div
                                className="popup-content"
                                ref={priceTiersPopUpRef}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                  }}
                                >
                                  <h3 className="popup-title">Price Tiers</h3>
                                  <button
                                    onClick={() => {
                                      let updatedProduct = [...products];
                                      updatedProduct[index].showPricePerUnit =
                                        false;
                                      setProducts(updatedProduct);
                                    }}
                                  >
                                    <RxCross2 />
                                  </button>
                                </div>

                                <table className="price-table">
                                  <tbody>
                                    {product.sellerProductDetail?.pricePerUnit
                                      .piece && (
                                      <>
                                        {!product.sellerProductDetail
                                          .pricePerUnit.piece.unique ? (
                                          <tr>
                                            <th>For Pieces</th>
                                            <th>Price (₹)</th>
                                          </tr>
                                        ) : null}
                                        {!product.sellerProductDetail
                                          .pricePerUnit.piece.unique ? (
                                          product.sellerProductDetail.pricePerUnit.piece.quantityWisePriceList.map(
                                            (p: any) => (
                                              <tr
                                                key={`${p.moreThanOrEqual}-${p.lessThanOrEqual}`}
                                              >
                                                <td>
                                                  For {p.moreThanOrEqual} to{' '}
                                                  {p.lessThanOrEqual} piece
                                                </td>
                                                <td>₹{p.price}</td>
                                              </tr>
                                            )
                                          )
                                        ) : (
                                          <tr>
                                            <td>Price per piece</td>
                                            <td>
                                              ₹
                                              {
                                                product.sellerProductDetail
                                                  .pricePerUnit.piece?.price
                                              }
                                            </td>
                                          </tr>
                                        )}
                                      </>
                                    )}

                                    {product.sellerProductDetail.pricePerUnit
                                      .bundle && (
                                      <>
                                        {!product.sellerProductDetail
                                          .pricePerUnit.bundle.unique ? (
                                          <tr>
                                            <th>For Bundles</th>
                                            <th>Price (₹)</th>
                                          </tr>
                                        ) : null}
                                        {!product.sellerProductDetail
                                          .pricePerUnit.bundle.unique ? (
                                          product.sellerProductDetail.pricePerUnit.bundle.quantityWisePriceList.map(
                                            (p: any) => (
                                              <tr
                                                key={`${p.moreThanOrEqual}-${p.lessThanOrEqual}`}
                                              >
                                                <td>
                                                  For {p.moreThanOrEqual} to{' '}
                                                  {p.lessThanOrEqual} bundle
                                                </td>
                                                <td>₹{p.price}</td>
                                              </tr>
                                            )
                                          )
                                        ) : (
                                          <tr>
                                            <td>Price per bundle</td>
                                            <td>
                                              ₹
                                              {
                                                product.sellerProductDetail
                                                  .pricePerUnit.bundle.price
                                              }
                                            </td>
                                          </tr>
                                        )}
                                      </>
                                    )}

                                    {product.sellerProductDetail.pricePerUnit
                                      .bundle && (
                                      <>
                                        {!product.sellerProductDetail
                                          .pricePerUnit.box.unique ? (
                                          <tr>
                                            <th>For Boxes</th>
                                            <th>Price (₹)</th>
                                          </tr>
                                        ) : null}
                                        {!product.sellerProductDetail
                                          .pricePerUnit.box.unique ? (
                                          product.sellerProductDetail.pricePerUnit.box.quantityWisePriceList.map(
                                            (p: any) => (
                                              <tr
                                                key={`${p.moreThanOrEqual}-${p.lessThanOrEqual}`}
                                              >
                                                <td>
                                                  For {p.moreThanOrEqual} to{' '}
                                                  {p.lessThanOrEqual} box
                                                </td>
                                                <td>₹{p.price}</td>
                                              </tr>
                                            )
                                          )
                                        ) : (
                                          <tr>
                                            <td>Price per box</td>
                                            <td>
                                              ₹
                                              {
                                                product.sellerProductDetail
                                                  .pricePerUnit.box.price
                                              }
                                            </td>
                                          </tr>
                                        )}
                                      </>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          )}
                        </div>

                        <button
                          className={
                            product.addedToCart == 'adding'
                              ? 'tick_mark_btn'
                              : 'add_to_bag_btn'
                          }
                          type="button"
                          onClick={(event: any) =>
                            handleAddToBag(event, product, index)
                          }
                        >
                          {showUpdateBag(product.addedToCart)}
                        </button>
                      </div>
                    </li>
                  );
                })
              ) : (
                <ShowRequiredMsg msg={msg} />
              )}

              {products.length > 0 ? <div ref={endOfListRef}></div> : ''}
            </ul>

            {/* <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: '1rem 1rem',
                  padding: '20px 0',
                }}
              >
                {loading ? <Loader /> : ''}
              </div> */}
          </div>
        </div>

        <div>
          <SubCart
            setShowPlaceOrder={setShowPlaceOrder}
            fetchPendingOrder={fetchPendingOrder}
            bag={bag}
            setBag={setBag}
            removeProductFromBag={removeProductFromBag}
            handleIncrementInBag={handleIncrementInBag}
            handleDecrementInBag={handleDecrementInBag}
            showMinimumQuantityAccToSelectedOption={
              showMinimumQuantityAccToSelectedOption
            }
            updateProductForQuantity={updateProductForQuantity}
            updateProductForOption={updateProductForOption}
            setShowIndicator={setShowIndicator}
            setMessage={setMessage}
            setType={setType}
            activeDistributor={activeDistributor}
            showNumberAccToSelectedOption={showNumberAccToSelectedOption}
            handleChangeOnQuantity={handleChangeOnQuantity}
            products={products}
            setProducts={setProducts}
          />
        </div>
      </div>
    </div>
  );
}

function ShowRequiredMsg(msg: any) {
  return (
    <div>
      <p className="error_info">{msg.msg}</p>
    </div>
  );
}
