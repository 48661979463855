import '../profile.scss';
import { BsFillCameraFill } from 'react-icons/bs';
import { CiUser } from 'react-icons/ci';
import { MdOutlineKeyboardBackspace, MdVerified } from 'react-icons/md';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  GetRequest,
  PatchRequest,
  PostRequest,
} from '../../../../components/Request';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { userSelector } from '../../../../features/auth/Slice';

export default function UserProfile(props: any) {
  let { setSelectedNavigation, setShowIndicator, setType, setMessage } = props;
  const [phoneNumberOTP, setPhoneNumberOTP] = useState<string[]>(
    new Array(6).fill('')
  );
  const [startPhoneOTPTimer, setStartPhoneOTPTimer] = useState(false);
  const [resendPhoneOTPTimer, setResendPhoneOTPTimer] = useState(30);
  const inputRef = useRef<HTMLInputElement>(null);
  const [activeOTPIndex, setActiveOTPIndex] = useState<number>(0);
  const [errorOfOTP, setErrorOfOTP] = useState('');
  const [sentOrResendOTPMsg, setSentOrResendOTPMsg] = useState('');
  const [showVerifiy, setShowVerify] = useState(false);
  const userInfoInRedux = useSelector(userSelector);
  const [userInfo, setUserInfo] = useState({
    image: '',
    name: '',
    email: { id: '', isVerified: false },
    errors: {
      image: '',
      name: '',
      email: '',
    },
  });

  const [showOtp, setShowOtp] = useState(false);

  useEffect(() => {
    if (localStorage.userInfo) {
      let userInfoLocal = JSON.parse(localStorage.userInfo);
      setUserInfo((prevState: any) => {
        return {
          ...prevState,
          name: userInfoLocal.name,
          image: userInfoLocal.profileImage,
          email: { id: userInfoLocal.email, isVerified: true },
        };
      });
    } else {
      GetRequest('user/self')
        .then((res) => {
          if (res.status == 200) {
            localStorage.setItem(
              'userInfo',
              JSON.stringify({
                profileImage: res.data.data.data.profileImage || '',
                name: res.data.data.data.name || '',
                email: res.data.data.data.email || '',
              })
            );
            setUserInfo((prevState: any) => {
              return {
                ...prevState,
                image: res.data.data.data.image || '',
                name: res.data.data.data.name || '',
                email: res.data.data.data.email || '',
              };
            });
          }
        })
        .catch((err) => {
          console.log(err, 'err in user self api');
        });
    }
  }, []);

  function validateEmail(email: any) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true;
    }
    return false;
  }

  const handleChangeForUserInfo = async (event: any) => {
    const { value, name } = event.target;
    // Create a copy of the userInfo and errors objects
    const updatedUserInfo = { ...userInfo };
    const updatedErrors = { ...userInfo.errors };

    // Update the corresponding fields based on the name
    switch (name) {
      case 'image':
        let img = event.target.files[0];
        const formData = new FormData();
        formData.append('file', img);
        await PostRequest('file?type=user-image', formData)
          .then((fileResponse) => {
            if (fileResponse.data.statusCode == 201) {
              updatedUserInfo.image = fileResponse.data.data.url.includes('pdf')
                ? ''
                : fileResponse.data.data.url;
              updatedErrors.image = fileResponse.data.data.url.includes('pdf')
                ? 'pdf file type not supported'
                : '';
            }
          })
          .catch((error) => {
            if (
              error.response.data.message ===
              'File larger than 1 MB not accepted'
            ) {
              updatedUserInfo.image = '';
              updatedErrors.image = error.response.data.message;
            }
          });

        setTimeout(() => {
          setUserInfo((prevState: any) => {
            return {
              ...prevState,
              errors: {
                ...prevState.errors,
                image: '',
              },
            };
          });
        }, 2000);
        break;
      case 'name':
        updatedUserInfo.name = value;
        updatedErrors.name = !value ? 'name is required' : '';
        break;
      case 'email':
        updatedUserInfo.email = { id: value, isVerified: false };
        if (value !== '' && !validateEmail(value)) {
          updatedErrors.email = 'email is invalid';
          setShowVerify(false);
        } else if (value == '') {
          updatedErrors.email = '';
          setShowVerify(false);
        } else {
          updatedErrors.email = '';
          setShowVerify(true);
          if (showOtp == true && validateEmail(value)) {
            setShowOtp(false);
            setShowVerify(true);
            setResendPhoneOTPTimer(30);
            setPhoneNumberOTP(new Array(6).fill(''));
            // update resend timeer here to 30
          }
        }
        break;
    }

    // Update the state with the modified objects
    setUserInfo({
      ...updatedUserInfo,
      errors: updatedErrors,
    });
  };

  const updateUser = async () => {
    await PatchRequest('user', {
      profileImage: userInfo.image,
      name: userInfo.name,
      email: userInfo.email.id,
    })
      .then((res: any) => {
        if (res.status === 200) {
          setShowIndicator(true);
          setMessage('Updated');
          setType('success');
          setTimeout(() => {
            setShowIndicator(false);
          }, 3000);
          localStorage.setItem(
            'userInfo',
            JSON.stringify({
              name: res.data.data.name || '',
              profileImage: res.data.data.profileImage || '',
              email: res.data.data.email || '',
            })
          );
        }
      })
      .catch((err) => {
        setShowIndicator(true);
        setMessage(err.response.data.message);
        setType('error');
        setTimeout(() => {
          setShowIndicator(false);
        }, 3000);
        console.log(err, 'this');
      });
  };

  const handleUpdateUser = async (event: any) => {
    if (userInfo.name && userInfo.email.id && userInfo.email.isVerified) {
      updateUser();
    } else if (
      userInfo.name &&
      !userInfo.email.id &&
      !userInfo.email.isVerified
    ) {
      updateUser();
    } else {
      if (validateEmail(userInfo.email.id) && !userInfo.email.isVerified) {
        setShowIndicator(true);
        setMessage('The provided email must be verified');
        setType('error');
        setTimeout(() => {
          setShowIndicator(false);
        }, 3000);
      }
    }
  };

  const phoneNumberOTPHandleOnKeyDown = (
    { key }: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    const newPhoneNumberOTP: string[] = [...phoneNumberOTP];

    if (key === 'Backspace') {
      if (newPhoneNumberOTP[index] === '') {
        setActiveOTPIndex(index - 1);
        newPhoneNumberOTP[index - 1] = '';
      } else {
        newPhoneNumberOTP[index] = '';
        setPhoneNumberOTP(newPhoneNumberOTP);
        setActiveOTPIndex(index);
      }
    } else if (key.match('[0-9]')) {
      newPhoneNumberOTP[index] = key;
      setActiveOTPIndex(index + 1);
      setPhoneNumberOTP(newPhoneNumberOTP);
    }
  };

  useEffect(() => {
    inputRef.current?.focus();
    return;
  }, [activeOTPIndex]);

  const sendOTP = async (e: any) => {
    await PostRequest('otp/send', {
      email: userInfo.email.id,
    })
      .then((res) => {
        if (res.status == 200) {
          setShowOtp(true);
          setSentOrResendOTPMsg('OTP has been sent to your email');
          setShowVerify(false);
          setUserInfo((prevState: any) => {
            return {
              ...prevState,
              errors: {
                ...prevState.errors,
                email: '',
              },
            };
          });
        }
      })
      .catch((err) => {
        if (err.statusCode == 400) {
          setUserInfo((prevState: any) => {
            return {
              ...prevState,
              errors: {
                ...prevState.errors,
                email: err.fieldErrors[1],
              },
            };
          });
        }
      });
  };

  const handleSubmitOTP = async (event: any) => {
    await PostRequest('otp/verify', {
      email: userInfo.email.id,
      otp: phoneNumberOTP.join(''),
    })
      .then((res: any) => {
        if (res.status == 200) {
          setUserInfo({
            ...userInfo,
            email: { id: userInfo.email.id, isVerified: true },
          });
          setShowOtp(false);
          setPhoneNumberOTP(new Array(6).fill(''));
          setResendPhoneOTPTimer(30);
          setStartPhoneOTPTimer(false);
        }
      })
      .catch((err: any) => {
        if (err.response.data.statusCode == 400) {
          setErrorOfOTP(err.response.data.message);
        }
      });
  };

  const calculateResendPhoneOTPTimer = useMemo(() => {
    if (resendPhoneOTPTimer <= 0) {
      setStartPhoneOTPTimer(false);
      return 0;
    }
    return resendPhoneOTPTimer;
  }, [resendPhoneOTPTimer]);

  useEffect(() => {
    let intervalId: any;
    if (startPhoneOTPTimer) {
      intervalId = setInterval(() => {
        setResendPhoneOTPTimer(
          (resendPhoneOTPTimer) => resendPhoneOTPTimer - 1
        );
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [startPhoneOTPTimer]);

  const handleStartPhoneOTPTimer = () => {
    setStartPhoneOTPTimer(true);
  };

  return (
    <div className="user-profile-form">
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
        }}
      >
        <button
          className="go-back-button"
          onClick={() => setSelectedNavigation('')}
        >
          <MdOutlineKeyboardBackspace style={{ display: 'inline' }} />
        </button>
      </div>

      <div className="flex py-5 pb-[2rem] justify-center relative">
        {userInfoInRedux.profileImage ? (
          <img
            src={userInfoInRedux.profileImage}
            className="w-[255px] h-[137px]  object-cover"
            style={{
              borderRadius: '4rem',
            }}
            alt={userInfoInRedux.name}
          />
        ) : (
          <div
            style={{
              borderRadius: '4rem',
              border: '1px solid #14539A',
            }}
            className="w-[255px] h-[137px]  flex justify-center items-center"
          >
            <CiUser
              style={{
                width: '100px',
                color: '#14539A',
                height: '100px',
              }}
            />
          </div>
        )}

        <div
          style={{
            position: 'absolute',
            bottom: '8%',
            background: '#14539a',
            width: '45px',
            height: '45px',
            left: '58%',
            lineHeight: '40px',
            textAlign: 'center',
            borderRadius: '50%',
            overflow: 'hidden',
            border: '4px solid white',
            cursor: 'pointer',
          }}
          className="flex cursor-pointer right-[40%] md:right-[36%] justify-center items-center"
        >
          <input
            type="file"
            style={{
              position: 'absolute',
              transform: 'scale(2)',
              opacity: '0',
              cursor: 'pointer',
            }}
            name="image"
            onChange={handleChangeForUserInfo}
          />
          <BsFillCameraFill
            style={{
              cursor: 'pointer',
            }}
            className="w-[28px] h-[28px] text-white"
          />
        </div>
      </div>
      {userInfo.errors.image && (
        <div
          className="error"
          style={{
            marginTop: '-1rem',

            textAlign: 'center',
          }}
        >
          {userInfo.errors.image}
        </div>
      )}
      <div className="email-n-phone-number-cnt">
        <div className="form-control">
          <input
            type="text"
            value={userInfoInRedux.name}
            name="name"
            placeholder="Name"
            onChange={handleChangeForUserInfo}
          />
          {userInfo.errors.name && (
            <div className="error">{userInfo.errors.name}</div>
          )}
        </div>

        <div className="form-control" style={{ position: 'relative' }}>
          <input
            readOnly={userInfo.email.isVerified}
            style={{ flexBasis: '45%' }}
            type="email"
            value={userInfoInRedux.email}
            onChange={handleChangeForUserInfo}
            name="email"
            placeholder="Email"
          />
          {userInfoInRedux.email && (
            <span
              style={{
                marginLeft: '0.5rem',
                display: 'block',
                flexBasis: '5%',
                position: 'absolute',
                right: '20px',
                top: '18%',
              }}
            >
              <MdVerified style={{ color: 'green' }} />
            </span>
          )}
          {userInfo.errors.email && (
            <div className="error">{userInfo.errors.email}</div>
          )}

          {showVerifiy ? (
            <Link
              to="#"
              onClick={sendOTP}
              style={{
                color: '#175DAC',
                marginBottom: '0.8rem',
                textAlign: 'right',
                flexBasis: '100%',
                fontSize: '0.9rem',
                fontWeight: 'semibold',
                display: 'block',
              }}
            >
              Verify Email
            </Link>
          ) : (
            ''
          )}
          {userInfo.email.isVerified && (
            <Link
              to="#"
              onClick={() => {
                return setUserInfo({
                  ...userInfo,
                  email: { id: '', isVerified: false },
                });
              }}
              style={{
                color: '#175DAC',
                marginBottom: '0.8rem',
                textAlign: 'right',
                flexBasis: '100%',
                fontSize: '0.9rem',
                fontWeight: 'semibold',
                display: 'block',
              }}
            >
              Update Email
            </Link>
          )}

          {showOtp == true && userInfo.errors.email == '' ? (
            <Otp
              setSentOrResendOTPMsg={setSentOrResendOTPMsg}
              sentOrResendOTPMsg={sentOrResendOTPMsg}
              startPhoneOTPTimer={startPhoneOTPTimer}
              handleStartPhoneOTPTimer={handleStartPhoneOTPTimer}
              setResendPhoneOTPTimer={setResendPhoneOTPTimer}
              resendPhoneOTPTimer={resendPhoneOTPTimer}
              calculateResendPhoneOTPTimer={calculateResendPhoneOTPTimer}
              activeOTPIndex={activeOTPIndex}
              inputRef={inputRef}
              phoneNumberOTP={phoneNumberOTP}
              handleSubmitOTP={handleSubmitOTP}
              errorOfOTP={errorOfOTP}
              sendOTP={sendOTP}
              phoneNumberOTPHandleOnKeyDown={phoneNumberOTPHandleOnKeyDown}
            />
          ) : (
            ''
          )}
        </div>
      </div>
      <div className="user-profile-update-cnt">
        <button
          type="button"
          onClick={handleUpdateUser}
          className="btn-primary update-btn"
        >
          Update
        </button>
      </div>
    </div>
  );
}

function Otp(props: any) {
  let {
    sendOTP,
    handleStartPhoneOTPTimer,
    setResendPhoneOTPTimer,
    resendPhoneOTPTimer,
    calculateResendPhoneOTPTimer,
    inputRef,
    phoneNumberOTP,
    activeOTPIndex,
    phoneNumberOTPHandleOnKeyDown,
    handleSubmitOTP,
    errorOfOTP,
    startPhoneOTPTimer,
    sentOrResendOTPMsg,
    setSentOrResendOTPMsg,
  } = props;

  useEffect(() => {
    handleStartPhoneOTPTimer();
  }, []);

  return (
    <div className="cnt">
      <p
        className="greeting"
        style={{
          color: 'green',
          marginTop: '0.75rem',
        }}
      >
        {sentOrResendOTPMsg}
      </p>
      <div className="otp">
        <div className="cnt_otp">
          {phoneNumberOTP.map((a: any, index: number) => {
            return (
              <input
                key={index}
                value={phoneNumberOTP[index]}
                onChange={(event) => {
                  return;
                }}
                type="number"
                maxLength={1}
                onFocus={(event) => {
                  event.target.classList.add('border-solid');
                  event.target.classList.add('border-[1.5px]');
                  event.target.classList.add('border-black');
                }}
                onBlur={(event) => {
                  event.target.classList.remove('border-solid');
                  event.target.classList.remove('border-[1.5px]');
                  event.target.classList.remove('border-black');
                }}
                ref={index === activeOTPIndex ? inputRef : null}
                onKeyDown={(event) =>
                  phoneNumberOTPHandleOnKeyDown(event, index)
                }
                className="otp_field input"
              />
            );
          })}
        </div>
        <div
          className="resend_otp"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <span style={{ color: 'red' }}>{errorOfOTP}</span>
          <Link
            to="#"
            onClick={async () => {
              if (startPhoneOTPTimer != true) {
                await sendOTP();
                handleStartPhoneOTPTimer();
                setResendPhoneOTPTimer(3);
                setSentOrResendOTPMsg('OTP has been resend to your number');
              }
            }}
            style={{
              color: resendPhoneOTPTimer == 0 ? 'blue' : 'gray',
            }}
          >
            Resend OTP
            {resendPhoneOTPTimer == 0
              ? ''
              : ' in ' + calculateResendPhoneOTPTimer}
          </Link>
        </div>

        <button
          type="submit"
          onClick={handleSubmitOTP}
          className="send_otp_btn"
        >
          Submit OTP
        </button>
      </div>
    </div>
  );
}
