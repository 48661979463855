import { useState } from 'react';
import './inputNumber.scss';

export default function InputNumber({
  placeholder,
  label,
  value,
  handleChange,
  name,
  onBlur,
  htmlFor,
}: any) {
  const [labelOnTop, setLabelOnTop] = useState<Boolean>(false);

  return (
    <div className="input-number-cnt">
      <label
        onClick={() => setLabelOnTop(true)}
        className={
          labelOnTop || value ? 'label label-should-move-upward' : 'label'
        }
        htmlFor={htmlFor}
        onFocus={() => setLabelOnTop(true)}
      >
        {label}
      </label>
      <input
        id={htmlFor}
        inputMode="numeric"
        min="0"
        onFocus={() => setLabelOnTop(true)}
        onBlur={() => {
          if (typeof onBlur == 'function') {
            onBlur();
          }

          if (value == '') {
            setLabelOnTop(false);
          } else {
            setLabelOnTop(true);
          }
        }}
        type="number"
        name={name}
        value={value}
        onChange={handleChange}
        placeholder={labelOnTop && placeholder}
        className="input-number"
      />
    </div>
  );
}
